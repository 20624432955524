import { AfterViewInit, Component, Inject, NgModule, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CustomerService } from "../../services/customer/customer.service";
import { MessageDialogComponent } from "../my-account/message-dialog/message-dialog.component";
import { RouterModule } from "@angular/router";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service, } from "ng-recaptcha";
import { environment } from "../../environments/environment";
import { RegistratioSuccesMessageDialogComponent } from "./registratio-succes-message-dialog/registratio-succes-message-dialog.component";
import { TermsConditionsPopupComponent } from "../terms-conditions/terms-conditions-popup.component";
import { GtmService } from "../../services/shared/gtm.service";
import { MessageDialogService, NotificationDialogModel } from "../../services/shared/message-dialog.service";



@NgModule({
  imports: [
    RecaptchaV3Module
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.oos.siteKey
    }
  ]
})
export class SignupModule { }

@Component({
  selector: "app-signup",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    RecaptchaV3Module,
    RouterModule,
    SignupModule
  ],
  templateUrl: "./signup.component.html",
  styleUrl: "./signup.component.scss",
})
export class SignupComponent implements OnInit {

  registrationStatus: number = 0;
  pwHide: any = true;
  cwHide: any = true;
  customerDtails: any;
  days: any = [];
  months: any = [];
  pastYears: any = [];
  singUpForm: FormGroup<any>;
  confirmPassError = "";
  recaptchaToken: string = "";
  reCaptchaAction: string = "signup";

  constructor(
    public dialogRef: MatDialogRef<SignupComponent>,
    public dialog: MatDialog,
    private customerService: CustomerService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private gtmService: GtmService,
    private messageDialogService: MessageDialogService,
    @Inject(RECAPTCHA_V3_SITE_KEY) private siteKey: string
  ) {

    const currentYear = new Date().getFullYear();

    this.pastYears.push({ value: "0004", label: "" });
    for (let i = 1; i <= 100; i++) { // Start from 1 to exclude current year
      const year = currentYear - i;
      this.pastYears.push({ value: year, label: year.toString() });
    }


    const currentDate = new Date();
    this.months = [
      { value: 0, label: "" },
      ...Array.from({ length: 12 }, (_, i) => ({
        value: i + 1,
        label: new Date(currentYear, i).toLocaleDateString("en-US", {
          month: "long",
        }),
      })),
    ];

    this.days = [
      { value: 0, label: "" },
      ...Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
      })),
    ];

    const strongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
    this.singUpForm = new FormGroup({
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.email]),
      dob: new FormControl(""),
      dobDay: new FormControl(""),
      dobMonth: new FormControl(""),
      dobYear: new FormControl(""),


      newPassword: new FormControl("", [Validators.required, Validators.pattern(strongPasswordRegx)]),
      confirmPassword: new FormControl("", [Validators.required, Validators.pattern(strongPasswordRegx)]),
      phoneNumber: new FormControl("", [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(9),
      ]),

      subscription: new FormControl(""),
      agree: new FormControl(""),

    });

  }

  get passwordFormField() {
    return this.singUpForm.get('newPassword');
  }

  // ngAfterviewinit

  ngOnInit() {

    // this.recaptchaV3Service.execute(this.reCaptchaAction)
    //   .subscribe(token => {
    //     this.recaptchaToken = token;
    //   });


  }

  onPasswordChange(passType: string): void {
    this.passwordMatchCheck();
  }
  /**
   * 
   * to check the new password and confirmpassword are same 
   */
  passwordMatchCheck(): boolean {
    this.confirmPassError = "";
    const confirmPass = this.singUpForm.controls["confirmPassword"].value;
    const newPass = this.singUpForm.controls["newPassword"].value;
    if (confirmPass !== "" && confirmPass !== newPass) {
      this.confirmPassError =
        "New password and confirmation password don't match.";
      return false;
    }
    return true;
  }

  close(status:any) {
    this.dialogRef.close(status);
  }
  loginDialog() {
    this.dialog.open(LoginComponent, {
      panelClass: "login-dialog",
    });
    this.close(false);
  }

  /**
   * to set the detilas of a new singup customer 
   */
  signUp(): void {

    if (this.singUpForm && this.singUpForm.get("agree")) {
      const customerDetails = {
        title: "",
        first_name: this.singUpForm.controls["firstName"].value,
        last_name: this.singUpForm.controls["lastName"].value,
        email: this.singUpForm.controls["email"].value,
        login_id: this.singUpForm.controls["email"].value,
        address: "",
        city: "",
        state: "",
        country: "",
        post_code: "",
        dob: "",
        dob_day: this.singUpForm.controls["dobDay"].value,
        dob_month: this.singUpForm.controls["dobMonth"].value,
        dob_year: this.singUpForm.controls["dobYear"].value,
        phone_number: this.singUpForm.controls["phoneNumber"].value,
        gender: "",
        password: this.singUpForm.controls["newPassword"].value,
        is_subscribed: 1,
        is_valid: 1,
      };
      const agreeChecked = this.singUpForm.get("agree")!.value;
      const passwordStrength = ((this.passwordFormField?.value?.match('^(?=.*[A-Z])')) && (this.passwordFormField?.value?.match('(?=.*[a-z])'))
        && (this.passwordFormField?.value?.match('(.*[0-9].*)')) && (this.passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
        && (this.passwordFormField?.value?.match('.{8,}'))) ? true : false;
      if (!passwordStrength) {
        this.dialog.open(WeekPassword, {
          panelClass: 'confirm-dialog'
        })
        // const mssag = "Your password does not meet minimum requirements." + "\n"
        //   + "Include at least one uppercase letter (A-Z)" + "\n"
        //   + "Include at least one lowercase letter (a-z)" + "\n"
        //   + "Include at least one number (0-9)" + "\n"
        //   + "Include at least one special character (e.g., !@#$%^&*)" + "\n" //
        //   + "Be at least 8 characters long"
        // var dlgData: NotificationDialogModel = {
        //   message: mssag,
        //   title: "Week Password",
        //   yesButtonCaption: "Ok",
        //   // noButtonCaption: "Select Shop"
        // }
        // this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        // });
      }
      else if (this.singUpForm.valid && agreeChecked) {
        if (!this.passwordMatchCheck()) {
          return;
        }
        this.registrationStatus = 1;
        this.recaptchaV3Service.execute(this.reCaptchaAction)
          .subscribe(token => {
            this.recaptchaToken = token;
            this.customerService.singUpCustomer({ ...customerDetails, gToken: this.recaptchaToken }).subscribe({
              next: (response) => {

                if (response.status == "SUCCESS") {
                  this.registrationStatus = 0;
                  const reward = response.data.reward;
                  this.succesDialog(reward);
                  this.close(true);
                  /**
                             * GTM Code
                             */
                  this.gtmService.pushEvent(
                    'account_creation',
                    {
                      userId: this.gtmService.getCustomer(),
                    });

                } else {
                  this.registrationStatus = 0;
                  const message = response.message;
                  this.showDialog(message);
                }
              },
              error: (error) => {
                this.registrationStatus = 0;
                const message = error.message;
                this.showDialog("Failed to register customer. Please check the details and try again.");
              },
            });
          });

      } else {
        if (!agreeChecked) {
          this.showDialog("Please agree to the Terms and Privacy Policy.");
        }
      }
    } else {
      console.error("Form or form control is null.");
    }
  }
  /**
   * 
   * to show the response message 
   */
  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "SIGN UP", content: message };
    dialogConfig.panelClass = "app-message-dialog";
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }
  /**
   * 
   * to show the success message 
   */
  succesDialog(reward: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "Welcome to St. Pierres Sushi!", reward: reward };
    dialogConfig.panelClass = "app-registratio-succes-message-dialog";
    const dialogRef = this.dialog.open(RegistratioSuccesMessageDialogComponent, dialogConfig);
  }
  termsConditionsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "app-terms-conditions-popup";
    const dialogRef = this.dialog.open(TermsConditionsPopupComponent, dialogConfig);
  }

  /** to prevent typing charaters other than numbers  */
  preventNonNumeric(event: KeyboardEvent) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }

}

@Component({
  selector: 'week-password',
  template: `
  <section>
    <h5 class="font-secondary mb-4">Weak Password</h5>
    <p>Your password does not meet minimum requirements.</p>
    <ul>
        <li>At least one uppercase letter (A-Z)</li>
        <li>At least one lowercase letter (a-z)</li>
        <li>At least one number (0-9)</li>
        <li>At least one special character (!&#64;#$%^&*)</li>
        <li>At least 8 characters long</li>
    </ul>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="close()">Ok</button>
    </div>
  </section>`,
  styles: [`  
  ul{
    padding-left: .8rem;
    display:table;
  }
  `]
})
export class WeekPassword {
  constructor(public dialogRef: MatDialogRef<WeekPassword>) { }
  close() {
    this.dialogRef.close(false)
  }
}
