import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-qr-viewer',
  standalone: true,
  imports: [QRCodeModule],
  templateUrl: './qr-viewer.component.html',
  styleUrl: './qr-viewer.component.scss'
})
export class QrViewerComponent {

  qrData:string

  constructor( private dialogRef: MatDialogRef<QrViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any){

      this.qrData=this.data;
  }



}
