import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../storage/storage.service';

import { SESSION_AUTH_TOKEN } from '../../app.constants';


@Injectable({
  providedIn: 'root'
})
export class TokenService {
  crmEnv: any;
  constructor(private http: HttpClient, private storageService: StorageService) {
    this.crmEnv = environment.crm;
  }

  /**
   * Returns token from the local storage
   * if not found any token call the API to obtain the token and set to storage and return it.
   * @returns 
   */
  getToken(): Observable<any> {

    const storedToken = this.storageService.loadFromSession(SESSION_AUTH_TOKEN);
    if (storedToken) {
      // Token found in local storage, return it as an Observable
      return of(storedToken);
    } else {
      // Token not found, make an API request to fetch it
      let data = { user_name: this.crmEnv.crmUser, password: this.crmEnv.crmPassword, apikey: this.crmEnv.crmAppKey };
      return this.http.post(this.crmEnv.url + '/auth', data).pipe(
        map((response) => response),
        catchError((error) => {
          console.error('Error fetching token:', error);
          return of(''); // Return an empty string in case of error
        }),
        switchMap((response: any) => {
          let newToken = '';
          if (response.status == 'SUCCESS') {
            newToken = response.token;
          }
          // Store the new token in local storage
          this.storageService.saveToSession(SESSION_AUTH_TOKEN,newToken);
          // Return the new token as an Observable
          return of(newToken);
        })
      );
    }
  }
}
