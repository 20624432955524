import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, catchError, map, switchMap } from 'rxjs';
import { OOModule } from '../../modules/oo.module';
import { TokenService } from '../shared/token.service';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  authenticatenormalUrl: string;
  authenticatesocialUrl: string;
  forgotPasswordUrl: string;
  restorecustomerUrl: string;
  getAllAmenityUrl: string;
  getAllBusinessTypeUrl: string;
  getAllOnlineServiceUrl: string;
  getAllItemUrl: string;
  filteredSaleItemUrl: string;
  updateCustomerUrl: string;
  changeCustomerUrl: string;
  validateCustomerUrl: string;
  regesterCustomerUrl: string;
  sendFeedbackDataUrl: string;
  myOrdersUrl: string;
  myOrderDetailUrl: string;

  config = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  customerActivationUrl: string;
  
  

  constructor(private http: HttpClient, private tokenService: TokenService,private cartService:CartService) {
    this.authenticatenormalUrl =
      environment.oos.url + '/customer/get-validated-customer';
    this.authenticatesocialUrl =
      environment.oos.url + '/customer/get-validated-social-customer';
    this.forgotPasswordUrl = environment.oos.url + '/customer/forgot-password';
    this.restorecustomerUrl = environment.oos.url + '/customer/get/';
    this.getAllAmenityUrl = environment.oos.url + '/amenity/get-all';
    this.getAllBusinessTypeUrl = environment.oos.url + '/dashboard/get-info';
    this.getAllOnlineServiceUrl =
      environment.oos.url + '/company/get-online-services';
    this.getAllItemUrl = environment.oos.url + '/itemCategory/get-all';
    this.filteredSaleItemUrl = environment.oos.url + '/saleItem/get-all';
    this.validateCustomerUrl =
      environment.oos.url + '/customer/validate-customerMail/';
    this.changeCustomerUrl = environment.oos.url + '/customer/change-password';
    this.updateCustomerUrl = environment.crm.url + '/customer/update';
    this.regesterCustomerUrl = environment.crm.url + '/customer/add';
    this.customerActivationUrl = environment.crm.url + '/customer/activate';
    this.sendFeedbackDataUrl = environment.crm.url + '/customer/sendFeedback';
    this.myOrdersUrl = environment.oos.url + '/customer/get-my-orders';
    this.myOrderDetailUrl=environment.oos.url + '/customer/get-my-order-detail';
  }

  authenticateCustomer(customer: any): Observable<any> {
    return this.http.post(this.authenticatenormalUrl, customer, this.config);
  }

  authenticateSocialCustomer(socialcustomer: any): Observable<any> {
    return this.http.post(
      this.authenticatesocialUrl,
      socialcustomer,
      this.config
    );
  }

  forgotPassword(emailID: any): Observable<any> {
    return this.http.post(this.forgotPasswordUrl, emailID, this.config);
  }

  restoreCustomer(customerID: any): Observable<any> {
    return this.http.get(this.restorecustomerUrl + customerID, this.config);
  }

  getAmenities(): Observable<any> {
    return this.http.get(this.getAllAmenityUrl);
  }

  getBusinessType(): Observable<any> {
    return this.http.get(this.getAllBusinessTypeUrl);
  }

  getOnlineService(): Observable<any> {
    return this.http.get(this.getAllOnlineServiceUrl);
  }

  getItemList(): Observable<any> {
    return this.http.get(this.getAllItemUrl);
  }

  SaleItemList(): Observable<any> {
    return this.http.get(this.filteredSaleItemUrl);
  }

  updateCustomer(customer: any): Observable<any> {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };

        return this.http.post(
          this.updateCustomerUrl,
          customer,
          httpOptions
        );
      })
    );
   
    
  }
  verifyCustomerMail(customer: any): Observable<any> {
    return this.http.post<any>(this.validateCustomerUrl, customer);
  }

  changePassword(customer: any): Observable<any> {
    return this.http.post<any>(this.changeCustomerUrl, customer);
  }

  singUpCustomer(customerDtails: any): Observable<any> {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };

        return this.http.post(
          this.regesterCustomerUrl,
          customerDtails,
          httpOptions
        );
      })
    );
  }

  feedBackRegestration(feedBackData: any): Observable<any> {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };

        return this.http.post(
          this.sendFeedbackDataUrl,
          feedBackData,
          httpOptions
        );
      })
    );
  }

  /**
   *
   * to get order details
   */
  getMyOrders(customerid: any, limit: any, offset: any): Observable<any> {
    const url =
      this.myOrdersUrl + '/' + customerid + '/' + limit + '/' + offset;
    return this.http.get<any>(url).pipe();
  }


  getMyOrderDetail(orderId:any): Observable<any> {
    const url = this.myOrderDetailUrl +  '/' + orderId;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  /**
 * to get shop details
 */
  getShopDetails(){
    const cart = this.cartService.getCustomerCart();
    if (cart.shop != null && cart.shop != undefined) {
      return cart.shop;
    }
    else{
     
    }
  }


  activateCustomer(token: any): Observable<any> {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };

        return this.http.get(
          this.customerActivationUrl+"/"+token,
          httpOptions
        );
      })
    );
  }

  
  
 

}
