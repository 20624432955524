<div class="search-wrapper bg-white rounded-1 border position-relative searchMainDiv">
  <form>
    <div class="d-flex searchDiv">
      <button class="btn py-2 px-3" (click)="backButton()"><img src="assets/icons/back_arrow_icon.svg"
          class="me-lg-2 white-icon" alt=""></button>
      <input matInput class="border-0 w-100 fs-5 py-md-2 pe-2 pe-md-5" placeholder="Search for items..."
        aria-label="Number" [formControl]="searchControl" [matAutocomplete]="itemSearch" id="user-enter" style="font-family: 'Futura';">
      <img src="assets/icons/search_icon.svg" alt="" class="me-lg-2 white-icon search-icon">
    </div>

    <mat-autocomplete #itemSearch="matAutocomplete" class="item-search theme-scrollbar" panelWidth="auto">
      @for (item of filteredItemList; track item.saleItemId) {
      <mat-option class="mb-2 bg-white px-2 px-md-4 mx-md-2" [value]="item.name"
        (onSelectionChange)="navigateToItemDetail(item)">
        <div class="d-inline-block shop-address">
          <p class="fs-5 mb-2">{{item.name}}</p>
        </div>
      </mat-option>
      }
    </mat-autocomplete>
  </form>
</div>