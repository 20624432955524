<section>
    <h5 class="font-secondary mb-4">Shop Selection</h5>
    <p class="content">We noticed you haven't chosen a shop yet, but based on your previous order, we've selected 
        <b>{{shop}}</b> shop for you.</p>
    <p class="content">If this is the shop you want, simply click <b>CONTINUE.</b> Otherwise, click <b>SHOP LIST</b> to choose a different one.</p>
    <div class="text-end pt-1 font-secondary" style="display: flex; width: 100%;">
      <button class="btn btn-secondary py-2 px-4 rounded-pill me-2" (click)="continue()" style="flex: 1;">Continue</button>
      <button class="btn btn-primary py-2 px-4 rounded-pill" (click)="onShopSelection()" style="flex: 1;">Shop List</button>
    </div>
  </section>
