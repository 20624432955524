<div class="p-3 p-lg-4 border rounded-1 overflow-hidden mb-3">
    <div class="row cart-item-row">
        <div class="col-auto position-relative" style="align-items: center; display: flex;">
            @if((cartItem.offers!==undefined && cartItem.offers.length>0)){
            @if(cartItem.offers[0].offer.reward_eligibility!==1){
            <div class="position-absolute py-2 px-4 reward-applied d-flex">

                <div class="me-1 me-md-2 my-auto">
                    <img src="assets/icons/gift_icon.svg" width="24" class="align-top" alt="">
                </div>
                <div class="font-secondary">
                    <p class="mb-0 text-nowrap">Reward Applied</p>
                    @if (cartItem.offers.discount!=undefined && cartItem.offers.discount?.activation_mode !== 0) {
                    <p class="fs-6 text-decoration-underline mb-0" role="button"
                        (click)="removeReward(cartItem.offers)">Remove</p>
                    }
                </div>
            </div>
            }@else{
            <div class="position-absolute py-2 px-4 offer-applied d-flex">
                <div class="me-1 me-md-2 my-auto">
                    <img src="assets/icons/offer_applied.svg" width="24" class="align-top" alt="">
                </div>
            </div>
            }
            }
            <img [src]="saleItemInfo?.images !== null ? imageUrl+((saleItemInfo?.defaultImage !== null)? saleItemInfo?.defaultImage:saleItemInfo.images[0]): 'assets/images/no-image-available.png'"
                class="dish-img" width="140" alt="" onerror="this.src='assets/images/no-image-available.png'">
        </div>
        <div class="col">
            <h5 class="pb-1">
                @if (cartItem.hasError) {
                <span class="fa fa-question-circle" aria-hidden="true"
                    style="margin-right: 5px; color:#ba1b1b;font-size: 23px;"
                    (click)="showError();$event.stopPropagation();"></span>
                }
                {{(saleItemInfo !== null && saleItemInfo !== undefined )?saleItemInfo?.name:cartItem?.name}}
            </h5>
            @if (cartItem.isCustomized && cartItem.itemType==2) {
            <div class="col"
                style="background-color: lightgray;padding: 1px;border-radius: 2px; margin-bottom: .5rem;padding-bottom: .25rem;">
                @for (custOptVal of cartService.getSelectedCustomizationOptionValueData(cartItem); track $index) {
                <span>[{{custOptVal.nameToPrint}}]</span>
                }
            </div>
            }
            <div class="item-price-qty-div">
                <h5 class="mb-0 font-secondary d-inline-block align-middle amount">
                    <!-- {{cartItem.quantity}}&nbsp;x&nbsp; -->
                    {{currencySymbol}}{{getItemTotalPrice(cartItem)|number:'1.2-2'}}
                </h5>
                @if((cartItem.offers!==undefined && cartItem.offers.length>0)){
                <del class="mb-0  fs-6 font-secondary d-inline-block align-middle offer-actual-price amount">
                    {{currencySymbol}}{{(saleItemInfo.fixedPrice+cartItem.customizationUnitPriceWithTax)* cartItem.quantity|number:'1.2-2'}}
                </del>
                }
                <mat-select [(ngModel)]="cartItem.quantity" class="btn btn-outline-dark "
                    style="width: 37%; max-width: 80px;" panelClass="rounded" (selectionChange)="itemQtyChanged()" [disabled]="!isDisabled?false:true">
                    @for (qty of quantity; track $index) {
                    <mat-option [value]="qty.value">{{qty.label}}</mat-option>
                    }
                </mat-select>
            </div>
        </div>
        <div class="col-auto">
            <button class="btn btn-edit p-2 rounded-circle bg-secondary mb-2" style="--bs-bg-opacity:.1;"
                (click)="editItemDetail(cartItem);$event.stopPropagation();">
                <img src="assets/icons/edit_icon.svg" width="24" class="m-lg-1" alt="">
            </button>
            @if (cartItem.hasError) {
            <button class="btn btn-edit p-2 rounded-circle d-block" style="background-color: #ba1b1b;"
                (click)="deleteItemFromCart(cartItem);$event.stopPropagation();">
                <img src="assets/icons/delete_w_icon.svg" width="24" class="m-lg-1" alt="">
            </button>
            }
            @else {
            <button class="btn btn-edit p-2 rounded-circle bg-secondary d-block" style="--bs-bg-opacity:.1;"
                (click)="deleteItemFromCart(cartItem);$event.stopPropagation();">
                <img src="assets/icons/delete_icon.svg" width="24" class="m-lg-1" alt="">
            </button>
            }
        </div>
        @if(pendingOfferDescription!=undefined && pendingOfferDescription.description.length>0){
        <div class="col-12 bg-secondary py-2 offer-instruction" style="position: relative;  background: black; color: #fff200;"
            (click)="showOffers(cartItem)" [ngStyle]="{'cursor': pendingOfferDescription.showPopUp ? 'pointer' : ''}">
            @if(pendingOfferDescription.showPopUp){
    
                <img src="assets/icons/popup.svg" alt="pop up image" style="width: 20px;
                /* float: right; */
                position: absolute;
                right: 3px;
                top: 2px;">
         
            }
            <p class="mb-0">
                <span>Offer available.</span>

            </p>
            <span>{{pendingOfferDescription.description}}</span>
            <!-- <p class="mb-0"></p> -->
        </div>
        }

        <div class="col-12 bg-secondary py-2 custom-instruction" style="--bs-bg-opacity:10%">
            <p class="mb-0">Customer Note:&nbsp;<span class="ml-2">{{cartItem.remarks}}</span></p>
            <!-- <p class="mb-0"></p> -->
        </div>
    </div>
</div>