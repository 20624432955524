<div class="position-sticky back-strip bg-white">
  <div class="container py-3 font-secondary">
   
    <p
      class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
    >
      payment
    </p>
  </div>
</div>

<div class="container">
  <div class="payment-gateWay-init">
    <div class="wait-div">
      <h4 class="wait-span-title">Redirecting to payment gateway</h4>

      <h5 class="wait-span-message">Please do not press back button or refresh. If you want to cancel, use cancel
        option
        in the payment screen.</h5>
    </div>
    <div class="payment-loading" style="text-align: center;">
        <div class="spinner-border text-danger">
          <span class="visually-hidden"></span>
        </div>
    </div>
  </div>
</div>
    
    <!-- <p-confirmDialog key="payment-fail" #cdFail class="payment-success poppup_main_div"  [closable]="false" [rejectVisible]="false"  [closeOnEscape]="false" header="FAILED TO PROCESS ORDER!" [responsive] = "true" width="350" height="150" >
      <p-footer class="poppup_main_div_button">
          <button mat-raised-button class="pd-accept-btn" label="Ok" (click)="cdFail.accept()">Ok</button>
          <button mat-raised-button class="pd-accept-btn" label="No" (click)="cd.reject()">No</button>
        </p-footer>
    </p-confirmDialog> -->
    
    
