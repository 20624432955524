import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { SharedService } from '../shared/shared.service';
import { OOModule } from '../../modules/oo.module';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  message: any = null;
  tokens:any

  constructor(private http:HttpClient,private sharedService:SharedService) { }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.config.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            this.sharedService.openDb().subscribe((db) => {
              this.tokens=this.sharedService.getAllFCMTokens(db)
              this.sharedService.storeData(db, currentToken)
            })
            this.subscribe(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }

  subscribe(token: string) : Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAAr9N6cB8:APA91bG0UfG5Q0WU2l-R2fgZDRyAK0Dxp-T6akKKxhObUullK-fScg3KLMNXhRdGx70vFgKv9Npx0ZeMxLoX2eXPJzt6Y1YPj8LIkfOYVHYSQCGLze0SEStR94yQb4qc_r9IqPGHeUQm', // Example Authorization header
    });
    var payload={
      to: "/topics/test",
      registration_tokens: [token]
    }
    return this.http.post(environment.firebase.subscriptionURL, payload, { headers: headers });
  }

  sendRewards(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAAr9N6cB8:APA91bG0UfG5Q0WU2l-R2fgZDRyAK0Dxp-T6akKKxhObUullK-fScg3KLMNXhRdGx70vFgKv9Npx0ZeMxLoX2eXPJzt6Y1YPj8LIkfOYVHYSQCGLze0SEStR94yQb4qc_r9IqPGHeUQm', // Example Authorization header
    });
    var payload={
      "to": "/topics/test",
      "notification": {
        "title": "Push notification Test",
        "body": "This is a test notification of Firebase cloud messaging service"}
    }
    return this.http.post(environment.firebase.sendURL, payload, { headers: headers });
  }
}
