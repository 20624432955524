<div class="p-3 p-lg-4 border rounded-1 overflow-hidden mb-3">
    <div class="row cart-item-row">
        <div class="col-auto position-relative">
            <!-- <div class="position-absolute py-2 px-4 reward-applied d-flex">
                <div class="me-1 me-md-2 my-auto">
                    <img src="assets/icons/gift_icon.svg" width="24" class="align-top" alt="">
                </div>
                <div class="font-secondary">
                    <p class="mb-0 text-nowrap">Reward Applied</p>
                    <p class="fs-6 text-decoration-underline mb-0" role="button">Remove</p>
                </div>
            </div> -->
            <img [src]="saleItemInfo?.images !== null ? imageUrl+((saleItemInfo?.defaultImage !== null)? saleItemInfo?.defaultImage:saleItemInfo.images[0]): 'assets/no-image-available.png'"
                class="dish-img" width="140" alt="" onerror="this.src='assets/no-image-available.png'">
        </div>
        <div class="col">
            <h5 class="pb-1">
                {{(saleItemInfo !== null && saleItemInfo !== undefined )?saleItemInfo?.name:cartItem?.name}}
                <div style="font-weight: bold;">&nbsp;{{cartItem.quantity}}&nbsp;x&nbsp;{{currencySymbol}}{{cartItem.fixedPrice}}&nbsp;</div>
            </h5>
            @if (cartItem.isCustomized) {
                @for (custOptVal of cartService.getSelectedCustomizationOptionValueData(cartItem); track $index) {
                    <div class="mb-2 mb-sm-4 pb-sm-2">
                        <span>{{custOptVal.nameToPrint}}</span>
                        <p style="font-weight: bold;">&nbsp;{{cartItem.quantity}}&nbsp;x&nbsp;{{currencySymbol}}{{custOptVal.fixedPrice | number :
                            '1.2-2'}}&nbsp;</p>
                    </div>
                }
            }
            <h3 class="mb-0 font-secondary d-inline-block align-middle ps-5 ms-5">
                {{currencySymbol}}{{getItemTotalPrice(cartItem)| number :
                    '1.2-2'}}
            </h3>
        </div>
        @if (cartItem.remarks) {
        <div class="col-12 bg-secondary py-2 custom-instruction mt-4" style="--bs-bg-opacity:10%">
            <p class="mb-0">Customer Note:&nbsp;<span class="ml-2">{{cartItem.remarks}}</span></p>
        </div>
        }
    </div>
</div>