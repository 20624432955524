
<section>
    <h5 class="font-secondary mb-4">{{data.title}} </h5>
   
    <p>Thank you for signing up with us! We’re thrilled to have you on board!</p>
@if(reward!==null && reward!==undefined){
  	<div class="reward-message">
    		<p>As a newly registered customer, a Welcome Offer is now available on your next purchase!</p>
    		<p>Redeem your Welcome Offer and check out our freshly made bento bowl and sushi varieties! </p>
  	</div>
}
<div >
  <p>To activate your account, we have sent a link to the registered email address. Please open the mail and activate the account using the link in the email. </p>
  <p>Happy ordering!</p>
</div>
    <div class="text-end pt-1 font-secondary">
      
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="loginDialog()">close</button>
      
    </div>
  </section>
  
