import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-message-dialog",
  standalone: true,
  imports: [],
  templateUrl: "./message-dialog.component.html",
  styleUrl: "./message-dialog.component.scss",
})
export class MessageDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * to close the dialog
   */
  close() {
    this.dialogRef.close();
  }
}
