<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-4">
    <div class="container py-3 mt-lg-4 pt-lg-2 font-secondary">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="white-icon" alt=""></button>
        <h2 class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-3 page-heading text-center align-middle">About Us</h2>
    </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">About Us</p>
    </div>
  </div>

<div class="container text-center">
    <p class="pt-4 pt-lg-1 mb-4 pb-2 col-lg-7 mx-auto fs-4 about-sub-heading"> The  St Pierres Ordering Platform  is the online
        ordering system for
        St Pierres Sushi and Bento Bowl menu. It
        is managed and operated in New Zealand by</p>
    <p class="fs-5 fw-bold">ST PIERRES ONLINE LIMITED</p>
    <p> 190 Great South Road Epsom 1051 </p>
    
    <p class="mb-1 pb-2">Monday to Friday: 9am to 5pm.</p>
    <!-- <i class="fa fa-instagram" style="font-size:24px"></i>
    <i class="fa fa-facebook-square" style="font-size:24px"></i>
    <i class="fa fa-linkedin-square" style="font-size:24px"></i> -->
    <div class="d-flex justify-content-center gap-2 mb-5">
        <a href="https://www.facebook.com/StPierresSushi/" class="icon"><i class="fa-brands fa-square-facebook"></i></a>
        <a href="https://www.instagram.com/stpierres_sushi/?hl=en" class="icon"><i class="fa-brands fa-square-instagram"></i></a>
        <a href="https://www.tiktok.com/@stpierresnz" class="icon"><i class="fa-brands fa-tiktok"></i></a>
        <a href="https://nz.linkedin.com/company/st-pierres-sushi" class="icon"><i class="fa-brands fa-linkedin"></i></a>
    </div>
    <p class="pb-1">For more information on St Pierres Sushi, please visit&nbsp;<a
        href="https://stpierres.co.nz/" target=”_blank”>stpierres.co.nz</a></p>
        <p class="pb-1"> For more information on St Pierres Bento Bowl, please visit&nbsp;<a
            href="https://bentobowl.co.nz/" target=”_blank”>bentobowl.co.nz</a></p>
            <p class="pb-1"> For careers in St Pierres, please visit&nbsp;<a
                href="https://www.stpierrescareers.com/" target=”_blank”>stpierres careers</a></p>
        
             <p class="pb-1">For more information on how become a partner with St Pierres, please visit&nbsp;<a
                href="https://www.stpierrescareers.com/partnerwithus" target=”_blank”>partner with us</a></p>
       

    <!-- <div class="mb-5 pb-2 text-start">
        <div class="row pb-1 about-row">
            <div class="col-md-6">
                <div class="p-4 bg-secondary rounded-1 h-100" style="--bs-bg-opacity:10%">
                    <div class="brand-img px-3 py-2 bg-secondary d-inline-block rounded-3 mb-4">
                        <img src="assets/images/logo.svg" width="322" alt="" class="img-fluid">
                    </div>
                    <p class="pb-1">For more information on St Pierre’s Sushi, please visit&nbsp;<a
                            href="https://stpierres.co.nz/" target=”_blank”>stpierres.co.nz</a></p>
                    <p class="mb-0"> To connect with us on Facebook, please go to&nbsp;<a
                            href="https://www.facebook.com/StPierresSushi/" target="_blank">StPierresSushi</a></p>
                </div>
                
            </div>
            <div class="col-md-6">
                <div class="p-4 bg-secondary rounded-1 h-100" style="--bs-bg-opacity:10%">
                    <div class="brand-img d-inline-block mb-4">
                        <img src="assets/images/BB_logo_horizontal_Black_bg.svg" width="276" alt="" class="img-fluid">
                    </div>
                    <p class="pb-1"> For more information on St Pierre’s Bento Bowl, please visit&nbsp;<a
                            href="https://bentobowl.co.nz/" target=”_blank”>bentobowl.co.nz</a></p>
                    <p class="mb-0"> To connect with us on Facebook, please go to&nbsp;<a
                            href="https://www.facebook.com/BentoBowl" target="_blank">Bento Bowl</a></p>
                </div>
            </div>
        </div>
    </div> -->
    <div class="" style="padding-top:50px;">
        <p class="text-center mx-auto">This online ordering platform is developed and maintained by Mochi
            Digital Limited. <br>
            All rights reserved by Mochi Digital Limited {{ currentYear }}.</p>
    </div>
</div>
