import { NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedService } from '../services/shared/shared.service';
import { LoginService } from '../pages/login/login.service';
import { StorageService } from '../services/storage/storage.service';



@NgModule({
  declarations: [],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
  ],
  providers:[
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'home-autocomplete' },
    },
    SharedService,
    LoginService,
    StorageService
  ]
})
export class MatModule { }
