import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { SESSION_COMPANY_INFO } from '../../app.constants';
import { LoginService } from '../../pages/login/login.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StartupService {

  _startupData: any;
  companyInfo: any;
  private companyInfoUrl:any;
  constructor(private http: HttpClient,
    private storageService:StorageService
    ) { }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  /**Fetch company info and api url */
  async load() {
    this._startupData = await this.http.get(environment.oos.url).toPromise();
    this.companyInfoUrl =  environment.oos.url + '/company/get-info';
    this.companyInfo = await this.http.get(this.companyInfoUrl).toPromise();
    this.storageService.saveToSession(SESSION_COMPANY_INFO,JSON.parse(this.companyInfo.object[0]));  
  }

  get startupData(): any {
    return this._startupData;
  }

  get companyData(): any {
  return this.companyInfo;
  }


}
