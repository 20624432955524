<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-4">
  <div class="container py-3 mt-lg-4 pt-lg-2 font-secondary">
    <button
      class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none"
      (click)="back()"
    >
      <img src="assets/icons/back_arrow_icon.svg" class="white-icon" alt="" />
    </button>
    <h2
      class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-5 page-heading text-center align-middle"
    >
      Frequently Asked Questions
    </h2>
  </div>
</div> -->

<div class="back-strip font-secondary bg-white firstDiv">
  <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
              <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                 src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                 class="align-middle d-none d-lg-inline">BACK</span></button>
              <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">  Frequently Asked Questions</p>
  </div>
</div>

<div class="container mt-lg-0 mt-3 pt-3 mb-5 pb-5">
  <div class="mat-typography ql-editor faq-counter">
    <div class="content-div" [innerHTML]="faqContent">
    </div>
  </div>
</div>