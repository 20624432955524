import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FavouriteShop } from './shop-detail';
import { Observable, throwError } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateTimeServiceService } from '../../services/shared/date-time-service.service';
import { DateSelectionService } from '../../services/shared/date-selection.service';;
import moment from 'moment';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ShopDetailService {
  getShopListUrl: string;
  shopWorkingInfo = { shopOpen: '', shopStatus: 'Closed', currentShopWrkInfo: '' };
  getFavouriteShopUrl: string;
  favouriteShopUrl: string;
  getPickupLocUrl: string;
  constructor(private http: HttpClient, private dateTimeServiceService: DateTimeServiceService,
    private datePipe: DatePipe) {
    this.favouriteShopUrl = environment.oos.url + '/customer/set-fav';
    this.getFavouriteShopUrl = environment.oos.url + '/customer/get-fav-shop';
    this.getShopListUrl = environment.oos.url + '/shop/get-all';
    this.getPickupLocUrl = environment.oos.url + '/pickup-location-info/get-pickup-location'; 
  }
  /* Add and delete shop to the favourite shop list*/
  setFavouriteShop(favouriteShop: FavouriteShop): Observable<any> {
    return this.http.post<any>(this.favouriteShopUrl, favouriteShop)
      .pipe(

      );
  }
  getShopList(): Observable<any> {
    return this.http.get<any>(this.getShopListUrl)
      .pipe(

      );
  }

  getPickuplocdata():Observable<any> {
    return this.http.get<any>(this.getPickupLocUrl)
      .pipe(

      );
  }

  /**
   *  @param shop,@param amenityList
   *  Available shop amenities of each shop
   *
   */
  getShopAmenities(shop:any, amenityList:any) {
    const shopAmenityList: any[] = [];
    let count = 0;
    if (shop.amenity !== null) {
      shop.amenity.forEach(function (amenityId:any) {
        shopAmenityList[count] = amenityList.find(
          function (obj:any) { return obj.id === amenityId; }
        );
        count++;
      }.bind(this));
    }
    return shopAmenityList;
  }
  getShopDepartments(shop:any, departmentList:any) {
    const shopDepartmentList: any[] = [];
    let count = 0;
    if (shop.department !== null) {
      shop.department.forEach(function (departmentId:any) {
        shopDepartmentList[count] = departmentList.find(
          function (obj:any) { return obj.id === departmentId && obj.isCommon === 0; }
        );
        count++;
      }.bind(this));

    }
    return shopDepartmentList;
  }

  getFavouriteShopId(favouriteShopList:any, currentShop:any) {
    const favouriteId = favouriteShopList.filter(
      (favouriteShopObj:any) => favouriteShopObj === currentShop.id);
    return favouriteId;
  }

  getShopHours(shop:any, serverTime:any) {
    return this.getShopHoursByDate(shop, serverTime);
  }

  formatDate(date: Date): any {
    const dt = date.getDate();
    const month = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    const dd = (dt < 10) ? ('0' + dt) : dt + '';
    const mm = (month < 10) ? ('0' + month) : month + '';

    return (yyyy + '/' + mm + '/' + dd);
  }

  getShopHoursByDate(shop:any, date:any) {

    let shopHoliday = false;
    let shopWorkingInfo: { shopOpen: any; shopStatus: any; currentShopWrkInfo: any; };
    let wrkDayVal: any;
    let wrkDay: any;
    let shopWkgHrsDisplayTime:any;
    let notHoliday: any = [];
    let tomorrowDate = this.datePipe.transform(new Date(+date + 86400000), "yyyy-MM-dd");
    let shopWorkingHours = shop.shopDailyWorkingHours.filter((obj:any) => obj.date === this.datePipe.transform(date, "yyyy-MM-dd"));
    notHoliday = shop.shopDailyWorkingHours.filter((obj:any) => obj.dayType != 1);
    shopWkgHrsDisplayTime = shopWorkingHours[0].displayTime;
    if (notHoliday.length !== 0) {
      const WrkDayIndex = notHoliday.findIndex((element:any) => (element.date === this.datePipe.transform(date, "yyyy-MM-dd")));
      wrkDayVal = notHoliday[WrkDayIndex + 1];
      
      /**
       * If next working day is with in the same month then it should show only the date.
       * If next working day belongs to different month,then show short month name and date.
       */
  if(wrkDayVal !== undefined){
      var df = ((new Date().getMonth() + 1) !== (new Number(this.datePipe.transform(wrkDayVal.date, 'MM')).valueOf())) ? 'MMM Do' : 'Do';
      wrkDay = moment(wrkDayVal.date).format(df);
    }
    }
    if (shopWorkingHours != undefined) {
      shopHoliday = shopWorkingHours[0].dayType == 1;
      const curTime = this.datePipe.transform(date, 'HH:mm:ss');
      const curDay = this.datePipe.transform(date, 'yyyy-MM-dd');
      const openTime = shopWorkingHours[0].openTime;
      const closeTime = shopWorkingHours[0].closeTime;
     

      /**
       * current time is between the working time
       */
      if (openTime <= !curTime && !curTime <= closeTime) {
        shop.status = 'Now Open';
        
      } else {
        /**
         * Just close/Open case
         * If  current time is before opening, 
         * time is + this.datePipe.transform('1900-01-01T' + shopWorkingHours[0].openTime, 'h:mm a')
         * show “Opening Shortly : shopWorkingHourse[0].openTime
         */
        if (openTime >= !curTime) {
          shop.status = 'Opening Shortly';

        } else if (!curTime >= closeTime) {
          /**
           * If  current time is after closing,
           *  show “Closed, Open at  : xxxxx”
           */
          if (notHoliday.length != 0 && wrkDayVal !== undefined) {
            let dayDate = this.getWrkDayInfo(tomorrowDate!, wrkDayVal, date, wrkDay);
            shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
            this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
            shop.status = 'Closed';
          } else {
            shopWkgHrsDisplayTime = '-';
            shop.status = 'Closed';
          }
        }
      }
    } else {

      /**
       *  shop.status = 'Closed';
       * If today is holiday, then show next working day
       * This is the case for undefined working day.
       */
      if (notHoliday.length != 0 && wrkDayVal !== undefined) {
        let dayDate = this.getWrkDayInfo(tomorrowDate!, wrkDayVal, date, wrkDay);
        shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
        this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
        shop.status = 'Closed';
      } else {
        shopWkgHrsDisplayTime = '-';
        shop.status = 'Closed';
      }
    }

  //  let shopWkgHrsDisplayTime = shopWorkingHours[0].displayTime;

    if (shopHoliday) {
      /**
       * shop.status = 'Closed (Holiday)';
       */
      if (wrkDayVal !== undefined && wrkDayVal !== undefined) {
        let dayDate = this.getWrkDayInfo(tomorrowDate!, wrkDayVal, date, wrkDay);
        shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
                                this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
        shop.status = 'Closed (' + shopWorkingHours[0].dayTitle+")";
      } else {
        shopWkgHrsDisplayTime = '-';
        shop.status = 'Closed';
      }
    }
    shopWorkingInfo = { shopOpen: shopWkgHrsDisplayTime, shopStatus: shop.status, currentShopWrkInfo: shopWorkingHours[0] };
    return shopWorkingInfo;
  }

/**
 * 
 * @param tomorrowDate 
 * @param wrkDayVal 
 * @param date 
 * @param wrkDay 
 * @returns 
 */
  getWrkDayInfo(tomorrowDate: string, wrkDayVal: any, date: any, wrkDay: any) {

    let dayDate = (tomorrowDate === wrkDayVal.date) ? ' Tomorrow (' +
      this.dateTimeServiceService.dayOfWeek[new Date(+date + 86400000).getDay()] + ') ' : ' ' + wrkDay +
      ' (' + this.dateTimeServiceService.dayOfWeek[(wrkDayVal.weekDay === 7) ? 0 : wrkDayVal.weekDay] + ') ';

    return dayDate;
  }



  getShopHoursByDate_depri(shop:any, date:any, shopHoliday:any) {
    let shopWorkingInfo;
    const selectedDate = date;
    let slotData: any=[];
    let time: any=[];
    const dt = date;

    // const dt = new Date();
    const shopWorkingHours = shop.shopWorkingHours;
    if (selectedDate.getDay() !== 0) {
      slotData = shopWorkingHours.filter((obj:any) => obj.weekDay === selectedDate.getDay());
    } else if (selectedDate.getDay() === 0) {
      slotData = shopWorkingHours.filter((obj:any) => obj.weekDay === 7);
    } if (slotData!.length === 0) {
      slotData = shopWorkingHours.filter(
        (obj:any) => obj.weekDay === 0
      );
    }
    if (slotData!.length > 0) {
      shop.opens = slotData[0].hoursShop;
      const splitted = slotData[0].hoursShop.split(',');
      for (let i = 0; i < splitted.length; i++) {
        time = splitted[i].split('To');
        const fromTime = time[0].trim();
        const toTime = time[1].trim();
        const currntTimeStr = dt.getHours() + ':' + dt.getMinutes();

        const openTime = slotData[0].openTime;
        const closeTime = slotData[0].closeTime;
        const curTime = this.datePipe.transform(date, 'HH:mm:ss');

        if (openTime <= !curTime && !curTime <= closeTime) {
          shop.status = 'Open Now';
          break;
        } else {
          shop.status = 'Closed Now';
        }
      }
    } else {
      shop.status = 'Closed';
    }
    if (shopHoliday !== null && shopHoliday !== undefined && shopHoliday.length !== 0) {
      shop.status = 'Closed (Holiday)';
    }
    shopWorkingInfo = { shopOpen: shop.opens, shopStatus: shop.status, currentShopWrkInfo: slotData[0] };
    return shopWorkingInfo;
  }
}
