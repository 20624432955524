import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrmApiService } from './crm-api.service';
import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../storage/storage.service';
import { Observable, Subject, catchError, map } from 'rxjs';
import { throwError } from 'rxjs';

import { CUSTOMER_TYPE_GUEST, PROMOTION_ELIGIBILITY_TYPE_REWARD, SESSION_APPLIED_REWARDS, SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_CUSTOMER_REWARDS, SESSION_FILTERED_OFFERS, SESSION_FILTERED_SALE_ITEM_LIST, SESSION_OFFERS, SESSION_SALE_ITEM_LIST } from '../../app.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PromotionItemDialogComponent } from '../../dialogs/promotion-item-dialog/promotion-item-dialog.component';
import { CartService } from '../cart/cart.service';
import { MessageDialogService, NotificationDialogModel } from './message-dialog.service';
import { CartItem } from '../../models/cart.model';
import { NotificationService } from './notifications.service';
import { TaxService } from './tax.service';
import { RewardSnackbarService } from '../../snackbar/reward-snackbar.service';
import { ItemListService } from './item-list.service';

export interface Reward {
  id: number,
}

@Injectable({
  providedIn: 'root'
})

export class RewardService {

  promotions: any;
  rewards: any
  FilteredSaleItemList: any;
  //appliedRewards:any=[];
  offerItem: any;
  // applyRewards: any=[];


  constructor(private http: HttpClient,
    private crmApiService: CrmApiService,
    private storageService: StorageService,
    public dialog: MatDialog,
    private cartService: CartService,
    private messageDialogService: MessageDialogService,
    private notificationService: NotificationService,
    private taxService: TaxService,
    private rewardSnackbarService: RewardSnackbarService,
    private itemListService:ItemListService
  ) {
    this.notificationService.rewardApplied.subscribe(reward => {
      this.rewardSnackbarService.showOfferAppled(reward);
    });
    this.notificationService.rewardRemoved.subscribe(reward => {
      this.rewardSnackbarService.showOfferRemoved(reward);
    });
    // this.notificationService.cartChanged.subscribe(data => {
    //   switch (data) {
    //     case "added":
    //       this.autoloadReward();
    //       break;
      
    //     default:
    //       break;
    //   }
    // });
   }

  /**
   * Returns the loaded item list from session storage.
   * @returns 
   */
  getFilteredSaleItemList(): any[] {
    let sessionFilteredSaleItemList = this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST);
    this.FilteredSaleItemList = (sessionFilteredSaleItemList !== null && sessionFilteredSaleItemList !== undefined) ? JSON.parse(sessionFilteredSaleItemList) : [];
    return this.FilteredSaleItemList;
  }
  
/**
   * Returns the loaded offers from session. 
   * @returns 
   */
getSessionOfferList(): any[] {
  let sessionOfferList = this.storageService.loadFromSession(SESSION_OFFERS);
  return (sessionOfferList !== null && sessionOfferList !== undefined) ? JSON.parse(sessionOfferList) : [];
}
  /**
   * Load and set the customer rewards
   * @param customerId 
   */
  loadCustomerRewards(customerId: any): Observable<any> {
    return this.crmApiService.getRewards(customerId)
      .pipe(
        map((response: any) => {
          if (response.status == 'SUCCESS') {
            const customerRewards = response.data.customer_reward;
            if (customerRewards !== undefined && customerRewards !== null) {
              const activeRewards = customerRewards.filter((cr: any) => cr.customer_status == 1)
              this.storageService.saveToSession(SESSION_CUSTOMER_REWARDS, JSON.stringify(customerRewards));
              this.storageService.saveToSession(SESSION_CUSTOMER_ACTIVE_REWARDS, JSON.stringify(activeRewards));

            }
          } else {
            console.log("No rewards found")
          }
          return response;
        }),
        catchError(error => {
          throw error;
        })
      );

  }
/**
 * Check for any autoload reward is in pending list and force to apply
 */
  checkForAutoloadRewards(){
    var allRewards = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    allRewards = (allRewards !== null && allRewards != undefined && allRewards !== "") ? JSON.parse(allRewards) : [];
    if(allRewards!==null && allRewards !== undefined) {
      allRewards.forEach((promotion: any) => {
        if (promotion.discount.activation_mode == 0) {
          var dlgData: NotificationDialogModel = {
            message: "We recommend this welcome offers just for you!",
            title: "Information",
            yesButtonCaption: "Ok",
          }
          this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
          });
        }
      });
    }
  }

  autoloadReward() {
    // const allRewards = JSON.parse(this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS));
    var allRewards = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    allRewards = (allRewards !== null && allRewards != undefined && allRewards !== "") ? JSON.parse(allRewards) : [];
    if(allRewards!==null && allRewards !== undefined) {
      allRewards.forEach((promotion: any) => {
        if (promotion.discount.activation_mode == 0) {
          if (this.addToAppliedRewards(promotion)) {
            this.notificationService.notifyRewardApplied(promotion);
            this.applyAutoReward(promotion);
          }
        }
      });
    }
  }

  applyAutoReward(promo: any) {
    const promoItems = this.getRelatedItems(promo);
    this.offerItem = this.buildCartItemFromSaleItem(promoItems.offer[0].item, promoItems.offer[0].qty,promo.id);
    this.cartService.addItem(this.offerItem);
    this.notificationService.notifyCartChange(this.offerItem);
  }
  /**
   * Push reward to appled reward
   * @param reward 
   */
  addToAppliedRewards(reward: any): boolean {
    var result:boolean=false;
    var appliedRewards = this.getAppliedRewards();
    if (!appliedRewards.some((ar: any) => ar.id == reward.id)) {
      appliedRewards.push(reward);
      this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(appliedRewards));
      this.notificationService.notifyRewardApplied(appliedRewards);
      result=true;
    }
    return result;
  }

  /**
* Remove the given reward from the array and save to session
* @param reward 
*/
  removeFromAppledRewards(reward: any) {
    var appliedRewards = this.getAppliedRewards();
    const newRewardList = appliedRewards.filter((rw: any) => rw.id !== reward[0].offerId);
    this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(newRewardList));
  }

  /**
   * Returns the applieds rewards
   */
  getAppliedRewards(): any[] {
    var appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));
    return (appliedRewards !== null && appliedRewards != undefined) ? appliedRewards : [];
  }

  /**
   * Extract the promotion for the given sale item
   * @param itemId 
   * @returns 
   */
  getItemPromotion(itemId: any): any {
    var filteredRewards = [];
    var promotions = [];
    var filteredOffers = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_OFFERS));
    filteredOffers = (filteredOffers !== null && filteredOffers != undefined) ? filteredOffers : [];
    var customerRewards = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    customerRewards = (customerRewards !== null && customerRewards != undefined && customerRewards !== "") ? JSON.parse(customerRewards) : [];
    var appliedRewards = this.getAppliedRewards();

    filteredRewards = customerRewards.filter((cr: any) => { !appliedRewards.some((ar: any) => ar.id == cr.id) });
    promotions = [...filteredRewards, ...filteredOffers];
    const buyPromo = promotions.filter((item: any) => {
      if (item.discount.buy.based_on === 'sale_item') {
        return item.discount.buy.trigger_on.some((trigger: any) => trigger.id === itemId);
      }
      return false;
    });
    const offerPromo = promotions.filter((item: any) => {
      if (item.discount.offer.type === 'sale_item') {
        return item.discount.offer.offer.some((offer: any) => offer.id === itemId);
      }
      return false;
    });
    const itemPromo = { buy: buyPromo, offer: offerPromo };
    return itemPromo;
  }

  /**
   * Checks for promotions
   * @returns 
   */
  hasPromotion(itemId: any): boolean {
    this.promotions = this.getItemPromotion(itemId)
    return ((this.promotions && this.promotions.buy && this.promotions.buy.length > 0) || (this.promotions && this.promotions.offer && this.promotions.offer.length > 0));
  }


  /**
   * getting the buy and offer from the saleItem according to offer
   * @param offer 
   * @returns 
   */
  getRelatedItems(offer: any) {

    const allSaleItem = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    const buyItemsId = offer.discount.buy.trigger_on;
    const offerItemsId = offer.discount.offer.offer;

    var offerItemList = [];
    var buyItemList = [];

    if (buyItemsId !== undefined && buyItemsId.length > 0) {
      buyItemList = buyItemsId.map((of: any) => ({
        item: allSaleItem.find((item: any) => of.id == item.saleItemId),
        qty: of.qty
      })).filter((e: any) => e.item !== undefined);
    }

    if (offerItemsId !== undefined && offerItemsId.length > 0) {
      offerItemList = offerItemsId.map((of: any) => ({
        item: allSaleItem.find((item: any) => of.id == item.saleItemId),
        qty: of.qty
      })).filter((e: any) => e.item !== undefined);
    }

    var offerItems = { buy: buyItemList, offer: offerItemList };
    return offerItems;
  }

  /**
   * For items in the reward validate before apply the reward.
   */
  offerItemvalidation(offer:any){
    var discountParams=offer.discount;
    var offerItems= this.getRelatedItems(offer);
        switch (offer.reward_type) {
          case 5:
            if(offerItems.offer == undefined || offerItems.offer.length == 0){
              return false;
            }
            else{
              const validItemList=this.compareOfferArrays(discountParams.offer.offer,offerItems.offer)
              if(validItemList !== undefined && validItemList.length > 0){
                discountParams.offer.offer=validItemList;
                return true;
              }
              else{
                return false;
              }
            }
          case 4:
            if(offerItems.offer == undefined ||offerItems.buy == undefined){
              return false;
            }else{
              var validItemList=this.compareOfferArrays(discountParams.buy.trigger_on,offerItems.buy);
              if(validItemList !== undefined && validItemList.length > 0){
                discountParams.buy.trigger_on=validItemList;
                // console.log(discountParams.buy.trigger_on)
              }else{
                return false;
              }
              validItemList=this.compareOfferArrays(discountParams.offer.offer,offerItems.offer);
              if(validItemList !== undefined && validItemList.length > 0){
                discountParams.offer.offer=validItemList;
                // console.log(discountParams.offer.offer)
                return true;
              }else{
                return false;
              }  
            }
          default:
            return false;
        }
  }

  compareOfferArrays(offerParams: any, offerItem:any): []{
    return offerParams.filter((oi:any) => offerItem.some((op:any) => oi.id === op.item.saleItemId));
    // return offerParams.filter((oi:any) => offerItem.some((si:{item:{saleItemId:any}})=>si.item.saleItemId == oi.id));
    // return offerParams.every((oi:any) => offerItem.some((op:any) => oi.id === op.item.saleItemId));
  }

  checkForPromotionValidity(promo:any){
    var custRewards = this.getCustomerRewards();
    const isValid = custRewards.some((cr: any) => cr.id = promo.id);
    return isValid;
  }
  /**
   * For showing the items according to the current offer
   * @param offer 
   */
  showPromotionSelection(offer: any, source: { type: any, data: any } = { type: 'offer', data: offer }) {
    // console.log(offer
    const promoItems = this.getRelatedItems(offer);
    var isApplied = false;
    if (offer.reward_eligibility == 2) {
      const promoCartItem = this.cartService.getItemWithPromotion(offer.id);
      if ((promoCartItem != null && promoCartItem != undefined)) {
        isApplied = promoCartItem.offers.some((of: any) => of.offerId == offer.id);
      }
      // if (offer.discount.activation_mode == 0){
      //   isApplied = true;
      // }
    }
    if (isApplied) {
      var dlgData: NotificationDialogModel = {
        message: `This reward has been added to the cart and requires a minimum order of $1. You can use this reward only once.`,
        title: "Reward Already Added",
        yesButtonCaption: "Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
      });
    } else {
      if (promoItems !== undefined && promoItems !== null) {
        const dlgData = { promoItemList: promoItems, offer: offer, source: source }
        this.dialog.open(PromotionItemDialogComponent, {
          panelClass: 'app-message-dialog',
          data: dlgData,
          maxHeight: '80vh'
        })
      }
    }
  }

  /**
   * Returns the loaded rewards
   * @param id 
   * @returns 
   */
  // getMyRewards(id: any): any[] {
  //   let sessionRewards = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
  //   const customerRewards = (sessionRewards !== null && sessionRewards !== undefined) ? JSON.parse(sessionRewards) : [];
  //   return customerRewards;
  // }

  /**
   * Returns the customer rewards
   */
  getCustomerRewards() {
    const customerRewards = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    return (customerRewards !== null && customerRewards !== undefined && customerRewards.length > 0) ? JSON.parse(customerRewards) : [];
  }

  /**
   * for getting the offer items in offer categorie
   * @param promo 
   */
  getPromoSaleItemIds(promo: any) {
    const promotions = this.getRelatedItems(promo)
    var promoItemList: any = [];
    if (promotions.buy !== null && promotions.buy.length > 0) {
      promoItemList = promotions.buy.map(function (promoItem: any) {
        return promoItem.item.id
      });
    } else {
      promoItemList = promotions.offer.map(function (promoItem: any) {
        return promoItem.item.id
      });
    }
    const filteredSaleItemList = this.getFilteredSaleItemList();
    filteredSaleItemList.forEach((fr: any) => {
      promoItemList.some((pr: any) => pr.id == fr.id)
    });
    return promoItemList;
  }

  /**
   * Creates dummy item categories for each offers
   * @returns 
   */
  getPromotionalCategoriesWithItems(): any[] {

    var filteredOffers = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_OFFERS));
    filteredOffers = (filteredOffers !== null && filteredOffers !== undefined && filteredOffers.length > 0) ? filteredOffers : [];
    const customerRewards = this.getCustomerRewards();

    var promotions = [...filteredOffers, ...customerRewards].filter((promo: any) => promo.show_in_offers == 1);
    var promoCat: any[] = promotions.map((promo: any) => ({
      id: promo.id,
      code: promo.code,
      name: promo.name,
      saleItemIds: this.getPromoSaleItemIds(promo)
    }));
    return promoCat;

  }

  /**
   * 
   * @param item 
   */
  checkForOffer(item: CartItem, offerType: String = "buy") {
    // { buy: buyPromo, offer: offerPromo };
    const offers = this.getItemPromotion(item.saleItemId);
    if (offerType == "buy" && offers.buy !== undefined && offers.buy.length > 0) {
      var isValid=this.cartService.isOfferEnabledForCust(offers.buy[0])
      if(isValid) {
      this.showPromotionSelection(offers.buy[0], { type: "cart-item", data: item })
      }
      else{
        // var dlgData: NotificationDialogModel = {
        //   message: `You must login to get this offer.`,
        //   title: "Please Login",
        //   yesButtonCaption: "Ok",
        // }
        // this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        // });
      }
    }

  }

  buildCartItemFromSaleItem(offerItemInfo: any, qty: any,offerId:any): any {
    var carItem = {
      itemId: 0,
      ooSaleItemId: offerItemInfo.id, saleItemId: offerItemInfo.saleItemId, quantity: qty,
      fixedPrice: offerItemInfo.fixedPrice,
      totalPrice: (offerItemInfo.fixedPrice * qty),
      unitPriceWithTax: offerItemInfo.unitPriceWithTax,
      customizationUnitPriceWithTax: 0,
      isCustomized: false, customization: [], remarks: '',
      taxCalculationMethod: offerItemInfo.taxCalculationMethod, tax1Percentage: offerItemInfo.tax1Percentage,
      netCustomizationTotal: 0,
      netTotal: 0, totalTax: 0,
      offerId:offerId
    }
    this.cartService.setCustomization(carItem);
    this.taxService.reCalculateTax(carItem);
    return carItem;
  }

  /**
   * To get the condtion description to get the offer
   * @param cartItem 
   * @returns 
   */
  getOfferPendingDescription(cartItem: any): any {
    var description: String = "";
    var allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));
    var saleItemInfo = allSaleItemList.find((asl: any) => asl.id === cartItem.ooSaleItemId);
    var allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    if (saleItemInfo!==null && saleItemInfo!== undefined) {
    var offer = this.getItemPromotion(saleItemInfo.saleItemId);
    
    // var isOfferEnabled=this.isOfferEnabledForCust(offer.offer[0])
    // if(!isOfferEnabled){
    //   description = "You must Login to get this offer";
    // }
    // else{
      if (cartItem.offerPendingMessage != undefined && cartItem.offerPendingMessage.length > 0) {
        description = cartItem.offerPendingMessage;
      } else {
  
        if (offer.buy.length > 0) {
          var promo = offer.buy[0];
          description = (promo.short_description != undefined && promo.short_description.length > 0) ? promo.short_description : promo.description;
  
        } else if (offer.offer.length > 0) {
          var promo = offer.offer[0];
  
          description = (promo.short_description != undefined && promo.short_description.length > 0) ? promo.short_description : promo.description;
          description = cartItem.offerPendingMessage;
  
        }
      }
    }
    // }
    return (description !== undefined && description.length > 0) ? { description: description, showPopUp: offer.buy.length > 0 } : undefined;
  }
  /**
   * 
   * @param offer 
   * Check offers enabeled for to apply offer for current customer
   */
  isOfferEnabledForCust(offer: any): boolean {
    var isValid=true;
        if (offer.enabled_for !== null) {
            if(this.cartService.isGuestLogin()){
              if(!offer.enabled_for.some((pr: any) => pr==CUSTOMER_TYPE_GUEST)){
                isValid=false;
              }
            }
          }
          return isValid;
  }

}