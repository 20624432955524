
export const CUSTOMER_TYPE_GUEST = 2;
export const CUSTOMER_TYPE_REGUSER = 1;
export const CUSTOMER_TYPE_SOCIAL = 0;

export const PROMOTION_ELIGIBILITY_TYPE_REWARD = 2;
export const PROMOTION_ELIGIBILITY_TYPE_OFFER = 1;

export const PRODUCT_IMAGE_FOLDER  = '/images/product/';
export const ERROR_CODE_OUT_OF_STOCK ='102';

export const SESSION_SHOP_LIST = 'o_a';
export const SESSION_NEAREST_SHOP_LIST = 'o_b';
export const SESSION_CUSTOMER_ACTIVE_REWARDS = 'o_c';
export const SESSION_CUSTOMER_REWARDS = 'o_d';
export const SESSION_CART = 'o_e';
export const SESSION_OFFERS = 'o_f';
export const SESSION_FILTERED_OFFERS = 'o_g';
export const SESSION_APPLIED_REWARDS = 'o_h';
export const SESSION_AUTH_TOKEN = 'o_i';
export const SESSION_AUTHENTICATED_CUSTOMER = 'o_j';
export const SESSION_DEPARTMENT_LIST = 'o_k';
export const SESSION_PICKUP_LOCATION_LIST='o_l';
export const SESSION_AMENITY_LIST = 'o_m';
export const SESSION_BUSINESS_TYPE_LIST = 'o_n';
export const SESSION_ONLINE_SERVICE_LIST = 'o_o';
export const SESSION_SELECTED_SHOP_LIST = 'o_p';
export const SESSION_ITEM_CATEGORY_LIST = 'o_q';
export const SESSION_FILTERED_SALE_ITEM_LIST='o_r';
export const SESSION_FILTERED_CATEGORY_ITEM_LIST = 'o_s';
export const SESSION_SELECTED_SUBCATEGORY_ID = 'o_t';
export const SESSION_SELECTED_SHOP='o_u';
export const SESSION_SALE_ITEM_LIST='o_v';
export const SESSION_SYSTEM_SETTINGS = 'o_x';
export const SESSION_OUTOFSTOCK_ITEM_LIST = 'o_y';
export const SESSION_COMPANY_INFO = 'o_z';
export const SESSION_PROMOTIONAL_SALEITEM_IDS = 'o_aa';
export const SESSION_TOKEN = 'o_ab';
export const SESSION_CONFIRMATION_MESSAGE = 'o_ac';
export const SESSION_LAST_SUCCESS_TXN_ID = 'o_ad';
export const SESSION_LAST_SUCCESS_TAX_INVOICE_NO = 'o_ae';
export const SESSION_LAST_SUCCESS_TXN_RECEIPT = 'o_af';
export const SESSION_LAST_TXN_RECEIPT_DOWNLOADED = 'o_ag';
export const SESSION_START_TIME = 'o_ah';
export const SESSION_MASTER_CUSTOMIZATION = 'a_ai';
export const SESSION_MASTER_COMBO_CUSTOMIZATION='o_aj';

