<div class="back-strip font-secondary bg-white firstDiv">
   <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">
            @if (isSearchItem) {
               <app-search-item (backClick) = "backClick($event)" style="width: 100%;"></app-search-item>
            }
            @else {
               <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                  src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                  class="align-middle d-none d-lg-inline">BACK</span></button>
               <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">Item List</p>
               <button class="btn btn-outline-dark rounded-pill py-2 px-3 ms-lg-5 searchButton" (click)="searchAllItems()"><img
                  src="assets/icons/search_icon.svg" class="me-lg-2 white-icon" alt="" style="width: 20px;"><span
                  class="align-middle d-none d-lg-inline">SEARCH</span></button>
            }
   </div>
</div>
<div class="container pt-1 shop-item-list-root-container">
   <div class="d-flex py-2">
      <div class="d-flex flex-wrap align-items-center">
         <h5 class="mb-2 mb-lg-0 line-clamp" style="--truncate-lines:2">{{selectedShop.name}}</h5>
         <p class="ms-lg-3 ps-lg-1 mb-0 col-12 col-lg-auto">
            <img src="assets/icons/clock_icon.svg" width="24" class="me-2" alt="">
            <span class="align-middle">{{deliveryMethod.name}} {{ deliveryDate.label}} {{deliveryDateTime |
               date:'shortTime'}}</span>
         </p>
      </div>
      <div class="col-auto ms-auto ms-lg-4">
         <button class="btn btn-outline-dark rounded-pill font-secondary px-2 px-sm-3 py-2 ms-1 ms-sm-0"
            (click)="navigateToshopList()">
            <img src="assets/icons/edit_icon.svg" class="me-sm-2 white-icon" alt=""> <span
               class="d-none d-sm-inline">Change</span>
         </button>
      </div>
   </div>
   <div class="nav nav-pills flex-nowrap d-flex mb-3 overflow-auto w-100 scrollbar" id="pills-tab" [class.sticky]="isSticky">
      @for(pCat of parentCategoryList; track $index) {
      <div class="nav-link btn py-2 px-5 rounded-pill text-nowrap  border justify-content-center align-items-center"
         id="pills-main-cat-{{pCat.code?.toString() | lowercase}}" (click)="onParentCategorySelected(pCat)"
         [class.active]="pCat.id==selectedParentCategory.id" style="display: flex; text-transform: uppercase;">
         {{pCat.name}}
      </div>
      }
   </div>
   <div class="tab-content" id="outer-tabs">
      <div class="tab-pane outer-tab active show" id="pills-tab">
         <div #itemCatDiv [class.stickysub]="isSticky"
            class="nav nav-pills flex-nowrap d-flex mb-3 overflow-auto w-100 scrollbar" id="pills-sub-cat-list">
            <!-- <div #itemCatDiv [class.sticky]="isSticky"
            class="nav nav-pills flex-nowrap d-flex mb-3 overflow-auto w-100 theme-scrollbar" id="pills-sub-cat-list" > -->
            @if (selectSubCategoryList!==null) {
               @for(sCat of selectSubCategoryList; track $index) {
                  @if(isValidCatergory(sCat)){
                  <div #targetElement class="nav-link btn rounded-1 text-nowrap py-2 border justify-content-center align-items-center"
                     id="pills-sub-cat-{{sCat.code?.toString() | lowercase}}" [class.active]="sCat.id==selectedSubCategory.id"
                     (click)="onSubCategorySelected(sCat)">
                     {{sCat.name}}</div>
                  }
               }
            }
         </div>
      </div>
      <div class="tab-content item-list-container" id="inner-tabs" #scrollableContainer>
         @if (selectSubCategoryList!==null) {
            @for(sCat of selectSubCategoryList; track $index) {
            @if(isValidCatergory(sCat)){
            <div class="tab-pane inner-tab show active section" id="pills-sub-cat-{{sCat.code?.toString() | lowercase}}-tab" role="tabpanel"
               tabindex="{{$index}}">
               <div class=" text-lg-start my-1 my-lg-0">
                  <h3 class="d-inline-block font-secondary mb-2 mb-lg-3 mt-2 mt-lg-4 ">
                     <!-- <img src="assets/images/sticker_bg.svg" width="518" class="img-fluid" alt=""> -->
                     <span
                        class=" w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center">{{sCat.name}}</span>
                  </h3>
               </div>
               <div class="row shop-items-row mb-2">
                  @for(item of getItemList(sCat); track item.saleItemId) {
                     @if (item !== undefined && item.isDirectSaleAllowed === 1) {
                        <app-shop-item-card class="col-md-6 col-lg-4 col-xl-3" [item]="item" (add)="addToCart($event)"
                     (customize)="itemSelected($event)" (select)="itemSelected($event)"></app-shop-item-card>
                     }    
                     }
               </div>
            </div>
            }
            }
         }
      </div>
   </div>
</div>