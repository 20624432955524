
<!-- <div class="pb-1">
    <button
    class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none"
    onclick="history.back()"
  >
    <img src="assets/icons/back_arrow_icon.svg" class="white-icon" alt="" />
  </button>
    <h3 class="font-secondary" style="    display: flex; align-content: center; flex-wrap: wrap; height: 42px;">Apply Reward</h3>
</div> -->
<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-1">
    <div class="container py-3 pt-lg-2 font-secondary">
      <button
        class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none"
        onclick="history.back()"
      >
        <img src="assets/icons/back_arrow_icon.svg" class="white-icon" alt="" />
      </button>
      <h2
        class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-4 pb-lg-2 page-heading text-center align-middle "
      >
      MY Reward
      </h2>
      <div class="qr-code-container"></div>p
    </div>
  </div> -->

  <!-- <div class="position-sticky back-strip bg-white">
    <div class="container py-3 font-secondary">
      <button
        class="btn btn-outline-dark rounded-pill py-2 px-3"
        (click)="back()"
      >
        <img
          src="assets/icons/back_arrow_icon.svg"
          class="me-lg-2 white-icon"
          alt=""
        />
        <span class="align-middle d-none d-lg-inline">BACK</span>
      </button>
      <p
        class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
      >
      MY Reward
      </p>
    </div>
  </div> -->
<div class="back-strip font-secondary bg-white firstDiv">
   <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
               <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                  src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                  class="align-middle d-none d-lg-inline">BACK</span></button>
               <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle"> MY Rewards</p>
   </div>
</div>
  
<section class="overflow-auto theme-scrollbar container pt-3 pb-3" mat-content>
  <div class="reward-items-row ">
    @for (reward of rewardsData; track $index) {
      <div class="  order-card   rounded-1 card-shadow  "  content-item>

         <app-my-reward-card [reward]="reward" ></app-my-reward-card>
        
        <!-- <div style="width: 100%; background-color:white;height:100px; padding: 20px; ">
          <button class="btn rounded-pill py-2 px-4 font-secondary mt-2"
          [style.backgroundColor]="getRewardStatus(reward.customer_status).color"
          style="color: white;">
    {{ getRewardStatus(reward.customer_status).text }}
   
  </button>
  <div *ngIf="getRewardStatus(reward.customer_status).description" style="color:black;" class="pt-2">
   ({{ getRewardStatus(reward.customer_status).description }})
  </div>
        </div> -->
         <div style="width: 100%; background-color: white; height: 100px; padding: 15px; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);">
          <button   type="button" class="btn btn-secondary btn-sm"
          [style.backgroundColor]="getRewardStatus(reward).color"
          style="color: white; border: none;">
    {{ getRewardStatus(reward).text }}
    
   
  </button>
  
  <div *ngIf="getRewardStatus(reward).description" style="color:black;" class="pt-2">
   {{ getRewardStatus(reward).description }}

  </div>
        </div>
        </div>

       
        
  
    }
  </div>
</section>
<!-- <div class="reward-items-row gap-2">
  @for (reward of rewardsData; track $index) {
    <div class="  order-card   rounded-1 card-shadow  col-lg-5 col-xl-5"  content-item>

       <app-my-reward-card [reward]="reward" ></app-my-reward-card>
      
     
       <div style="width: 100%; background-color: white; height: 100px; padding: 15px; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);">
        <button   type="button" class="btn btn-secondary btn-sm"
        [style.backgroundColor]="getRewardStatus(reward).color"
        style="color: white; border: none;">
  {{ getRewardStatus(reward).text }}
  
 
</button>

<div *ngIf="getRewardStatus(reward).description" style="color:black;" class="pt-2">
 {{ getRewardStatus(reward).description }}

</div>
      </div>
      </div>

     
      

  }
</div> -->