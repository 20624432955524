import { Component, EventEmitter, HostListener, Input, Output, ViewChild, inject } from '@angular/core';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { ShopService } from './shop.service';
import { SESSION_DEPARTMENT_LIST, SESSION_NEAREST_SHOP_LIST, SESSION_SHOP_LIST } from '../../app.constants';
import { filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HomeService } from '../home/home.service';
import { Router } from '@angular/router';
import { TimeService } from '../../services/shared/time-service.service';
import { OrderScheduleComponent } from '../order-schedule/order-schedule.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/shared/notifications.service';
import { UtilService } from '../../services/shared/util.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger,MatAutocomplete} from '@angular/material/autocomplete';
import { SearchItemComponent } from '../shop-items/components/search-item/search-item.component';
import { SerachShopComponent } from './components/serach-shop/serach-shop.component';

@Component({
  selector: 'app-recent-shops',
  standalone: true,
  imports: [CommonModule,FormsModule,MatAutocompleteModule,ReactiveFormsModule,RouterModule,SerachShopComponent],
  templateUrl: './recent-shops.component.html',
  styleUrl: './recent-shops.component.scss'
})
export class RecentShopsComponent {
  responsive: any = false;
  @ViewChild('homeSearch') homeSearch!: MatAutocompleteTrigger;
  filteredShopList: any = [];
  searchControl = new FormControl('');
  innerWidth: any;
  nearestShopList: any;
  nearestShopLists: any;
  shopList: any;
  allShopList: any;
  serverTime: any;
  localserverTime: any;
  shopWorkingInfo: any;
  day: any;
  shoptime: any;
  hasNearestShop: boolean = false;
  locationCoordinateData: any;
  isCurrentLocationAvilable = false;
  radius = environment.oos.initialRad;
  showMoreButton: boolean = false;
  departmentList: any = [];
  department: any;
  recentOrdersList: any;
  lastOrderShopName: any;
  msg: any;
  src: any;
  loading: boolean = false;
  redirectUrl: any;
  selectedShopList: any;
  isRegionListOpen: any;
  showError: any;
  sessionNearestShopLists: any;
  isOrderScheduleTrgd: boolean = false;
  isSearchItem: boolean=false;
  nearestShopCount: number=0;

  constructor(private location: Location,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private shopService: ShopService,
    private homeService: HomeService,
    private router: Router,
    private timeService: TimeService,
    private notificationService: NotificationService,
    private utilService: UtilService) {
    // this.notificationService.recentOrders.subscribe(recentOrder => {
    //   this.recentOrdersList = recentOrder;
    //   this.lastOrderShopName = this.recentOrdersList[0].shopName;
    //   // this.customerName = sessionCustomerDetails.customer.firstName;
    // });
  }
  back() {
    this.location.back()
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 767) {
      this.responsive = true;
    }
  }
  ngOnInit(): void {

    this.utilService.scrollToTop();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 767) {
      this.responsive = true;
    }
    this.localserverTime = new Date();
    /**
   * jojesh commented out
   */
    // this.id = this.route.snapshot.paramMap.get('id');
    this.src = this.route.snapshot.queryParams['src'];
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    this.day = weekday[this.localserverTime.getDay()];
    const getColonTimeFromDate = (date: any) => date.toTimeString().slice(0, 8);
    this.shoptime = getColonTimeFromDate(new Date(this.localserverTime + 1000 * 60 * 30 * 7));

    this.allShopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    // this.nearestShopList = JSON.parse(this.storageService.loadFromSession(SESSION_NEAREST_SHOP_LIST));
    this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    this.setShopList();
  }

  setShopList() {
    this.sessionNearestShopLists = JSON.parse(this.storageService.loadFromSession(SESSION_NEAREST_SHOP_LIST));
    if (this.sessionNearestShopLists !== null && this.sessionNearestShopLists !== undefined && this.sessionNearestShopLists.length>0){
      this.nearestShopList =  this.sessionNearestShopLists;
      this.showMoreButton = true;
      this.hasNearestShop=true;
      this.shopList=[];
      this.nearestShopCount=this.sessionNearestShopLists.length;
      this.nearestShopList.forEach((ns:any) => {
        this.shopList.push(this.allShopList.find((as:any)=>as.id==ns.id));
      });
    }else{
      this.shopList =this.allShopList;
    }
}

  sortByDistance(shopList:any){
    const sortedShopList=shopList.sort(function (a: any, b: any) {
      if (a.distance < b.distance) return -1;
      if (a.distance > b.distance) return 1;
      return 0;
    });
    return sortedShopList;
  }

  sortedShopListByDistance(allshopLists:any){
    const reorderedArray = [];
    for (const item1 of this.nearestShopList) {
      const foundIndex = allshopLists.findIndex((item2: any) => item2.name === item1.name);

      if (foundIndex !== -1) {
        reorderedArray.push(allshopLists[foundIndex]); // Add matching object
      } else {
        // Handle missing objects if needed (optional)
      }
    }
    return reorderedArray;
  }

  getShopDistance(shopId: Number): number {
    if (this.nearestShopList !== null && this.nearestShopList !== undefined) {
      const shop = this.nearestShopList.find((s: any) => s.id == shopId);
      return shop.distance;
    }
    else {
      const shop = this.allShopList.find((s: any) => s.id == shopId);
      return shop.distance;
    }
  }

  isBentoShop(shopId: Number): boolean {
    const shop = this.shopList.find((s: any) => s.id == shopId);
    const shopDepartmentList = shop.department;
    const bentoDpt = this.departmentList?.find((dpt: any) => dpt.code == 'BENTO');
    const shopBentodpt = shopDepartmentList?.find((dpt: any) => dpt == bentoDpt.id);
    return (shopBentodpt !== undefined && shopBentodpt !== null);
  }

  getNearestshop() {
    // this.radius = this.radius ;
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const lng = +pos.coords.longitude;
          const lat = +pos.coords.latitude;
          this.locationCoordinateData = { 'longitude': lng, 'latitude': lat, 'radius': this.radius };
          this.getShopListByLocation(this.locationCoordinateData);
        },
        err => {
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  }
  getShopListByLocation(locationData: any) {
    this.isCurrentLocationAvilable = false;
    // this.shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.homeService.getNearestShops(locationData).subscribe(
      (res) => {
        this.nearestShopLists = res.object;
        var newShopCount = this.nearestShopLists.length;
        if(this.nearestShopCount<newShopCount){
          this.storageService.saveToSession(SESSION_NEAREST_SHOP_LIST, JSON.stringify(this.nearestShopLists));
        this.setShopList();
        }
      }
    );
  }

  /**
   * jojesh commented out
   */
  // shopWorkingInfoChecking(shop:any) {
  //   this.serverTime=this.timeService.getServerTime()
  //   this.shopWorkingInfo = this.shopService.getShopHours(shop, this.serverTime);
  //   console.log(this.shopWorkingInfo);
  // }

  showMore() {
    /**
     * jojesh
     * We need to show distance
     */

    this.radius+=5;
    this.getNearestshop();
  }

  /**
   * For normal Item selection and add to the cart
   * @param buzType 
   * @param shop 
   */
  scheduleDialog(buzType: any, shop: any) {
    const dlgData = { businessType: buzType, shop: shop, redirectUrl: this.redirectUrl, msg: this.msg }
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
  }

  /**
   * For offer Item add to cart for this already item added and after that shop selection
   * @param buzType 
   * @param shop 
   */
  navigateForOfferSelection(buzType: any, shop: any) {
    const dlgData = { businessType: buzType, shop: shop, redirectUrl: '/shoppingCart', msg: this.msg }
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
  }
 

  searchAllItems() {
    this.isSearchItem = true;
  }

  // itemClick(itemName:any) {
  //   this.userenter = itemName;
  //   this.router.navigate(['/all-item-list'], { queryParams: { 'search': this.userenter } });
  // }

  backClick(event:any) {
    this.isSearchItem = false;
  }
}
