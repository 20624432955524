import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarData } from './model/snackbar.model';
import { MatModule } from '../../modules/mat.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notification-snackbar',
  standalone:true,
  imports:[CommonModule,MatIconModule],
  templateUrl: './notification-snackbar.component.html',
  styleUrls: ['./notification-snackbar.component.scss'],
  encapsulation:ViewEncapsulation.None
})


export class NotitificationSnackBarComponent {


  constructor(private snackbar :MatSnackBar, public sbRef: MatSnackBarRef<NotitificationSnackBarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }

  closeSnackbar(): void{
    this.snackbar.dismiss();
  }

}
