import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { StorageService } from '../../../../services/storage/storage.service';
import { SESSION_FILTERED_SALE_ITEM_LIST } from '../../../../app.constants';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-search-item',
  standalone: true,
  imports: [CommonModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, RouterModule],
  templateUrl: './search-item.component.html',
  styleUrl: './search-item.component.scss',
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'item-search-autocomplete' },
    },]
})
export class SearchItemComponent {
  searchControl = new FormControl('');
  itemList: any = [];
  filteredItemList: any = [];
  @Output() backClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('item', { static: true }) itemInput!: ElementRef;

  constructor(private storageService: StorageService, private router: Router) {
    const itemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));
    this.itemList = itemList.sort(function (a: any, b: any) {
      if (a.name < b.name) return -1; // Sort ascending (a before b)
      if (a.name > b.name) return 1; // Sort ascending (b before a)
      return 0; // Equal names
    })
  }

  ngOnInit() {
    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.filterItems();
    });
    this.setFocus();
  }

  /**
   *  Filter the itemList using JavaScript's filter() method
   */
  filterItems() {
    const searchTerm = this.searchControl.value?.toLocaleLowerCase();
    this.filteredItemList = this.itemList.filter((item: any) => {
      return item.name.toLowerCase().includes(searchTerm);
    });
  }

  /**
   * Navigate to item or combo details page
   * @param item 
   */
  navigateToItemDetail(item: any) {
    if(item.itemType == 3){
      this.router.navigate(['/comboDetail'], { queryParams: { 'source': '', 'id': item.saleItemId } });
    }else{
    this.router.navigate(['/itemDetail'], { queryParams: { 'source': '', 'id': item.saleItemId } });
    }
  }

  backButton() {
    this.backClick.emit();
  }
  setFocus(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      document.getElementById("user-enter")?.focus();
    },1); 
  }
}