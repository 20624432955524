import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ComboService {

    private combo: any;


    setCombo(combo: any): void {
        this.combo = combo;
    }

    getCombo(): any {
        return this.combo;
    }

    getComboOption(optionId: any): any {
        return this.combo.comboOptions.find((sc: any) => sc.comboOptId == optionId);
    }

    getComboOptionValueByValueId(optionValueId: any) {
        for (const option of this.combo.comboOptions) {
            for (const value of option.optionValue) {
                if (value.id == optionValueId) {
                    return value;
                }
            }
        }
        return null;
    }

    getComboOptionValueBySaleItem(optionId: any, saleItemId: any) {
        const comboOption = this.getComboOption(optionId);
        var comboOptionValue = undefined;
        if (comboOption !== null && comboOption !== undefined) {
            for (const value of comboOption.optionValue) {
                if (value.saleItem.saleItemId == saleItemId) {
                    comboOptionValue = value;
                }
            }
        }
        return comboOptionValue;
    }

    getSaleItem(optionId: any, saleItemId: any):any{
        const comboOption = this.getComboOption(optionId);
        var saleItem = undefined;
        if (comboOption !== null && comboOption !== undefined) {
            for (const value of comboOption.optionValue) {
                if (value.saleItem.saleItemId == saleItemId) {
                    saleItem = value.saleItem;
                }
            }
        }
        return saleItem;
    }
    

    updateSaleItem(optionValueId: any,saleItem:any){

        var optionValue=this.getComboOptionValueByValueId(optionValueId);

        optionValue.saleItem=saleItem;

    }


    // selectedCombo: any;
    // currentComboItem: any;
    // constructor(){}

    // setEditingItem(comboItem:any){
    //     this.selectedCombo=comboItem;
    // }

    // updateComboCustomization(comboItemDetail:any){
    //     var currentComboOptionIndex = this.selectedCombo.comboOptions.findIndex((sc:any) =>sc.comboOptId==comboItemDetail.comboOptId);
    //     var index = this.selectedCombo.comboOptions[currentComboOptionIndex].optionValue.findIndex((co:any) =>co.saleItemId==comboItemDetail.saleItemId);
    //     this.selectedCombo.comboOptions[currentComboOptionIndex].optionValue[index] = comboItemDetail;
    //     this.selectedCombo.unitPriceWithTax=this.selectedCombo.unitPriceWithTax+this.selectedCombo.comboOptions[currentComboOptionIndex].optionValue[index].totalPrice;
    // }

    // getEditingItem(){
    //     return this.selectedCombo;
    // }

    // getComboItem(){
    //     return this.currentComboItem;
    // }


}