<div class="d-flex rounded-1 p-3 mb-2 reward-container">
    @if(hasImageError){
    <div class="d-flex rounded-1 p-3 content-item">
        <div class="col">
            <h5 class="mb-3">{{reward.name}}</h5>
            <h6 class="mb-0 line-clamp" style="line-height: var(--h3);--truncate-lines:3">
                {{reward.description}}
            </h6>
        </div>
        @if (reward.crm_event_id==1) {
        <div class="col-auto ms-3 reward-img">
            <img src="assets/images/icons/welcome-offer.svg" width="176" height="176" alt="">
        </div>
        }
        @else {
        <div class="col-auto ms-3 reward-img">
            <img src="assets/images/rewards/special-offer-default.svg" width="176" height="176" alt="">
        </div>
        }

    </div>
    <div class="d-flex ms-1 ms-sm-5 text-center reward-action-container">
        @if (isRewardApplied(reward)) {
        <!-- @if (reward.discount.activation_mode!==0) { -->
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate remove-btn"
            type="button" (click)="removeReward(reward)"
            [disabled]="reward.discount.activation_mode==0?true:false">Remove</button>
        <!-- } -->
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate  applied-btn">
            <img src="assets/icons/double_check_icon.svg"><span class="ms-1 align-middle">Applied</span></button>
        } @else {
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate apply-btn"
            type="button" (click)="applyReward(reward)">Apply</button>
        }
    </div>
    }@else{
    @if (reward.crm_event_id==1) {
    <img #rewardImg src="{{crmAssetsUrl}}/images/reward/{{reward.image_file_name}}" class="reward-dish-img"
        alt="rewardImage" onerror="this.src='assets/images/icons/welcome-offer.svg'" style="object-fit: contain;">
    }
    @else {
    <img #rewardImg src="{{crmAssetsUrl}}/images/reward/{{reward.image_file_name}}" class="reward-dish-img"
        alt="rewardImage" onerror="this.src='assets/images/rewards/special-offer-default.svg'" style="object-fit: contain;">
    }
    <div class="d-flex ms-1 ms-sm-5 text-center reward-action-container">
        @if (isRewardApplied(reward)) {
        @if (reward.discount.activation_mode!==0) {
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate remove-btn"
            type="button" (click)="removeReward(reward)">Remove</button>
        }
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate  applied-btn">
            <img src="assets/icons/double_check_icon.svg"><span class="ms-1 align-middle">Applied</span></button>
        } @else {
        <button
            class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate apply-btn"
            type="button" (click)="applyReward(reward)">Apply</button>
        }
    </div>
    }
</div>