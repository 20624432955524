import { Component } from "@angular/core";
import { StorageService } from "../../services/storage/storage.service";
import { CartService } from "../../services/cart/cart.service";
import { CommonModule } from "@angular/common";
import { QRCodeModule } from "angularx-qrcode";
import { QrViewerComponent } from "./qr-viewer/qr-viewer.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { Location } from '@angular/common'
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { CustomerService } from "../../services/customer/customer.service";
import { response } from "express";
import { PasswordDialogComponent } from "./password-dialog/password-dialog.component";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { GtmService } from "../../services/shared/gtm.service";

@Component({
  selector: "app-my-account",
  standalone: true,
  imports: [
    CommonModule,
    QRCodeModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: "./my-account.component.html",
  styleUrl: "./my-account.component.scss",
})
export class MyAccountComponent {
  UpdateStatus:number=0;
  days: any = [];
  months: any = [];
  pwHide: boolean = true;
  customer: any = {};
  custQrInfo: any;
  customerForm: FormGroup<any>;
  pastYears: any = [];
  isMailEditMode: boolean = false;
  emailVerifyMsg: string = "";
  emailVerified: boolean = true;
  subscription: number;
  

  constructor(
    private location: Location,
    private cartService: CartService,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private gtmService:GtmService
  ) {
    const currentYear = new Date().getFullYear();

this.pastYears.push({ value: "0004", label: "" });
for (let i = 1; i <= 100; i++) { // Start from 1 to exclude current year
  const year = currentYear - i;
  this.pastYears.push({ value: year, label: year.toString() });
}

    const currentDate = new Date();
    this.months = [
      { value: 0, label: "" },
      ...Array.from({ length: 12 }, (_, i) => ({
        value:  i + 1,
        label: new Date(currentYear, i).toLocaleDateString("en-US", {
          month: "long",
        }),
      })),
    ];

    this.days = [
      { value: 0, label: "" },
      ...Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
      })),
    ];
   // Split the dob value (if necessary)
   this.subscription = (this.customer.subscription)? 1:0;
   const dobParts = this.customer.dob ? this.customer.dob.split('-') : [];
   const dobDay = dobParts[0] || '';
   const dobMonth = dobParts[1] || '';
   const dobYear = dobParts[2] || '';
    this.customerForm = new FormGroup({
      
      firstName: new FormControl(this.customer.firstName, Validators.required),
      lastName: new FormControl(this.customer.lastName),
      email: new FormControl(this.customer.email, [
        Validators.required,
        Validators.email,
      ]),
      //  dob: new FormControl(this.customer.dob),
      dobDay:new FormControl(dobDay),
      dobMonth:new FormControl(dobMonth),
      dobYear:new FormControl(dobYear),
      phoneNumber: new FormControl(this.customer.phoneNumber, [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(9),
      ]),
      address: new FormControl(this.customer.address),
      city: new FormControl(this.customer.city),
      state: new FormControl(this.customer.state),
      country: new FormControl(this.customer.country),
      postCode: new FormControl(this.customer.postCode),
      subscription: new FormControl(this.customer.subscription),
    });
  }
  // showDOBErrorMessage(): boolean {
  //   return !((this.customerForm.get('dobDay')?.value && this.customerForm.get('dobMonth')?.value && this.customerForm.get('dobYear')?.value) ||
  //   (!this.customerForm.get('dobDay')?.value && !this.customerForm.get('dobMonth')?.value && !this.customerForm.get('dobYear')?.value && 
  //   (this.customerForm.get('dobYear')?.value !== '')));
  // }
  ngOnInit() {
    this.customer = this.cartService.getCustomer();
    // this.custQrInfo = JSON.stringify({
    //   customer_id: this.customer.id,
    //   customer_name: this.customer.firstName + " " + this.customer.lastName,
    // });
    // this.custQrInfo = "Z"+ this.customer.id+"Z";
    this.customerForm.patchValue(this.customer);
    /**
     * GTM Code
     */
    this.gtmService.pushEvent(
      'my_profile',
      {
        newsletterOn: (this.subscription==1),
        profileUpdated: false,
        userId: this.gtmService.getCustomer(),
      });
  }
  /**
   * display the QR dialog container
   */
  onShowQR() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.custQrInfo;
    dialogConfig.panelClass = "qr-code-viewer";
    const dialogRef = this.dialog.open(QrViewerComponent, dialogConfig);
  }

  /**
   *
   * update customer information
   */
  onUpdate(): void {
    const customerDetails = {
      id:this.customer.id,
      origin:"oo",
      title:"",
      first_name:this.customerForm .controls["firstName"].value,
      last_name:this.customerForm .controls["lastName"].value,
      address:this.customerForm .controls["address"].value,
      city:this.customerForm .controls["city"].value,
      state:this.customerForm .controls["state"].value,
      country:this.customerForm .controls["country"].value,
      post_code:this.customerForm .controls["postCode"].value,
      gender:"",
      dob:null,
      dob_day:this.customerForm .controls["dobDay"].value,
      dob_month:this.customerForm .controls["dobMonth"].value,
      dob_year:this.customerForm .controls["dobYear"].value,
      phone_number:this.customerForm .controls["phoneNumber"].value,
      email:this.customerForm .controls["email"].value,
      is_valid:1,
      is_subscribed:1
    };
    Object.assign(this.customer, this.customerForm.value);
    this.customer.subscription = this.customer.subscription ? 1 : 0;
    if (this.emailVerified !== true) {
      this.emailVerifyMsg =
        "Please verify your email address or click on cancel.";
      return;
    }
    if (this.customerForm.valid) {
      this.UpdateStatus=1;
      this.customerService.updateCustomer({...customerDetails}).subscribe({
        next: (response) => {
          const message = response.message;
          this.showDialog(message);
          this.UpdateStatus=0;
          if (response.success) {
          
            this.customer = {
              first_name:this.customerForm .controls["firstName"].value,
              last_name:this.customerForm .controls["lastName"].value,
              address:this.customerForm .controls["address"].value,
              city:this.customerForm .controls["city"].value,
              state:this.customerForm .controls["state"].value,
              country:this.customerForm .controls["country"].value,
              post_code:this.customerForm .controls["postCode"].value,
              phone_number:this.customerForm .controls["phoneNumber"].value,
              email:this.customerForm .controls["email"].value,
              dob_day:this.customerForm .controls["dobDay"].value,
              dob_month:this.customerForm .controls["dobMonth"].value,
              dob_year:this.customerForm .controls["dobYear"].value,

            };
            this.cartService.setCustomer(this.customer);
          }
        },
      });

    }
  }

  /**
   *display dialog container to change customer password
   */
  passwordDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "password-change-container";
    const dialogRef = this.dialog.open(PasswordDialogComponent, dialogConfig);
  }

  /**
   * to cancel the email change
   */
  onCancelEmailChange() {
    this.emailVerified = true;
    this.customerForm.controls["email"].setValue(this.customer.email);
    this.isMailEditMode = false;
    this.emailVerifyMsg = "";
  }

  /**
   * to verify customer emil
   */
  onVerifyEmail(): void {
    const verifyInfo = {
      id: this.customer.id,
      email: this.customerForm.controls["email"].value,
      loginId: this.customer.loginId,
    };
    this.customerService.verifyCustomerMail(verifyInfo).subscribe({
      next: (response) => {
        if (response.success == true) {
          this.isMailEditMode = false;
        }
        this.emailVerifyMsg = response.message;
        this.emailVerified = response.success;
      },
    });
  }

  /**
   * to change the email
   */
  onEmailChange(): void {
    this.isMailEditMode = true;
    this.emailVerifyMsg = "";
    this.emailVerified = false;
  }

  /**
   *
   * to show update profile message
   */
  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { title: "MY ACCOUNT", content: message };
    dialogConfig.panelClass = "app-message-dialog";
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }
  back() {
    this.location.back()
   
  }
   /** to prevent typing charaters other than numbers  */
  preventNonNumeric(event: KeyboardEvent) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }
}
