<!-- <div class="position-sticky back-strip bg-white ">
    <div class="container py-3 font-secondary">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
        <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle">Item Details</p>
    </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
        <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">Combo Details</p>
    </div>
</div>

<div class="container py-2 py-lg-4 item-detail-root-container">
    <div class="row pt-lg-1">
        <div class="col-lg-5 text-center text-lg-start item-quantity">
            <div class="mb-4 pb-2 mb-lg-0 pb-lg-0">
                <img src="{{ assetsUrl }}/images/product/{{this.saleItemInfo.images[0]}}" width="390"
                    class="img-fluid mb-3 mb-lg-5 pb-lg-3 item-img" alt="">
                <!-- @if (!isItemAvailable()) {
                        <span class="na-button">{{message}}</span>
                    } -->
                <h3 class="pb-1 pb-lg-0 mb-lg-4">{{this.saleItemInfo.name}}</h3>
                @if (saleItemInfo.description!==undefined && saleItemInfo.description.length>0) {
                <p class="mb-4 pb-2 dish-description">{{this.saleItemInfo.description}}</p>
                }
                <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                    <!-- @if (sourceParam=="combo") {
                        <h3 class="mb-0 me-3 pe-1 font-secondary d-inline-block align-middle amount">
                            {{currencySymbol}}
                            {{(this.saleItemInfo.fixedPrice)*this.selectedQuantity
                            | number:'1.2-2'}}</h3>
                    }
                    @else { -->
                        <h3 class="mb-0 me-3 pe-1 font-secondary d-inline-block align-middle amount">
                            {{currencySymbol}}
                            {{(this.saleItemInfo.unitPriceWithTax+this.customizationUnitPriceWithTax)*this.selectedQuantity
                            | number:'1.2-2'}}</h3>
                    <!-- } -->
                    <mat-select [(ngModel)]="selectedQuantity" class="btn btn-outline-dark rounded-pill"
                        style="width: 20%;" panelClass="rounded">
                        @for (qty of quantity; track $index) {
                        <mat-option [value]="qty.value">{{qty.label}}</mat-option>
                        }
                    </mat-select>&nbsp;&nbsp;
                    <a href="https://stpierres.co.nz/nutrition" target="_blank"
                        class="btn btn-outline-dark rounded-pill font-secondary py-2 px-4 text-truncate text-decoration-underline">
                        Nutrition Info</a>
                </div>
            </div>
            <div>
                @if(this.promotions==true){
                @for (offer of offerList; track $index) {
                @if (offer.show_in_offers==1) {
                <div class="mt-3 mb-2 offer-description-desk" style="text-align: left;
                            color: red;
                        ">
                    <!-- <img class="offer-icon" src="assets/icons/reward-notification.svg"> -->
                    <!-- <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 13C18.7167 13 18.4792 12.9042 18.2875 12.7125C18.0958 12.5208 18 12.2833 18 12C18 11.7167 18.0958 11.4792 18.2875 11.2875C18.4792 11.0958 18.7167 11 19 11H21C21.2833 11 21.5208 11.0958 21.7125 11.2875C21.9042 11.4792 22 11.7167 22 12C22 12.2833 21.9042 12.5208 21.7125 12.7125C21.5208 12.9042 21.2833 13 21 13H19ZM18.4 19.4L16.8 18.2C16.5667 18.0333 16.4333 17.8167 16.4 17.55C16.3667 17.2833 16.4333 17.0333 16.6 16.8C16.7667 16.5667 16.9833 16.4333 17.25 16.4C17.5167 16.3667 17.7667 16.4333 18 16.6L19.6 17.8C19.8333 17.9667 19.9667 18.1833 20 18.45C20.0333 18.7167 19.9667 18.9667 19.8 19.2C19.6333 19.4333 19.4167 19.5667 19.15 19.6C18.8833 19.6333 18.6333 19.5667 18.4 19.4ZM18 7.4C17.7667 7.56666 17.5167 7.63333 17.25 7.6C16.9833 7.56666 16.7667 7.43333 16.6 7.2C16.4333 6.96666 16.3667 6.71666 16.4 6.45C16.4333 6.18333 16.5667 5.96666 16.8 5.8L18.4 4.6C18.6333 4.43333 18.8833 4.36666 19.15 4.4C19.4167 4.43333 19.6333 4.56666 19.8 4.8C19.9667 5.03333 20.0333 5.28333 20 5.55C19.9667 5.81666 19.8333 6.03333 19.6 6.2L18 7.4ZM6 19C5.44772 19 5 18.5523 5 18V15H4C3.45 15 2.97917 14.8042 2.5875 14.4125C2.19583 14.0208 2 13.55 2 13V11C2 10.45 2.19583 9.97916 2.5875 9.5875C2.97917 9.19583 3.45 9 4 9H8L11.475 6.9C11.8083 6.7 12.1458 6.7 12.4875 6.9C12.8292 7.1 13 7.39166 13 7.775V16.225C13 16.6083 12.8292 16.9 12.4875 17.1C12.1458 17.3 11.8083 17.3 11.475 17.1L8 15H7V18C7 18.5523 6.55228 19 6 19V19ZM14 15.35V8.65C14.45 9.05 14.8125 9.5375 15.0875 10.1125C15.3625 10.6875 15.5 11.3167 15.5 12C15.5 12.6833 15.3625 13.3125 15.0875 13.8875C14.8125 14.4625 14.45 14.95 14 15.35ZM11 14.45V9.55L8.55 11H4V13H8.55L11 14.45Z"
                                        fill="red"></path>
                                </svg> -->

                    {{offer.short_description}}
                </div>
                <!-- <app-promotion-card [promotion]="offer"></app-promotion-card> -->
                }
                }
                }
            </div>
        </div>
        <div class="col-lg-7">
            <div class="mt-4 pt-2 mt-lg-0 pt-lg-0">
                <h3 class="font-secondary mb-2 pb-lg-1 mb-lg-3 text-center text-lg-start">Customise Your Combo</h3>
                @if (saleItemInfo.itemComboOptionIds!==undefined && saleItemInfo.itemComboOptionIds!==null &&
                saleItemInfo.itemComboOptionIds.length>0 ) {
                @for (comboOptionData of comboOptionDetails;track $index) {
                <mat-expansion-panel class="mat-elevation-z border mb-2 faq-indicator combo-panel" #customPanel
                    [expanded]="step === $index" (onOpen)="customizationAccordionOpen($index)"
                    (opened)="setStep($index)" [class]="customPanel.expanded?'pb-0':''">
                    <mat-expansion-panel-header class="my-1 bg-white px-3" [class]="!customPanel.expanded?'my-1':''">

                        <div class="title cust-option-title">

                            <span class="mb-0 fs-5 name">{{comboOptionData.displayName}}</span>

                            @if (getCustomizationDisplayName(comboOptionData)!="") {

                            <span class="selected-cust-value">
                                ({{getCustomizationDisplayName(comboOptionData)}})
                            </span>
                            }
                        </div>

                    </mat-expansion-panel-header>
                    <!-- <div style="display: flex;padding: 10px;"> -->
                    @for (custOptVal of comboOptionData.optionValue; track $index) {
                    @if (isValidCustOptVal(custOptVal)) {
                    @if (isOutOfStockCustOptVal(custOptVal)) {
                    <div class="item-container border px-2 py-2 d-inline-flex m-2" (click)="selectCustomization(custOptVal,comboOptionData)">
                        <div class="radio">
                            <div class="input-and-image-container">
                            <input class="form-check-input shadow-none" type="radio" name="{{comboOptionData.code}}"
                                id="no"
                                [checked]="custOptVal.isSelected===1">
                            <div class="justify-content-center" style="align-items: center;">
                                <img [src]="custOptVal.saleItem.images !== null ? imageUrl+((custOptVal.saleItem.defaultImage !== null)? custOptVal.saleItem.defaultImage:custOptVal.saleItem.images[0]): 'assets/images/no-image-available.png'"
                                 class="m-md-auto mb-md-4 item-image">
                            </div>
                        </div>
                            <div class="name-and-button-container">
                            <div class="form-check-label align-middle item-name-container" for="no">
                              <h5 class="item-name">{{custOptVal.name}}</h5> 
                            </div>
                            <div class="d-flex justify-content-between" style="width:100%;">
                            <div class="item-amount amount">
                                <!-- @if (custOptVal.isDefault==1) { -->
                                {{currencySymbol}}{{getOptionValueUnitPriceWithTax(custOptVal)|number:'1.2-2'}}
                                <!-- }
                                @else {
                                {{currencySymbol}}{{custOptVal.price|number:'1.2-2'}}
                                } -->
                            </div>
                            @if (custOptVal.saleItem.itemType==2) {
                            <div>
                                <button [disabled]="custOptVal.isSelected!==1"
                                [class.selected-button]="custOptVal.isSelected === 1"
                                    class="btn  rounded-pill py-0 px-1 py-sm-1 px-sm-2 font-secondary text-truncate  Customise-button " style="font-size:12px;" (click)="onCustomize(custOptVal)"><img
                                    [src]="custOptVal.isSelected === 1 ? 'assets/icons/chef_icon_white.svg' : 'assets/icons/chef_icon.svg'" width="14"
                                        class="me-sm-2 custom-icon white-icon" alt="">
                                    Customise
                                </button>
                                
                                
                            </div>
                            }
                        </div>
                    </div>
                        </div>
                    </div>
                    }
                    @else {
                    <div class="item-container border px-2 py-2 d-inline-flex m-2">
                        <div class="radio">
                            <input class="form-check-input shadow-none" type="radio" name="flexRadioDefault" id="no"
                                (click)="selectCustomization(custOptVal,comboOptionData)">
                            <div class="justify-content-center" style="align-items: center;">
                                <img [src]="custOptVal.saleItem.images !== null ? imageUrl+((custOptVal.saleItem.defaultImage !== null)? custOptVal.defaultImage:custOptVal.saleItem.images[0]): 'assets/images/no-image-available.png'"
                                    style="object-fit: contain;height: 80px; display: block;
                                    margin-left: auto;
                                    margin-right: auto;" class="m-md-auto mb-md-4">
                            </div>
                            <div class="form-check-label align-middle" for="no" style="overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;width: 100px;">
                                {{custOptVal.name}}
                            </div>
                            
                            <div>
                                @if (custOptVal.isDefault==1) {
                                ({{currencySymbol}}{{custOptVal.price|number:'1.2-2'}})
                                }
                                @else {
                                ({{currencySymbol}}{{custOptVal.price|number:'1.2-2'}})
                                }
                            </div>
                            @if (custOptVal.saleItem.itemType==2) {
                            <div>
                                <button
                                    class="btn btn-outline-dark rounded-pill py-1 px-2 py-sm-2 px-sm-3 font-secondary text-truncate" (click)="onCustomize(custOptVal)"><img
                                        src="assets/icons/chef_icon.svg" width="24"
                                        class="me-sm-2 custom-icon white-icon" alt="">
                                    Customise
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                    }

                    }
                    }
                    <!-- </div> -->
                </mat-expansion-panel>
                }
                }
                <div class="mb-4">
                    <label for="message" class="form-label mb-3 fs-5 mt-3 pt-1">Special Instructions</label>
                    <textarea class="form-control shadow-none rounded-1 p-3" style="resize: none;" id="message" rows="5"
                        (keydown.enter)="onKeydown($event)"
                        placeholder="Add notes on your allergies. Requests that cost extra will not be fulfilled."
                        [(ngModel)]="this.remark"></textarea>
                </div>
                <div class="cart-update-anim">
                    <div class="cart-update-msg">
                      CART UPDATED
                    </div>
                  </div>
                @if (!this.outOfStockItemIds.includes(saleItemInfo.id)) {
                @if (this.isUpdating ==true) {
                <button class="btn btn-primary rounded-pill font-secondary py-2 px-4 mb-5" (click)="updateCart()" style="font-size: 18px;">
                    Update Order
                </button>
                }
                @else {
                <button class="btn btn-primary rounded-pill font-secondary py-2 px-4 mb-5" (click)="addToCart()" style="font-size: 18px;">
                    Add to Order
                </button>
                }
                }
                @else {
                <div class="bg-primary rounded-2 py-1 px-2 py-sm-1 px-sm-3 font-secondary text-danger text-center fs-4">
                    OUT OF STOCK
                </div>
                }
            </div>
        </div>
    </div>
</div>