import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { CartService } from '../../services/cart/cart.service';
import { OrderConfirmationService } from '../confirm-orders/order-confirmation.service';
import { SESSION_APPLIED_REWARDS, SESSION_LAST_SUCCESS_TAX_INVOICE_NO, SESSION_LAST_SUCCESS_TXN_RECEIPT, SESSION_LAST_TXN_RECEIPT_DOWNLOADED } from '../../app.constants';
import { OrderHistoryService } from '../../services/shared/order-history.service';
import { fromEvent, map } from 'rxjs';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { UtilService } from '../../services/shared/util.service';
import { CommonModule } from '@angular/common';
import { RewardService } from '../../services/shared/reward.service';

@Component({
  selector: 'app-view-receipt',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './view-receipt.component.html',
  styleUrl: './view-receipt.component.scss'
})
export class ViewReceiptComponent {
  result: any;
  lastTaxInvNo: any;
  htmlData: SafeHtml | undefined;
  lastTxnMail: any;
  queryTaxInvNumber: any;
  scrollToBottmVisible = true;
  receiptDownloaded = false;
  showScrollButton = true;
  container: any;
  loading: boolean = true;
  rewardsData: any;
  constructor(private route: ActivatedRoute, private router: Router,
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
    public cartService: CartService, private dataService: OrderHistoryService,
    private rewardService: RewardService,
    private utilService: UtilService) {

    this.lastTaxInvNo = this.storageService.loadFromSession(SESSION_LAST_SUCCESS_TAX_INVOICE_NO);
    this.receiptDownloaded = this.storageService.loadFromSession(SESSION_LAST_TXN_RECEIPT_DOWNLOADED);
    this.queryTaxInvNumber = this.route.snapshot.queryParams['tn'];
    this.htmlData = undefined;
    if (this.queryTaxInvNumber !== this.lastTaxInvNo) {
      this.lastTaxInvNo = undefined;
      this.loading = true;
      this.dataService.getPaymentReceiptInfo(this.queryTaxInvNumber).subscribe(
        res => {
          this.result = res.success;
          var paymentDetails = res.object;
          if (this.result) {
            this.loading = false;
            this.storageService.saveToSession(SESSION_LAST_SUCCESS_TAX_INVOICE_NO, JSON.stringify(paymentDetails.taxInvoiceNumber));
            this.storageService.saveToSession(SESSION_LAST_SUCCESS_TXN_RECEIPT, JSON.stringify(paymentDetails.mailContent));
            this.htmlData = this.sanitizer.bypassSecurityTrustHtml(paymentDetails.mailContent);
            this.lastTaxInvNo = paymentDetails.taxInvoiceNumber;
            setTimeout(() => {
              this.downloadReceipt();
            }, 3);
            this.setScrollButtons();
          } else {
          }
        }
      )
    } else {
      this.lastTxnMail = this.storageService.loadFromSession(SESSION_LAST_SUCCESS_TXN_RECEIPT);
      this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.lastTxnMail);
      this.loading = false;
    }
  }

  /**
   * 
   */
  ngAfterViewInit(): void {

    this.setScrollButtons();

  }

  /**
   * 
   */
  setScrollButtons(): void {
    setTimeout(() => {
      var element = document.getElementsByClassName("main-div-wrap")[0];
      this.showScrollButton = (element.scrollHeight > element.clientHeight);
    }, 3);
  }

  /**
   * 
   */
  ngAfterContentInit(): void {

    if (!this.receiptDownloaded) {
      setTimeout(() => {
        this.downloadReceipt();
      }, 3);
    }
  }

  ngOnInit() {
    this.utilService.scrollToTop();
    let tracker = document.getElementsByClassName("main-div-wrap")[0];

    let windowYOffsetObservable = fromEvent(tracker, 'scroll').pipe(map(() => {
      return tracker.scrollTop;
    }));

    // subscribe to our Observable so that for each new item, our callback runs
    // this is our event handler
    let scrollSubscription = windowYOffsetObservable.subscribe((scrollPos) => {
      let botomLimit = tracker.scrollHeight - tracker.clientHeight;

      if (scrollPos === botomLimit) {
        this.scrollToBottmVisible = false;
      } else if (scrollPos === 0) {
        this.scrollToBottmVisible = true;
      }

    });

  }


  clickHereToDownload(){
    this.dataService.getPaymentReceiptInfo(this.queryTaxInvNumber).subscribe(
      res => {
        console.log(res);
        this.result = res.success;
        var paymentDetails = res.object;
        if (this.result) {
          this.loading = false;
          this.storageService.saveToSession(SESSION_LAST_SUCCESS_TAX_INVOICE_NO, JSON.stringify(paymentDetails.taxInvoiceNumber));
          this.storageService.saveToSession(SESSION_LAST_SUCCESS_TXN_RECEIPT, JSON.stringify(paymentDetails.mailContent));
          this.htmlData = this.sanitizer.bypassSecurityTrustHtml(paymentDetails.mailContent);
          this.lastTaxInvNo = paymentDetails.taxInvoiceNumber;
          setTimeout(() => {
            this.downloadReceipt();
          }, 3);
          this.setScrollButtons();
        } else {
        }
      }
    );
  }

  downloadReceipt() {

    if (this.htmlData !== undefined) {
      this.container = document.getElementById('receiptContentDiv');
      html2canvas(this.container).then((canvas) => {
        // var fileName= "oo_recipt-"+ this.lastTaxInvNo.split("-")[2]+"-.png"

        var fileName = "oo_receipt-(" + moment().format("DD-MM-YYYY") + ")-(" + this.lastTaxInvNo.split("-")[2] + ").png"
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = fileName;
        link.href = canvas.toDataURL("image/png");
        link.target = '_blank';
        link.click();
      });
      this.storageService.saveToSession(SESSION_LAST_TXN_RECEIPT_DOWNLOADED, true);
      if (this.cartService.cart.customer !== undefined && this.cartService.cart.customer.customerType !== 2) {
        this.cartService.getRecentOrder(this.cartService.cart.customer?.id).subscribe((recentOrderData: any) => {
          this.cartService.setRecentOrderCustomerToCart(recentOrderData.object.recentOrder);
        });
      }
      this.rewardService.loadCustomerRewards(this.cartService.cart.customer?.id).subscribe(reward => {
        if (reward.data.customer_reward !== undefined && reward.data.customer_reward !== null) {
          const activeRewards = reward.data.customer_reward.filter((cr: any) => cr.customer_status == 1);
          this.rewardsData = activeRewards;
        } else {
          this.rewardsData = undefined;
        }
        var appliedRewards:any =[]; // this.rewardService.getAppliedRewards();
        // this.rewardsData.forEach((rd: any) => {
        //   appliedRewards=appliedRewards.filter((pr: any) => pr.id == rd.id)
        // });
        this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(appliedRewards));
      });
    }
  }

  scrolToBottom() {
    var element = document.getElementsByClassName("main-div-wrap")[0];
    var bottom = element.scrollHeight - element.clientHeight;
    this.showScrollButton = (element.scrollHeight > element.clientHeight);
    element.scrollTo({ top: bottom, left: 0, behavior: "smooth" });
    this.scrollToBottmVisible = false;
  }

  scrolToTop() {

    var element = document.getElementsByClassName("main-div-wrap")[0];
    element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.scrollToBottmVisible = true;
  }


}

