import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StorageService } from '../../../../services/storage/storage.service';
import { SESSION_APPLIED_REWARDS, SESSION_CUSTOMER_ACTIVE_REWARDS } from '../../../../app.constants';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../../services/shared/notifications.service';
import { RewardService } from '../../../../services/shared/reward.service';
import { CartService } from '../../../../services/cart/cart.service';

@Component({
  selector: 'app-reward-card',
  standalone: true,
  imports: [],
  templateUrl: './reward-card.component.html',
  styleUrl: './reward-card.component.scss'
})
export class RewardCardComponent {
  @Input() reward!: any;

  rewardsData: any;
  appliedRewards: any = [];
  crmAssetsUrl = environment.crm.assetsUrl;
  hasImageError: boolean = false;
  isAppliedReward: boolean = false;

  @ViewChild('rewardImg')
  rewardImgRef!: ElementRef<HTMLImageElement>; // Reference to the image
  constructor(private storageService: StorageService,
    private notificationService: NotificationService,
    private rewardService: RewardService,
    private cartService: CartService) { 
      this.notificationService.rewardApplied.subscribe(data=>{
        
      })
    }

  ngOnInit() {
    this.rewardsData = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    this.rewardsData = (this.rewardsData !== null && this.rewardsData != undefined) ? JSON.parse(this.rewardsData) : [];
    this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));
    if (this.appliedRewards == undefined) {
      this.appliedRewards = [];
    }
  }

  ngAfterViewInit() {
    const image = this.rewardImgRef.nativeElement;
    image.onerror = () => {
      this.hasImageError = true;
    };
  }

  /**
     * Add the reward to the applied rewards and save to session
     * @param reward 
     */
  applyReward(reward: any): void {
    // this.appliedRewards.push(reward)
    // this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
    // this.notificationService.notifyRewardApplied(this.appliedRewards);
    this.rewardService.showPromotionSelection(reward);
  }

  /**
   * Remove the given reward from the array and save to session
   * @param reward 
   */
  removeReward(reward: any) {
    this.appliedRewards = this.appliedRewards.filter((rw: any) => rw.id !== reward.id);
    this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
    const items = this.cartService.getCustomerCart();
    const itemsWithOfferId=items.items.filter((ci:any) => ci.offerId == reward.id);
    itemsWithOfferId.forEach((item:any) =>{
      this.cartService.deleteItem(item);
    });
    this.notificationService.notifyRewardRemoved(this.appliedRewards);
  }

  isRewardApplied(reward: any): boolean {
    var isRewardApplied = this.appliedRewards.some((rw: any) => rw.id === reward.id);
    return isRewardApplied;
  }
  
}
