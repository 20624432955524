import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {  Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { UtilService } from '../../services/shared/util.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageService } from '../../services/storage/storage.service';

import { SESSION_COMPANY_INFO, SESSION_DEPARTMENT_LIST } from '../../app.constants';
import { SESSION_STORAGE } from 'ngx-webstorage-service/src/storage-providers';
import { HomeService } from '../home/home.service';



@Component({
  selector: 'app-terms-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.scss'
})
export class TermsConditionsComponent {
  showHeader:boolean=true;
  companyInfo: any;
  tcContent: any;

  constructor(private location: Location,
    private utilService: UtilService,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private homeService: HomeService,
  ) { 

  }
  ngOnInit(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setCompanyInfo();
  }
  back() {
    this.location.back()
  }
   

  // initTitleBarParams() {
  //   this.utilService.fwConfigService.showFooterBar = false;
  //   this.utilService.resetFooterConfigData(this);
  //   const titleConfig: TitleConfig = {
  //     title: 'TERMS AND CONDITIONS',
  //     showLogo: false,
  //     showShoppingCart: false,
  //     hideHamBurgerMenu: false,
  //     hideBackButton: false
  //   };
  //   this.utilService.setTitleBarParams(this, titleConfig);
  //   const footerConfig: FooterConfig = {
  //   };
  //   this.utilService.setFooterBarParams(this, footerConfig);
  // }
  setCompanyInfo(){
    this.homeService.getCompanyInfo().subscribe((companydata: any) => {
      this.companyInfo = companydata.object[0];
      this.storageService.saveToSession(SESSION_COMPANY_INFO,JSON.stringify(this.companyInfo));
    this.tcContent = this.sanitizer.bypassSecurityTrustHtml(this.companyInfo.salesPolicy);

    });
  }
}
