import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OOModule } from '../../modules/oo.module';
import { TokenService } from '../../services/shared/token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  token: any;
  nearestShopUrl = environment.oos.url + '/shop/get-nearest';
  getAllShopsUrl= environment.oos.url+'/shop/get-all';
  getAllDepartmentUrl= environment.oos.url+'/department/get-all';
  pickUpLocationUrl=environment.oos.url+'/pickup-location-info/get-pickup-location';
  companyInfoUrl=environment.oos.url+'/company/get-info';
  
  constructor(private http: HttpClient, private tokenService: TokenService) { }
  getCompanyInfo(): Observable<any>{
    return this.http.get(this.companyInfoUrl)
  } 

  getShops(): Observable<any>{
    return this.http.get(this.getAllShopsUrl)
  }

  getDepartments(): Observable<any>{
    return this.http.get(this.getAllDepartmentUrl)
  }
  offers(offerData: any) {
    return this.tokenService.getToken().pipe(
      map((response) => response),
      catchError((error) => {
        throw error;
      }),
      switchMap((response: any) => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: response,
          }),
        };
        return this.http.post(environment.crm.url + '/reward/getOffers', offerData, httpOptions);
      })
    )
  }
  
  getNearestShops(locationCoordinateData:any): Observable<any> {
    return this.http.post<any>(this.nearestShopUrl, locationCoordinateData)
      .pipe(

      );
  }
  getPickUpLocations():Observable<any>{
    return this.http.get(this.pickUpLocationUrl)
  }
}
