<header class="position-sticky top-0" style="z-index: 9999;">
  <nav class="navbar navbar-expand-lg bg-secondary">
    <div class="container container-2">
      <div class="navbar-brand"><img src="assets/images/logo.svg" class="img-fluid" alt="Brand logo"
          (click)="HomePage()"></div>
      <button #closeNav class="navbar-toggler shadow-none border-0" data-bs-theme="dark" type="button"
        data-bs-toggle="collapse" data-bs-target="#sushiNav" aria-controls="sushiNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="sushiNav">
        <div class="outside-click position-absolute end-0 top-0 bottom-0 start-0 d-lg-none"
          (click)="this.closeNav.click()"></div>
        <ul class="navbar-nav ms-lg-auto mb-2 mb-lg-0 font-secondary align-items-center ps-lg-2 text-nowrap">
          <button class="btn-close btn-close-white position-fixed shadow-none d-lg-none"
            (click)="this.closeNav.click()">
          </button>
          <li class="nav-item" style="--nav-icon-phone: url('assets/icons/home-2.svg');">
            <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" href="#"
              routerLink="" (click)="responsive? this.closeNav.click():''">HOME</a>

          </li>
          <li class="nav-item" style="--nav-icon-phone: url('assets/icons/info_icon.svg');">
            <a class="nav-link" href="/about" routerLink="about" routerLinkActive="active"
              (click)="responsive? this.closeNav.click():''">ABOUT US</a>
          </li>
          <li class="nav-item" style="--nav-icon-phone: url('assets/icons/support_icon.svg');">
            <a class="nav-link" href="/contact" routerLink="contact" routerLinkActive="active"
              (click)="responsive? this.closeNav.click():''">CONTACT</a>
          </li>
          <li class="nav-item" style="--nav-icon-phone: url('assets/icons/que_icon.svg');">
            <a class="nav-link" href="/faq" routerLink="faq" routerLinkActive="active"
              (click)="responsive? this.closeNav.click():''">FAQ</a>
          </li>

          <li class="nav-item d-lg-none" style="--nav-icon-phone: url('assets/icons/food-menu.svg');">
            <a class="nav-link" href="javascript:void(0)" (click)="this.closeNav.click();navigateToItemList()"
              >Menu</a>
          </li>
          <!-- <li class="nav-item d-lg-none" style="--nav-icon-phone: url('assets/icons/food-menu.svg');filter: invert(1);">
            <a class="nav-link" href="javascript:void(0)" (click)="this.closeNav.click();itemListback()" style="color: white;">Menu</a>
          </li> -->
          <li class="nav-item d-lg-none" style="--nav-icon-phone: url('assets/icons/gift_icon2.svg');"
            [style.display]="(loginStatus && rewardIconVisible) ? 'block' :'none'">
            <a class="nav-link" href="javascript:void(0)" (click)="this.closeNav.click();rewardDialog()">Rewards</a>
          </li>
          <li class="nav-item d-lg-none position-relative" style="--nav-icon-phone: url('assets/icons/bag_2.svg');">
            <a class="nav-link" routerLink="" routerLinkActive=""
              (click)="this.closeNav.click();cartNavigate()">Cart</a>
            <div class="cart-count position-absolute rounded-circle text-center fs-6 top-0 start-100">
              {{cartService.getTotalQuantity()}}</div>
          </li>
          <li class="nav-item d-lg-none" style="--nav-icon-phone: url('assets/icons/user2_icon.svg');"
          [style.display]="loginStatus ? 'block' :'none'">
          <a class="nav-link" href="/my-account" routerLink="my-account" routerLinkActive="active"
            (click)="responsive? this.closeNav.click():''">My Account</a>
        </li>
          @if (loginStatus==false) {
          <li class="nav-item nav-logout d-lg-none position-fixed bottom-0 bg-dark pe-4 mb-0"
            style="--nav-icon-phone: url('assets/icons/login_icon.svg');">
            <a class="nav-link" href="javascript:void(0)" (click)="this.closeNav.click();loginDialog();">Login</a>
          </li>
          }
          @else {
          <li class="nav-item nav-logout d-lg-none position-fixed bottom-0 bg-dark pe-4 mb-0"
            style="--nav-icon-phone: url('assets/icons/logout_icon.svg');color: aliceblue;">
            <a class="nav-link" href="javascript:void(0)" (click)="this.closeNav.click();logout();">Logout</a>
          </li>
          }
        </ul>
      </div>
      <!-- Desktop header -->
      <ul class="navbar-nav ms-lg-auto mb-lg-0 font-secondary align-items-center nav-2 flex-row" style="
            gap: 10px;
            margin-right: 5px;">
        <li class="nav-item position-relative item-2">
          <button class="nav-link text-center" (click)="navigateToItemList()"><img src="assets/icons/food-menu.svg"
              width="32px" alt="menu" ><span
              class="mt-1 d-block fs-6 d-none d-lg-block text-white">Menu</span></button> 
        </li>
        @if(rewardIconVisible){
          <li class="nav-item position-relative item-2">
            <a class="nav-link rounded-circle text-center" href="javascript:void(0)" (click)="rewardDialog()"><img
                src="assets/icons/gift.gif" style="filter: invert(1);" width="28px" alt="gift box">
              <span class="mt-2 d-block fs-6 d-none d-lg-block">Rewards</span></a>
          </li>
          }
        <li class="nav-item position-relative item-2" [@shake]="animationState" style="margin-right:5px ;">
          <button class="nav-link text-center checkout-btn" (click)="cartNavigate()"><img src="assets/icons/bag_2.svg" width="32px"
              alt="shopping cart" style="transform: scaleX(-1);"><span
              class="mt-1 d-block fs-6 d-none d-lg-block">Cart</span></button>
          <div class="cart-count position-absolute rounded-circle text-center fs-6 top-0 pe-none amount">
            {{cartService.getTotalQuantity()}}</div>
        </li>

        <!-- Show login  only on mobile if logged in -->
        @if (loginStatus==false) {
        <li class="nav-item position-relative item-2 d-lg-none ">
          <button class="nav-link text-center" (click)="loginDialog()">
            <img src="assets/icons/user_logout_icon.svg" width="32px" alt="user icon">
            <!-- <span class="mt-1 d-block fs-6"></span> -->
          </button>
        </li>
        }
        <!-- Show account  only on mobile if logged in -->
        @if (loginStatus==true){
        <li class="nav-item-account position-relative item-2  d-lg-none">
          <a class="dropdown-item" href="my-account" routerLink="my-account">
            <button class="nav-link text-center">
              <img src="assets/icons/user2_icon.svg" width="32px" alt="user icon">
              <!-- <span class="mt-1 d-block fs-6"></span> -->
            </button>
          </a>
        </li>
        }
      
        @if (loginStatus == false) {
        <li class="nav-item item-2">
          <button class="btn btn-primary rounded-pill py-2 px-4 font-secondary" (click)="loginDialog()">Login</button>
        </li>
        }
        @else {
        <li class="nav-item item-2">
          <div class="dropdown" style="font-family: var(--futura);">
            <button class="btn pe-none p-2 border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="assets/icons/user_icon.svg" width="42" height="42" class="pe-auto me-1" alt="">
              <img src="assets/icons/caret_icon.svg" width="24" height="24" class="pe-auto" alt="">
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li><a class="dropdown-item" href="my-account" routerLink="my-account">My Account</a></li>
              <li><a class="dropdown-item" (click)="logout();">Logout</a></li>
            </ul>
          </div>
        </li>
        }
      </ul>
      <!-- Desktop header -->
    </div>
  </nav>
</header>