
export const CUSTOMER_TYPE_GUEST = 2;
export const CUSTOMER_TYPE_REGUSER = 1;
export const CUSTOMER_TYPE_SOCIAL = 0;

export const PROMOTION_ELIGIBILITY_TYPE_REWARD = 2;
export const PROMOTION_ELIGIBILITY_TYPE_OFFER = 1;

export const PRODUCT_IMAGE_FOLDER  = '/images/product/';
export const ERROR_CODE_OUT_OF_STOCK ='102';

export const SESSION_SHOP_LIST = 'shop_list';
export const SESSION_NEAREST_SHOP_LIST = 'nearest_shop_list';
export const SESSION_CUSTOMER_ACTIVE_REWARDS = 'customer_active_rewards';
export const SESSION_CUSTOMER_REWARDS = 'customer_rewards';
export const SESSION_CART = 'cart';
export const SESSION_OFFERS = 'offers';
export const SESSION_FILTERED_OFFERS = 'filtered_offers';
export const SESSION_APPLIED_REWARDS = 'applied_rewards';
export const SESSION_AUTH_TOKEN = 'auth_token';
export const SESSION_AUTHENTICATED_CUSTOMER = 'authenticated_customer';
export const SESSION_DEPARTMENT_LIST = 'department_list';
export const SESSION_PICKUP_LOCATION_LIST='pickup_location_list';
export const SESSION_AMENITY_LIST = 'amenity_list';
export const SESSION_BUSINESS_TYPE_LIST = 'business_type_list';
export const SESSION_ONLINE_SERVICE_LIST = 'online_service_list';
export const SESSION_SELECTED_SHOP_LIST = 'selected_shop';
export const SESSION_ITEM_CATEGORY_LIST = 'item_category_list';
export const SESSION_FILTERED_SALE_ITEM_LIST='filtered_sale_item_list';
export const SESSION_FILTERED_CATEGORY_ITEM_LIST = 'filtered_category_item_list';
export const SESSION_SELECTED_SUBCATEGORY_ID = 'selectedSubCategory';
export const SESSION_SELECTED_SHOP='selected_shop';
export const SESSION_SALE_ITEM_LIST='sale_item_list';
export const SESSION_MASTER_CUSTOMIZATION = 'customization_option_detail';
export const SESSION_MASTER_COMBO_CUSTOMIZATION='customization_combo_option_detail';
export const SESSION_SYSTEM_SETTINGS = 'system_settings';
export const SESSION_OUTOFSTOCK_ITEM_LIST = 'out_of_stock_ooSaleItem_Ids';
export const SESSION_COMPANY_INFO = 'companyInfo';
export const SESSION_PROMOTIONAL_SALEITEM_IDS = 'promotional_item_list';
export const SESSION_TOKEN = 'token';
export const SESSION_CONFIRMATION_MESSAGE = 'confirmation_messge';
export const SESSION_LAST_SUCCESS_TXN_ID = 'last_success_txn_id';
export const SESSION_LAST_SUCCESS_TAX_INVOICE_NO = 'last_success_tax_invoice_no';
export const SESSION_LAST_SUCCESS_TXN_RECEIPT = 'last_success_txn_receipt';
export const SESSION_LAST_TXN_RECEIPT_DOWNLOADED = 'last_success_txn_receipt_downloaded';
export const SESSION_START_TIME = 'session_start_time';

