
@if(showHeader){
    <!-- <div class="back-strip font-secondary bg-white">
        <div class="container py-2 py-lg-3">
           <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                 src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                 class="align-middle d-none d-lg-inline">BACK</span></button>
           <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle">Terms & Conditions of Use</p>
        </div>
     </div> -->
     <div class="back-strip font-secondary bg-white firstDiv">
        <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                    <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                       src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                       class="align-middle d-none d-lg-inline">BACK</span></button>
                    <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">Terms & Conditions of Use</p>
        </div>
     </div>
    
}  @else{
    <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle">Terms & Conditions of Use</p>

}


<div class="container">
    <p class="mt-3 mb-3 pb-1">These Terms & Conditions of Use govern your use of St Pierres International Limited
        (St
        Pierres) Online
        Ordering Platform (St Pierres Online Ordering Platform). Your use of the St Pierres Online Ordering
        Platform
        constitutes your acceptance of these Terms & Conditions of Use.</p>
    <p class="mb-3 pb-1">
        St Pierres may from time to time amend or otherwise modify the Terms & Conditions of Use without notice.
        You
        are advised to carefully read, each time you visit the website, the Terms & Conditions of Use as they may
        affect
        your rights.
    </p>

    <p class="mb-3">
        If you do not agree with the Terms & Conditions of Use, do not use the St Pierres Online Ordering Platform.
        St Pierres is committed to ensuring the privacy of all users' (Users) information.
    </p>

    <h5 class="fw-bold">
        USE OF THE ORDERING PLATFORM
    </h5>

    <p>You agree to accept full responsibility for all your activities on St Pierres Online Ordering Platform. You are
        responsible for maintaining the confidentiality of your personal details, password and payment details. You
        further acknowledge that you are fully responsible for the activities of any individual who accesses St Pierres
        Online Ordering Platform using an electronic device which remains logged in to, or associated with, your
        registered account.</p>

    <p>The St Pierres online ordering system must only be used by persons over the age of eighteen (18) years, or
        otherwise under the supervision of an adult or guardian. St Pierres reserves the right to refuse service,
        terminate your access to the website or online ordering, remove or edit any content or cancel your order/s in
        its sole discretion and without prior notice to you.</p>
    <p>In making the St Pierres Online Ordering Platform available for use, St Pierres merely acts as an agent of
        sub-franchisee stores from which the products or services will be provided (as selected by you) does not provide
        you with any goods or services makes no warranties or representations regarding the goods and services to be
        provided.</p>
    <p>In making the St Pierres Online Ordering Platform available for use, St Pierres merely acts as an agent of
        sub-franchisee stores from which the products or services will be provided (as selected by you or determined);
        in its capacity as agent, collects and processes any online payments and remits this amount to the store from
        which the products or services are provided; makes no warranties or representations regarding the goods and
        services to be provided.</p>
    <p>St Pierres does not guarantee that the St Pierres Online Ordering Platform will be error-free or that any
        errors will be corrected. St Pierres does not guarantee that the St Pierres Online Ordering Platform will be
        free from cyber-attack, viruses or other harmful components and you agree to hold harmless St Pierres from any
        claim, judgement, loss, expense, liability from any viruses or harm suffered by you as a result of using St
        Pierre’s Online Ordering Platform.</p>
    <p>St Pierres reserves the right to alter the agreed price if an incorrect price was caused by a software fault,
        error or malicious attack.
        In using St Pierres Online Ordering Platform (whether via the website or an application) you acknowledge that
        all orders are treated as an express intention to purchase the nominated goods or services for the agreed online
        prices acknowledge that you contract with the individual store from which you order and not St Pierres and
        therefore you have no claim, recourse or action against St Pierres whatsoever and indemnify St Pierres from
        all claims, losses, judgement, liability and damage relating to a failure of the online ordering system.</p>
    <h5 class="fw-bold">INDEMNITY</h5>
    <p>You agree to defend, indemnify and hold harmless St Pierres, related corporations, affiliates, and
        the officers,
        directors, employees and agents of these entities, from and against any and all claims, damages, obligations,
        losses, liabilities, costs or debt, and expenses (including but not limited to legal fees) arising from or in
        connection with (i) your use of and access to the St Pierres Online Ordering Platform (ii) your violation of
        any term of these Terms and Conditions of Use and (iii) your violation of any third party right, including
        without limitation any copyright, property, or privacy right.</p>
    <h5 class="mb-3 fw-bold">ABILITY TO ACCEPT TERMS AND CONDITIONS OF USE</h5>
    <p>You affirm that you are either more than 18 years of age, or possess legal parental or guardian consent, and are
        fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and
        warranties set forth in these Terms and Conditions of Use, and to abide by and comply with these Terms and
        Conditions of Use.</p>
    <h5 class="fw-bold">
        PRIVACY POLICY
    </h5>

    <p>This Privacy Policy applies to all personal information submitted by Users on the St Pierres Online Ordering
        Platform to St Pierres, and any information that may be automatically retrieved through the services that St
        Pierre’s offers on the St Pierres Online Ordering Platform.</p>
    <p>By accessing the the St Pierres Online Ordering Platform and/or using the Services, all Users consent to the
        collection, use, disclosure, storage and processing of their information in accordance with this Privacy Policy.
    </p>
    <p>St Pierres may amend or update this Privacy Policy from time to time, with or without notice to Users. All Users
        agree to be bound by the Privacy Policy that is in effect at the time they access the Website and/or use the
        Services.</p>
    <p>In order to use the Services, all Users will need to submit certain personal information such as their email
        address, name, address, telephone number, gender and date of birth. Users may be asked to submit further
        information from time to time.</p>
    <p>The personal information that Users provide will only be used in connection with the Services offered by St
        Pierres, to communicate with Users in relation to the Services or to co-operate with any government, industry
        or regulatory authorities. St Pierres reserves the right to use data (on an anonymous basis) in relation to
        Users' use of the Services for marketing purposes.</p>
    <p>Unless expressly authorised to do so by the relevant User, St Pierres will not disclose any User's personal
        information to any third party except where the disclosure relates to the purposes for which the information was
        collected.</p>
    <p>Users may request at any time to see the personal information that St Pierres holds on its behalf or to correct
        or update any personal information (to the extent that Users are unable to do so on the St Pierres Online
        Ordering Platform).</p>
    <p class="mb-0">St Pierres will securely store Users' personal information in New Zealand or in any other country,
        which St Pierres may from time to time offer its Services. Users' personal information may therefore be
        transferred outside of New Zealand.</p>
    <p>St Pierres may hold Users personal information both before and after the cancellation of each User's account,
        but only for as long as St Pierres is lawfully entitled to do so.</p>
    <h5 class="fw-bold">
        REFUND POLICY
    </h5>
    <p>Due to your order being freshly made, we are unable to accept returns or cancellations. We can only provide
        refunds where product purchased has been incorrectly supplied.</p>
    <p class="fw-bold">All refunds will be processed by the St Pierres and may take at least 5 working days.</p>
    <h5 class="fw-bold">
        CANCELLATION POLICY
    </h5>
    <p>Due to your order being freshly made, we are unable to accept cancellations of orders after they have been
        produced. If you immediately contact the store you have placed the order from by phone they may be able to
        cancel the order if it has not yet been made.</p>
    <p class="bold">All refunds will be processed by the St Pierres and may take at least 5 working days.</p>

</div>
