<!-- <div class="position-sticky back-strip font-secondary bg-white">
    <div class="container py-2 py-lg-3">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
        <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle">Shop Details</p>
    </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">Shop Details</p>
    </div>
 </div>

<div class="container py-3 overflow-x-hidden">
    <div class="row detail-row">
        <div class="col-lg-6">
            <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d799.4096433589385!2d174.70597220710138!3d-36.73124058682794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d3beb6420cf45%3A0xe16a9888bac5675b!2sAlbany%20Mega%20Centre!5e0!3m2!1sen!2sin!4v1701151292423!5m2!1sen!2sin"
                width="600" height="450" class="w-100 map rounded-3" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                <!-- <google-map [options]="{center: { lat: shopList.location.latitude, lng: shopList.location.longitude},
                    zoom: 12,}" [draggable]="false" >
                </google-map> -->
                <div id="map" style="height: 400px;"></div>
                
        </div>
        <div class="col-lg-6">
            <div class="pt-4 pt-lg-0 pb-4 mb-lg-2">
                <h5 class="mb-3 mb-lg-4 d-inline-block pe-2" style="width: calc(100% - 30px);">{{shop.name}}</h5>
                <div>
                    <p class="mb-2 pb-1 mb-lg-3 pb-lg-0 d-flex d-lg-block"><img src="assets/icons/location_icon.svg"
                            alt="" class="mb-auto mb-lg-0">
                        <span class="ms-2 ps-2 align-middle">{{shop.address}}</span>
                    </p>
                    <p>&nbsp;<i class="fa fa-phone me-3" aria-hidden="true"></i>{{shop.phoneNumber}}</p>
                    <p class="mb-4"><img src="assets/icons/clock_icon.svg" alt="">
                        @for (time of shop.shopWorkingHours; track $index) {
                            @if (time.dayOfWeek==day) {
                                <span class=" ms-2 me-md-2 ps-1 align-middle">Opens at
                                    {{time.hoursShop}} &nbsp;</span>
                                @if(shoptime>=time.openTime && shoptime<=time.closeTime){ <span
                                    class="shop-status open float-end float-md-none">Now Open</span>
                                    }
                                    @else {
                                    <span class="shop-status closed float-end float-md-none">Close</span>
                                    }
                                }
                            }
                    </p>
                </div>
                <div class="row pb-4">
                   @for (amenity of shopAmenityList; track $index) {
                    <div class="col-auto">
                        <img src={{amenity.smallIcon}} width="30" height="30" alt="">
                    </div>
                    }
                </div>
                <div class="d-flex font-secondary mb-lg-2">
                    <button class="btn btn-dark rounded-pill py-2 px-4 me-2 text-truncate btn-shadow"
                        (click)="scheduleDialog('CATERING',shop.id)">
                        Order Catering</button>
                        @if (isBentoShop(shop.id)) {
                            <button class="btn btn-info rounded-pill btn-shadow py-2 px-4 me-md-2 text-truncate" (click)="scheduleDialog('ORDERNOW',shop.id)">
                                Sushi & Bento</button>
                        }
                        @else {
                            <button class="btn btn-warning rounded-pill btn-shadow py-2 px-4 me-md-2 text-truncate" (click)="scheduleDialog('ORDERNOW',shop.id)">
                                Order sushi</button>
                        } 
                </div>
            </div>
            <div>
                <h5 class="font-secondary mb-2 pb-1"><img src="assets/icons/clock_icon.svg"><span
                        class="ms-1 ps-2 align-middle">Opening Hours</span></h5>
                <div class="working-hr-list row">
                    @for (shopDay of shop.shopWorkingHours; track $index) {
                        @if (shopDay.dayOfWeek==day) {
                            <div class="col-sm-6 col-lg-12">
                                <div class="py-1 px-3 card flex-row mb-2"
                                    style="background-color: #fff200; border-color: #fff200;">
                                    <p class="my-auto mx-1">Today</p>
                                    <div class="ms-auto weekly-timing">
                                        @for (department of shopDay.deptHourList; track $index) {
                                            @if (department.departmentId==1) {
                                                <p class="mb-0">Sushi: {{department.displayTime}}</p>
                                            }
                                            @if (department.departmentId==2) {
                                                <p class="mb-0">Bento: {{department.displayTime}}</p>
                                            }
                                            @if (department.departmentId==3) {
                                                <p class="mb-0">Common: {{department.displayTime}}</p>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        @else{
                    <div class="col-sm-6 col-lg-12">
                        <div class="py-1 px-3 card flex-row mb-2">
                            <p class="my-auto mx-1">{{shopDay.dayOfWeek}}</p>
                            <div class="ms-auto weekly-timing">
                                @for (department of shopDay.deptHourList; track $index) {
                                    @if (department.departmentId==1) {
                                        <p class="mb-0">Sushi: {{department.displayTime}}</p>
                                    }
                                    @if (department.departmentId==2) {
                                        <p class="mb-0">Bento: {{department.displayTime}}</p>
                                    }
                                    @if (department.departmentId==3) {
                                        <p class="mb-0">Common: {{department.displayTime}}</p>
                                    }
                                }
                            </div>
                        </div>
                    </div>
                }
                }
                </div>
            </div>
        </div>
    </div>
</div>