import { Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../storage/storage.service';
import { SESSION_OUTOFSTOCK_ITEM_LIST } from '../../app.constants';
import { environment } from '../../environments/environment';
// import { StartupService } from './startup.service';

@Injectable({
  providedIn: 'root'
})

export class DateTimeServiceService {

  dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  outOfStockUrl: any;
  outOfStockInCartUrl: any;
  validateCartUrl: any;


  constructor(private datePipe: DatePipe,private http: HttpClient,private storageService:StorageService ) { 
    this.outOfStockUrl = environment.oos.url + '/shop/getOutOfStockByShop';
    //this.outOfStockInCartUrl = environment.oos.url + '/shop/getOutOfStockInCart';
    this.validateCartUrl = environment.oos.url + '/cart/validateCartDetail';
  }

  timezoneOffset() {
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset();
    const hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2);
    const minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2);
    const string = (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
    return string;
  }
  convertTimeToDateTime(time:any) {
    const currentDate = this.formatDate(new Date(), '-');
    const dateTimeFormat = currentDate + 'T' + time + this.timezoneOffset();
    return dateTimeFormat;
  }
  
  convertToDateTime(date:any,time:any) {
    const currentDate = this.formatDate(date, '-');
    const dateTimeFormat = currentDate + 'T' + time + this.timezoneOffset();
    return dateTimeFormat;
  }
  formatDate(date: Date, seperator: any): any {
    const dt = date.getDate();
    const month = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    const dd = (dt < 10) ? ('0' + dt) : dt + '';
    const mm = (month < 10) ? ('0' + month) : month + '';

    return (yyyy + seperator + mm + seperator + dd);
  }

  getDisplayDate(date: any): any {

    const dateObj = { date: '', label: '' };
    const curDate = new Date(date);
    const dayWeek = this.dayOfWeek[curDate.getDay()];

    dateObj.date = this.formatDate(curDate, '/');
    dateObj.label = this.getDateDisplayLabel(dateObj.date) + ' ' + '(' + dayWeek + ')';
    return dateObj;

  }

  getDateDisplayLabel(dateParam:any) {
    let dateLabel = this.datePipe.transform(dateParam, 'dd/MM/yyyy');
    const compDate = new Date(dateParam);
    compDate.setHours(0, 0, 0, 0);
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const dayDiff = (compDate.valueOf() - dt.valueOf()) / (1000 * 3600 * 24);
    if (dayDiff === 0) {
      dateLabel = 'TODAY';
    } else if (dayDiff === 1) {
      dateLabel = 'TOMORROW';
    }

    return dateLabel;
  }

  getOutofStockItemDtl(bodyParam:any): Observable<any> {
    return this.http.post<any>(this.outOfStockUrl, bodyParam)
      .pipe(

      );
  }
  
  getOutofStockByShop(inputParam:any){
    this.getOutofStockItemDtl(inputParam)
    .subscribe({
      next:(res:any)=>{
        let outofStockItem = res.object;
        this.storageService.saveToSession(SESSION_OUTOFSTOCK_ITEM_LIST, JSON.stringify(outofStockItem));
      },
      error:(err)=>{}
    });
  }

  
  validateCart(bodyParam:any): Observable<any> {
      return this.http.post<any>(this.validateCartUrl, bodyParam)
        .pipe(
  
        );
  }

}
