import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { RewardSnackbarComponent } from './reward-snackbar/reward-snackbar.component';
import { SnackBarData } from './notification-snackbar/model/snackbar.model';
import { NotitificationSnackBarComponent } from './notification-snackbar/notification-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class RewardSnackbarService {

  constructor(private snackBar: MatSnackBar) { }

  showOfferAppled(offr:any){
    //this.snackBar.openFromComponent(RewardSnackbarComponent,{data:"Applied",duration: 5000});
  }

  showOfferRemoved(offr:any){
    //this.snackBar.openFromComponent(RewardSnackbarComponent,{data:"Removed",duration: 5000});
  }
  /**
 * shows the snackbar
 * set defaults values
 * @param data 
 */
  showNotificationSnackBar(data: SnackBarData) {
    this.snackBar.openFromComponent(NotitificationSnackBarComponent, {
      data: data,
      duration: (data.duration == undefined ? 5000 : data.duration),
      panelClass: [(data.type == "success" ? "success-snack-notification" : ((data.type == "error") ? "error-snack-notification" : "warning-snack-notification"))],
      horizontalPosition: (data.horizontalPosition == undefined ? "end" : data.horizontalPosition),
      verticalPosition: (data.verticalPosition == undefined ? "top" : data.verticalPosition),
    });
  }
}
