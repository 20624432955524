import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {  Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";

@Component({
  selector: 'app-terms-conditions-popup',
  standalone: true,
  imports: [],
  templateUrl: './terms-conditions.component-new.html',
  styleUrl: './terms-conditions-popup.component.scss'
})
export class TermsConditionsPopupComponent {
  showHeader:boolean=false;

  constructor(private location: Location,private dialogRef: MatDialogRef<TermsConditionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  
    private dialog: MatDialog) { }
    back() {
      
    }

}
