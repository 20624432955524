<div class="password-change-root-container">
  <button
    class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-4"
    (click)="close()"
  ></button>
  <div class="mb-4 pb-lg-2">
    <h3 class="font-secondary mb-1">CHANGE PASSWORD</h3>
  </div>

  <form action="" [formGroup]="passwordChangeForm">
    <div class="mb-3 pb-1">
      <label for="current-password" class="form-label mb-1"
        >Current password*</label
      >
      <input
        type="password"
        class="form-control shadow-none rounded-1"
        id="current-password"
     
        formControlName="currentPassword"
        required
      />
      @if (passwordChangeForm.controls['currentPassword'].hasError('required'))
      {
      <div class="form-field-error">Please enter current password.</div>
      }
    </div>
    <div class="mb-3 pb-1">
      <div class="d-flex">
      <label for="new-password" class="form-label mb-1">New Password*</label>
      @if((!passwordFormField?.value?.match('^(?=.*[A-Z])'))||(!passwordFormField?.value?.match('(?=.*[a-z])'))
          ||(!passwordFormField?.value?.match('(.*[0-9].*)'))||(!passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
          ||(!passwordFormField?.value?.match('.{8,}'))){
          <div style="margin-left: auto;">
            <i class="fa fa-question-circle" aria-hidden="true" style="color:#ba1b1b;font-size: 15px;"></i>&nbsp;
            <span style="color:#ba1b1b;font-size: 15px;text-transform: lowercase;">Weak</span>
          </div>
          }
          @if((passwordFormField?.value?.match('^(?=.*[A-Z])'))&&(passwordFormField?.value?.match('(?=.*[a-z])'))
          &&(passwordFormField?.value?.match('(.*[0-9].*)'))&&(passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
          &&(passwordFormField?.value?.match('.{8,}'))){
            <div style="margin-left: auto;">
              <i class="fa fa-solid fa-circle-check" aria-hidden="true" style="color:#3E8635;font-size: 15px;"></i>&nbsp;
              <span style="color:#3E8635;font-size: 15px;text-transform: lowercase;">Strong</span>
            </div>
          }
        </div>
      <input
        type="password"
        class="form-control shadow-none rounded-1"
        id="new-password"
        
        formControlName="newPassword"
        (input)="onPasswordChange('new')"
        required
      />
      @if (passwordChangeForm.controls['newPassword'].hasError('required')) {
      <div class="form-field-error">Please enter new password.</div>
      }
      @else if(passwordFormField?.dirty){
        @if (!passwordFormField?.value?.match('^(?=.*[A-Z])')) {
          <div class="form-field-error">Add at least one uppercase letter (A-Z)
          </div>
        }
        @else if (!passwordFormField?.value?.match('(?=.*[a-z])')) {
          <div class="form-field-error">Add at least one lowercase letter (a-z)
          </div>
        }
        @else if (!passwordFormField?.value?.match('(.*[0-9].*)')) {
          <div class="form-field-error">Add at least one number (0-9)
          </div>
        }
        @else if (!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')) {
          <div class="form-field-error">Add at least one special character (!&#64;#$%^&*)</div>
        }
        @else if(!passwordFormField?.value?.match('.{8,}')){
          <div class="form-field-error">Be at least 8 characters long.</div>
        }
        @else {
          
        }
      }
    </div>
    <div class="mb-3 pb-1">
      <label for="comfirm-password" class="form-label mb-1"
        >Comfirm Password*</label
      >
      <input
        type="password"
        class="form-control shadow-none rounded-1"
        id="comfirm-password"
       
        formControlName="confirmPassword"
        (input)="onPasswordChange('confirm')"
        required
      />
      @if (passwordChangeForm.controls['confirmPassword'].hasError('required'))
      {
      <div class="form-field-error">Please enter comfirm password.</div>
      }
       @if(confirmPassError!=='') {
      <div class="form-field-error">{{confirmPassError}}</div>
      }
    </div>
  </form>

  <div class="button-container d-flex justify-content-center gap-2">
    <!-- <button mat-raised-button class="button-change" style="background-color: black; color: white" (click)="changePassword()">CHANGE</button> -->
    @if (passwordChangeStatus==0) {
    <button
      _ngcontent-ng-c2899863537=""
      class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate"
      (click)="changePassword()"
      
    >
      Change
    </button>
  }

  @if (passwordChangeStatus==1) {
    <button
      _ngcontent-ng-c2899863537=""
      class="btn btn-primary py-1 px-2 py-sm-2 px-sm-4 font-secondary rounded-pill mt-2 text-truncate text-dark"
      (click)="changePassword()"
      disabled
    >
      Change &nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
    </button>
  }
  </div>
</div>
