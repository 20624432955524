<button class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-4" (click)="close()"></button>
<div class="pb-1">
    <h3 class="font-secondary" style="display: flex; align-content: center; flex-wrap: wrap; height: 42px;">Apply
        Reward</h3>
</div>
<section class="overflow-auto theme-scrollbar " mat-content>
    @if (rewardsData.length==0) {
        <div class="col">
            <h6 class="mb-0 line-clamp" style="line-height: var(--h3);--truncate-lines:3">
                There is no active rewards.
            </h6>
        </div>
    }
    @for (reward of rewardsData; track reward.id) {
        <!-- {{reward.id}} -->
        <div class="rounded-1" content-item>
            <app-reward-card [reward]="reward"></app-reward-card>
        </div>
    }
</section>