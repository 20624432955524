<!-- <div class="position-sticky back-strip bg-white">
  <div class="container py-3 font-secondary">
    <button
      class="btn btn-outline-dark rounded-pill py-2 px-3"
      (click)="back()"
    >
      <img
        src="assets/icons/back_arrow_icon.svg"
        class="me-lg-2 white-icon"
        alt=""
      />
      <span class="align-middle d-none d-lg-inline">BACK</span>
    </button>
    <p
      class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
    >
      My Order
    </p>
  </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">My Order</p>
    </div>
 </div>
<section class="container pt-1">
  @if(orderHistoryArray.length === 0){
      <div class="wait-div-result">
          <span class="wait-span">Please wait...</span>
      </div>
      
      <div class="payment-loading-div-fail">
          <img class="payment-loading-panel" id="loading-id-fail" src="assets/Loading_icon.gif">
      </div>
    <!-- <div class="text-center">
      <div class="empty-message">
        <p class="fs-4">Your order history is currently empty.</p>
        <p class="fs-4">Why not place your first delicious order today?</p>
      </div>
    </div> -->
    } 
  <div class="container my-3 py-2">
    @for (orderDetail of orderHistoryArray; track $index) {  
      <div id="orderHistoryArray_{{$index}}">
      @for(items of orderDetail; track $index){
    <div
      class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3"
    >
      <div class="details-container">
        <div class="image-container ">
          <img
            src="{{ imageUrl }}/images/product/{{ items.orderImage }}"
            class="dish-img"
            width="150"
            alt=""
          />
        </div>
          <div class="container d-grid grid-template-rows 1fr auto 1fr">
            <div class="shope-info">
              <p class="fs-4 mb-2">{{ items.shopName }}</p>
            </div>
            <div class="d-flex flex-column flex-lg-row justify-content-between">
              <div class="item-details-container mt-1">
                <div class="invoice-num-class mt-2">
                  <span>#{{ items.taxInvoiceNum.split("-")[2] }}</span>
                </div>
                <p class="mb-0 mt-1">{{ items.deliveryDate }}</p>
                <h3 class="font-secondary pt-1 mt-2 amount">
                  {{currencySymbol}}{{items.totalAmountPaid| number :
                    '1.2-2'}}
                </h3>
              </div>
            </div>
          </div>
        </div>
        
      
      <div class="btn-container mt-3 mt-lg-0">
        <button
          class=" btn-primary  px-4 py-1 status-info"
          [ngStyle]="{
            'background-color': items.bgColor,
            color: items.fgColor
          }"
          style="border: solid black; border-radius:10px;cursor:default;" 
        >
          {{ items.displayText }}
        </button>
        <button
          class="btn btn-primary rounded-pill py-2 px-4 font-secondary btn-detail" (click)="orderDetails(items.orderId)"
        >
          DETAILS
         
        </button>
      </div>
    </div>
  }
  </div>
    }
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary rounded-pill py-2 px-4 font-secondary" (click)="showMoreClick()" style="background-color: black; color: white;"  *ngIf="hasItemsToShowMore()">
        Show more
      </button>
    </div>
    
    
  </div>
</section>

