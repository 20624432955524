import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmation } from '../header/header.component';
import { environment } from '../../environments/environment';
import { SettingsService } from '../../services/shared/settings.service';
import { PRODUCT_IMAGE_FOLDER, SESSION_SALE_ITEM_LIST } from '../../app.constants';
import { StorageService } from '../../services/storage/storage.service';
import { ItemListService } from '../../services/shared/item-list.service';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CartService } from '../../services/cart/cart.service';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-confirmation-panel',
  standalone: true,
  imports: [CommonModule,RouterModule,MatSelectModule,CommonModule,FormsModule],
  templateUrl: './confirmation-panel.component.html',
  styleUrl: './confirmation-panel.component.scss'
})
export class ConfirmationPanelComponent {
  saleItemInfo: any;
  imageUrl: string;
  uploadUrl: any;
  systemSettings: any;
  allSaleItemList: any[];
  currencySymbol :any;
  selectedQuantity: any = 1;
  quantity: any=[];

  @Input() cartItem: any;
  @Output() deleteItemClickEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog,
    private settingsService:SettingsService,
    private storageService:StorageService,
    private itemListService:ItemListService,
    public cartService:CartService,
    private router:Router){
    this.uploadUrl = environment.oos.assetsUrl;
    this.imageUrl = this.uploadUrl + PRODUCT_IMAGE_FOLDER;

    this.systemSettings = this.settingsService.systemSettings;
    // this.showCustomizationInCart= (this.startUp.startupData.showCustomizationInCart && (this.cartItem.isCustomized || this.cartItem.remarks !== null));
    // this.useToggleViewInCartCustomization= this.startUp.startupData.useToggleViewInCartCustomization;
    // this.isShow=(this.showCustomizationInCart && !this.useToggleViewInCartCustomization);
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));

    this.getSaleItem();
    this.getQuantity();
    if (this.systemSettings !== null) {
      // this.currencySymbol = this.systemSettings.currency.symbol;
      this.currencySymbol = '$';
    }
  }

  ngOnInit(){
    this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
  }

  getSaleItem() {
    this.allSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    if (this.allSaleItemList === undefined || this.allSaleItemList === null) {
      this.itemListService.loadSaleItem().subscribe(
        res => {
          this.allSaleItemList = res.object;
          this.storageService.saveToSession(SESSION_SALE_ITEM_LIST, this.allSaleItemList);
          this.saleItemInfo = this.allSaleItemList.find(asl => asl.id === this.cartItem.ooSaleItemId);
        });
    }
  }

  /**
 * For print 2 decimal point
 * @param str 
 * @param val 
 * @returns 
 */
ParseFloat(str:any,val:any) {
  str = str.toString();
  // console.log(str.slice(0,str.indexOf(".")+val+1));
  if(str.indexOf(".")==-1){
    str=str+".00";
  }
  str = str.slice(0, (str.indexOf(".")) + val + 1); 
  return Number(str);     
}
  /**
   *
   * @param cartItem
   *  when clicking the delete button on the cart,
   * that item is deleted from the cart
   */
  deleteItemFromCart(cartItem:any) {
    // this.animService.removeFromCart('checkout-btn');
    // this.deleteConfirmation();
    if (+cartItem.quantity > 1) {
      cartItem.quantity = +cartItem.quantity - 1;
      this.cartService.setCustomization(cartItem);
      this.cartService.changeItem(cartItem);
    } else {
      this.deleteItemClickEvent.emit(this.cartItem); 
    }
  }

  getQuantity() {
    this.quantity = [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 },
      { label: '6', value: 6 },
      { label: '7', value: 7 },
      { label: '8', value: 8 },
      { label: '9', value: 9 },
      { label: '10', value: 10 },
    ];
  }

   /**
   * @param cartItem
   * when the item quantity is either increment or decrement,
   * it will reflect on the total price of the cart
   */
   getItemTotalPrice(cartItem:any) {
    const total = this.cartItem.quantity * (this.cartItem.unitPriceWithTax);
    const custTotal= this.cartItem.quantity * (this.cartItem.customizationUnitPriceWithTax!==undefined?this.cartItem.customizationUnitPriceWithTax:0);
    cartItem.netCustomizationTotal=custTotal;
    cartItem.totalPrice = total;
    this.cartService.changeItem(cartItem);
    return cartItem.netTotal+cartItem.netCustomizationTotal;
  }
/**
 * 
 * @param cartItem 
 * When redirect from cart the product will be updated accordingly.
 */
  editItemDetail(cartItem:any) {
    if(this.saleItemInfo !== null &&  this.saleItemInfo !== undefined && cartItem.hasError !== true){
      this.router.navigate(['/itemDetail'], { queryParams: { 'source': 'shoppingCart', 'id': cartItem.itemId  } });
    }
  }

  deleteConfirmation(){
    this.dialog.open(DeleteConfirmation,{
      panelClass:'confirm-dialog',
    })
  }
}
