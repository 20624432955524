import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OOModule } from '../../modules/oo.module';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public rewardsLoaded = new Subject<any>();
  public userLoginChanged = new Subject<any>();
  public recentOrders = new Subject<any>();
  public selectDate = new Subject<any>();
  public selectTime = new Subject<any>();
  public rewardApplied = new Subject<any>();
  public rewardRemoved= new Subject<any>();
  public cartChanged = new Subject<string>();
  private refreshorderSchedulePopup = new Subject<void>();
  refreshOrderSchedule = this.refreshorderSchedulePopup.asObservable();
  constructor() {}

  notifyRewardsLoaded(rewards: any) {
    this.rewardsLoaded.next(rewards);
  }

  notifyRecentOrder(recentOrder: any) {
    this.recentOrders.next(recentOrder);
  }

  notifyLoginChange(status: 'in' | 'out') {
    this.userLoginChanged.next(status);
  }

  notifyTimeChange(time: any) {
    this.selectTime.next(time);
  }

  notifyDateChange(date: any) {
    this.selectDate.next(date);
  }

  notifyRewardApplied(reward: any) {
    this.rewardApplied.next(reward);
  }

  notifyRewardRemoved(reward: any) {
    this.rewardRemoved.next(reward);
  }
  
  refreshAndOpenorderSchedulePopup() {
    this.refreshorderSchedulePopup.next();
  }
  /**
   * notify when there is a change in the cart 
   */
  notifyCartChange(param:string): void {
    this.cartChanged.next(param);
  }
}
