<div class="receipt-container" style="height: 0px; overflow: auto;">
    <div class="information-div">
        <div class="receipt-detail">
            <!-- <div class="close-button" (click)="outerReceiptClick()">
          <img class="image-close-btn"
            src="assets/close-button.png" /> 
          </div> -->
            <!-- <div class="download-receipt" (click)="downloadReceipt()"> <span class="glyphicon glyphicon-download-alt download-receipt-button"></span></div> -->
            <div class="receipt-div-detail">
                <div id='receiptContentDiv' class="receiptContentDiv" [innerHTML]="htmlData"></div>
            </div>
        </div>
    </div>
</div>
<div class="payment-sucess-main-div">
    <div class="payment-sucess-div-class">
        @if (!result) {
        <div class="wait-div-result">
            <!-- <span class="wait-span-title">Please wait...</span> -->
            <div class="finish-order">
                <img src="assets/images/payment-sucees.png" class="finish-order-icon">
                <span class="finish-order">Thank you. </span>
                <span class="finish-order">Your order has been received.</span>
            </div>
            <div class="finish-order-notice container">

                <span class="finish-order-sub-heading">We are generating your receipt.</span>
                <span class="finish-order-content">Please wait until your receipt is displayed.
                    The receipt will be downloaded automatically. If download doesn't start automatically, you can
                    download it
                    manually.</span>
            </div>
        </div>
        }
        @if (!result) {
        <div class="payment-loading-div">
            <!-- <img class="payment-loading-panel" id="loading-id" src="assets/Loading_icon.gif"> -->
            <!-- <p-progressSpinner [style]="{width: '70px', height: '70px'}" strokeWidth="5" fill="#EEEEEE" animationDuration="2s"></p-progressSpinner> -->
        </div>
        }
    </div>
</div>
<!-- <p-confirmDialog key="payment-success" #cdSuccess class="payment-success poppup_main_div" [closable]="false" [rejectVisible]="false"  [closeOnEscape]="false" header="THANK YOU FOR YOUR ORDER!" [responsive] = "true"  >
    <p-footer class="poppup_main_div_button">
        <button mat-raised-button class="pd-accept-btn" *ngIf="this.htmlData != undefined" label="Download Receipt" (click)="downloadReceipt()">Download Receipt</button>
        <button mat-raised-button class="pd-accept-btn" label="Ok" (click)="cdSuccess.accept()">Ok</button>     
      </p-footer>
  </p-confirmDialog> -->
<!-- <p-confirmDialog #cd class="confirmationShop poppup_main_div" [closable]="false" [rejectVisible]="false"
    header="Confirmation" [closeOnEscape]="false" [responsive]="true">
    <p-footer class="poppup_main_div_button">
      <button mat-raised-button class="pd-accept-btn" label="OK" (click)="cd.accept()">OK</button>
    </p-footer>
  </p-confirmDialog> -->