<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-4">
  <div class="container py-3 mt-lg-4 pt-lg-2 font-secondary">
    <button
      class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none"
      (click)="back()"
    >
      <img src="assets/icons/back_arrow_icon.svg" class="white-icon" alt="" />
    </button>
    <h2
      class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-4 pb-lg-2 page-heading text-center align-middle"
    >
      Contact Us
    </h2>
  </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
  <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
              <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                 src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                 class="align-middle d-none d-lg-inline">BACK</span></button>
              <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">Contact Us</p>
  </div>
</div>


<div class="container mb-4 pb-3 overflow-x-hidden">
  <div class="pt-1 row contact-row">
    <div class="col-lg-6">
      <div class="py-sm-5">
        <p class="fs-4 mt-3 mb-5">
          For the order related issues and other urgent matters, please contact
          the store you ordered from during its trading hours. Its phone number
          can be found on the order details emailed to you or on the Store
          Finder function on this Online Ordering platform.
        </p>
        <p class="mb-4">
          For other issues, please send us a note using the Contact Us form
          below.
        </p>
        <p class="mb-0">
          Your message will be attended to within 3 working days. Thank you for
          your understanding.
        </p>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="bg-secondary rounded-4 p-4" style="--bs-bg-opacity: 10%">
        <form class="p-2 m-1 pb-0 mb-0" [formGroup]="contanctDataForm">
          <div class="mb-3 pb-1">
            <label for="fullName" class="form-label mb-1">Full Name*</label>
            <input
              type="text"
              class="form-control shadow-none rounded-1"
              id="fullName"
              formControlName="fullName"
            />
            @if (contanctDataForm.controls['fullName'].hasError('required')) {
            <div class="form-field-error">Please enter first name.</div>
            }
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3 pb-1">
                <label for="emailId" class="form-label mb-1">Email*</label>
                <input
                  type="email"
                  class="form-control shadow-none rounded-1"
                  id="emailId"
                  formControlName="email"
                />
                @if (contanctDataForm.controls['email'].hasError('required')) {
                <div class="form-field-error">Please enter email.</div>
                } @if (contanctDataForm.controls['email'].hasError('email')) {
                <div class="form-field-error">Please enter valid email.</div>
                }
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3 pb-1">
                <label for="phoneNumber" class="form-label mb-1"
                  >Phone Number*</label
                >
                <input
                  type="tel"
                  class="form-control shadow-none rounded-1"
                  id="phoneNumber"
                  formControlName="phoneNumber"
                  maxlength="13"
                  pattern="[0-9]*" (keypress)="preventNonNumeric($event)"
                />
                @if
                (contanctDataForm.controls['phoneNumber'].hasError('required'))
                {
                <div class="form-field-error">Please enter phone number.</div>
                } @if
                (contanctDataForm.controls['phoneNumber'].hasError('minLength'))
                {
                <div class="form-field-error">
                  Please enter valid phone number.
                </div>
                }
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="mb-3 pb-1">
                <label for="emailId" class="form-label mb-1">Store Name</label>
                <input
                  type="text"
                  class="form-control shadow-none rounded-1"
                  id="emailId"
                  formControlName="storeName"
                />
                
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3 pb-1">
                <label for="phoneNumber" class="form-label mb-1"
                  >Order Number</label
                >
                <input
                  type="text"
                  class="form-control shadow-none rounded-1"
                  id="phoneNumber"
                  formControlName="orderNumber"
                 
                />
               
              </div>
            </div>
          </div>
          
          <div class="mb-4 position-relative">
            <label for="message" class="form-label mb-1">Message*</label>
            <textarea
              class="form-control shadow-none rounded-1"
              style="resize: none"
              id="message"
              rows="5"
              formControlName="message"
              (keyup)="onMessageKeyUp($event)"
              maxlength="800"
            ></textarea>
            <p class="mb-0 position-absolute end-0" style="top: 100%">0/800</p>
            @if (contanctDataForm.controls['message'].hasError('required')) {
              <div class="form-field-error">Please enter the message.</div>
            }
          </div>
        </form>
        <div class="p-2 m-1 mt-0 pt-0">
          <div class="mb-4"></div>
          @if (feedbackStatus==0) {
            <button
            class="btn btn-primary py-2 px-4 font-secondary rounded-pill"
            type="submit"
            (click)="sendFeedBackData()"
          >
            Submit
          </button>
          }
          @if (feedbackStatus==1) {
            <button
            class="btn btn-primary py-2 px-4 font-secondary rounded-pill text-dark"
            type="submit"
            (click)="sendFeedBackData()"
            disabled
          >
          submit &nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
          </button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
