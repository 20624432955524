import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor() { }

  getTax(item: any) {
    let total = 0;
    total = item.fixedPrice * item.quantity;
    return (item.taxCalculationMethod === 1) ?
      (total * (item.tax1Percentage / 100)) : (total - (total / (1 + (item.tax1Percentage / 100))));
  }
  
  /**
   * Calculate tax and set total amount
   * tax_calculation_method ->
   *        0 => inlcusive
   *        1 => exclusive
   * @param item 
   */
  reCalculateTax(item: any) {
    let tax = 0;
    let itemTotal = 0;
    let netTotal = 0;
    tax = this.getTax(item);
    itemTotal = parseFloat((item.fixedPrice * item.quantity).toFixed(2));
    netTotal = itemTotal;

    if (item.taxCalculationMethod !== 0) {
      netTotal = itemTotal + tax;
    }
    item.totalPrice = itemTotal;
    item.netTotal = netTotal;
    item.totalTax = tax;
  }
}
