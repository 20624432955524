export const environment = {
    production: true,
    buildNo:202408311120,
    firebase: {
        config: {
            apiKey: "AIzaSyAtbsC2rw6R0P8-mwJ3PJtr-9t8cMtlgfM",
            authDomain: "st-pierre-s-online.firebaseapp.com",
            projectId: "st-pierre-s-online",
            storageBucket: "st-pierre-s-online.appspot.com",
            messagingSenderId: "755167293471",
            appId: "1:755167293471:web:c604abc297f09a19d5652a",
            measurementId: "G-K5V4ZLPX1J",
            vapidKey: "BNQ-Bd6l22DNhF-zPdnajAky84NNqHnwzyDqCEyITFZVU8XQw_J7aA8sPulz8xw7dV8y96TLmZBfw8-93NlmZJw",
        },
        subscriptionURL: "https://iid.googleapis.com/iid/v1:batchAdd",
        sendURL: "https://fcm.googleapis.com/fcm/send",
    },
    oos: {
        url: "https://test.oov2.mojosoft.work/ws",      
        assetsUrl:  "https://test.oov2.mojosoft.work/resources",
        gmapKey:'AIzaSyCpyPycx2BQQmmESdaQUldS-81-EKBj00g',
        initialRad:5,
        maxRad:3000,
        statusRetryLimit:1,
        statusRetryInterval:3,
        siteKey:'6LcCYe8bAAAAADbDjiNWMEfvWv5d1CQGWaShfF4R',
        showCustomizationInCart:true,
        sessionexpired:300
    },
    crm: {
        url: "https://test.crm.mojosoft.work/ws",
        crmUser: "crm",
        crmPassword: "1234",
        crmAppKey: "e568458e9121478125d076b344e26c9e",
        assetsUrl:"https://test.crm.mojosoft.work/resources"
    }
};
