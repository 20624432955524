import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './app/environments/environment';
import { enableProdMode } from '@angular/core';
// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from './app/environments/environment';
// import { OOModule } from './app/modules/oo.module';


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

  if (environment.production) {
    enableProdMode();
  }
  
  // platformBrowserDynamic().bootstrapModule(OOModule)
  //   .then(() => {
  //     if ('serviceWorker' in navigator && environment.production) {
  //       navigator.serviceWorker.register('ngsw-worker.js');
  //     }
  //   })
  //   .catch(err => console.error('Error:', err));
