import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StorageService } from '../storage/storage.service';
import { SESSION_START_TIME } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private storageService:StorageService) { }

    /**
   * Scroll the page to top
   */
    scrollToTop():void{
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

     /**
     * validate session
     */
     validateSession(): boolean {
      let isSessionValid = false;
      if (this.storageService.loadFromSession(SESSION_START_TIME) !== undefined) {
          const sessionStartTime = new Date(this.storageService.loadFromSession(SESSION_START_TIME));
          const currentTime = new Date();
          const totalDiffMins = (currentTime.valueOf() - sessionStartTime.valueOf()) / 60000;
          if (totalDiffMins < environment.oos.sessionexpired) {
              isSessionValid = true;
          }
      }
      return isSessionValid;
  }
}
