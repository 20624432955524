import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from '../../../../services/cart/cart.service';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../../services/storage/storage.service';
import { SESSION_ITEM_CATEGORY_LIST, SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_SELECTED_SHOP, SESSION_SYSTEM_SETTINGS } from '../../../../app.constants';
import { MatModule } from '../../../../modules/mat.module';
import { CommonModule } from '@angular/common';
import { RewardService } from '../../../../services/shared/reward.service';
import { Cart } from '../../../../models/cart.model';
import { AnimationService } from '../../../../services/cart/animation.service';

@Component({
  selector: 'app-shop-item-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shop-item-card.component.html',
  styleUrl: './shop-item-card.component.scss'
})
export class ShopItemCardComponent implements OnInit {
  assetsUrl: any;
  currencySymbol: any;
  systemSettings: any;
  hasPromotion: any;
  avialablePromotion: any;
  currentPromotion: any;
  outOfStockItemIds: any;
  message: any;
  messageclass: any;
  imageUrl:string="";

  @Input() item!: any;
  @Output() customize: EventEmitter<any> = new EventEmitter<any>();
  @Output() add: EventEmitter<any> = new EventEmitter<{id: number, classname: string}>();
  @Output() select: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageclass: EventEmitter<any> = new EventEmitter<any>();
  itemCategories: any;
  shopDepartmentIds: any;
  shopItemCategories: any;
  cart: Cart;
  currentPromoDescription: string="";

  constructor(
    public cartService: CartService,
    private storageService: StorageService,
    private rewardService: RewardService,
    private cartAnimationService:AnimationService
  ) {
    this.cart = this.cartService.cart;
    this.assetsUrl = environment.oos.assetsUrl;
    this.systemSettings = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS));
    this.currencySymbol = this.systemSettings.currency.symbol;
    // this.outOfStockItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_OUTOFSTOCK_ITEM_LIST));
    this.itemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_ITEM_CATEGORY_LIST));
    this.shopDepartmentIds = JSON.parse(this.storageService.loadFromSession(SESSION_SELECTED_SHOP)).department;
    this.shopItemCategories = JSON.parse(this.storageService.loadFromSession(SESSION_SELECTED_SHOP)).item_category;
    this.outOfStockItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_OUTOFSTOCK_ITEM_LIST));
    this.imageUrl=this.assetsUrl +"/images/product/";
  }

  ngOnInit() {

    this.hasPromotion = this.rewardService.hasPromotion(this.item.saleItemId);
    this.avialablePromotion = this.rewardService.getItemPromotion(this.item.saleItemId);
    if (this.avialablePromotion.buy !== undefined && this.avialablePromotion.buy.length > 0) {
      const promotion = this.avialablePromotion.buy[0];
      // console.log(promotion)
      // if(!this.cartService.isOfferEnabledForCust(promotion)){
      //   this.currentPromoDescription="You must login to get this offer"
      // }
      // else{
      //   this.currentPromoDescription=""
      // }
      switch (promotion.reward_type) {
        case 4:
          this.currentPromotion = promotion;
          break;
        default:
          break;
      }
    } else if (this.avialablePromotion.offer !== undefined && this.avialablePromotion.offer.length > 0) {
      const promotion = this.avialablePromotion.offer[0];
      switch (promotion.reward_type) {
        case 5:
          this.currentPromotion = promotion;
          break;
        default:
          break;
      }
    }
    // if (this.selectedParentCategory.code === 'BENTO') {
    //   this.message = 'AVAILABLE ONLY IN SHOPS SELLING BENTO BOWL';
    //   this.messageclass = 'na-button1';
    // } else {
    //   this.message = 'TEMPORARILY UNAVAILABLE';
    //   this.messageclass = 'na-button';
    // }
    if(this.outOfStockItemIds.includes(this.item.id)){
      this.message = 'OUT OF STOCK';
      this.messageclass = 'na-button1';
    }
    // this.offerDescriptionOffer=this.offer.offer;
    // console.log(this.offerDescriptionBuy[0].show_special_offer_badge);
  }

getPromotionBanner():string {
  var bannerMessage="";
  if(this.currentPromotion!==undefined){
    if(!this.cartService.isOfferEnabledForCust(this.currentPromotion)){
      bannerMessage="You must login to get this offer."
    }
    else{
      bannerMessage=this.currentPromotion.short_description;
    }
  }
 return bannerMessage;
}

  /**
   * Checks for promotions
   * @returns 
   */
  // hasPromotion(): boolean {

  //   return ((this.promotions && this.promotions.buy && this.promotions.buy.length > 0) || (this.promotions && this.promotions.offer && this.promotions.offer.length > 0));
  // }

  /**
   * Event to handle customize button click.
   * Emit the event back to parent
   */
  onCustomize(): void {
    this.customize.emit(this.item);
  }

  /**
 * Event to handle add button click.
 * Emit the event back to parent
 */
  onAdd(): void {
    this.add.emit({id:this.item.saleItemId,classname:"item-img_"+this.item.saleItemId});
  }

  /**
   * Event to handle item selection.
   * Emit the event back to parent
   */
  onSelect(): void {
    this.select.emit(this.item);
  }
  
  isItemAvailable(item: any) {
    let itemAvailable = true;
    // console.log(this.outOfStockItemIds)
    const itemCatIndex = this.itemCategories.findIndex((obj:any) => obj.id === item.itemCategoryId);
    if (item.ooBusinessTypeId === null ||
      !(item.ooBusinessTypeId.findIndex((bt:any) => bt === this.cart.businessType.id) > -1)) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE FOR ' + this.cart.businessType.name;
    } else if (this.shopDepartmentIds === null || ((itemCatIndex !== -1) &&
      !(this.shopDepartmentIds.findIndex((obj:any) => obj === this.itemCategories[itemCatIndex].departmentId) >= 0))) {
      itemAvailable = false;
    } else if (this.shopItemCategories !== null && !(this.shopItemCategories.findIndex((obj:any) => obj === item.itemCategoryId) >= 0)) {
      itemAvailable = false;
    } else if (!this.availableDay(item)) {
      itemAvailable = false;
      this.message = 'ITEM NOT AVAILABLE ON ' + this.cart.deliveryDate.label;
    }else if(this.outOfStockItemIds.includes(item.id)){
      itemAvailable = false;
      this.message = 'OUT OF STOCK';
      this.messageclass = 'na-button1';
    }
    return itemAvailable;
  }

  availableDay(item:any) {

    let pickUpDay = new Date(this.cart.deliveryDate.date).getDay();
    if (pickUpDay === 0) {
      pickUpDay = 7;
    }
    return item.itemAvailableDay.findIndex((e:any) => e === pickUpDay) > -1;
  }
}
