import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OOModule } from '../../modules/oo.module';
import { Observable, Subject, catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from '../storage/storage.service';
import { SESSION_SALE_ITEM_LIST, SESSION_SHOP_LIST } from '../../app.constants';
import { CartService } from '../cart/cart.service';
import { DatePipe } from '@angular/common';

export interface Reward {
  id: number,
}

@Injectable({
  providedIn: 'root'
})

export class ItemListService {
  selectedTimeValidityUrl: any
  saleItemUrlExt: string;
  saleItemUrl:string;
  customizationOptDtlUrl:string;
  filteredCategoryitemList: any[]=[];
  private _saleItemList: any;
  selectedDeliveryDate: any;
  customizationComboOptDtlUrl: string;

  get saleItemList(): any {
    this._saleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    if (this._saleItemList === null || this._saleItemList === undefined) {
      this.loadSaleItem().subscribe(
        res => {
          this._saleItemList = res.object;
          this.storageService.saveToSession(SESSION_SALE_ITEM_LIST, JSON.stringify(this._saleItemList));

        });
    }
    return this._saleItemList;
  }

  constructor(private http: HttpClient,private storageService:StorageService,
    private cartService:CartService,private datePipe: DatePipe) {
    this.selectedTimeValidityUrl = environment.oos.url + '/shop/selectedTimeValidity/';
    this.saleItemUrlExt = environment.oos.url + '/saleItem/get-all-ext';
    this.saleItemUrl = environment.oos.url + '/saleItem/get-all';
    this.customizationOptDtlUrl=environment.oos.url + '/customization-info/getCustomizationOptionDtl';
    this.customizationComboOptDtlUrl=environment.oos.url + '/combo-info/getComboOptionDtl';
  }
/**
   * get all sale item
   */
loadSaleItem(): Observable<any> {
  return this.http.get<any>(this.saleItemUrl)
    .pipe(
    );
}

loadCustomizationOptionDtl(): Observable<any> {
  return this.http.get<any>(this.customizationOptDtlUrl)
    .pipe(

    );
}

loadCustomizationComboOptionDtl(): Observable<any> {
  return this.http.get<any>(this.customizationComboOptDtlUrl)
  .pipe(

  );
}

  selectedTimeValidity(shopId: any, selectedDate: any, selectedTime: any): Observable<any> {
    const url = this.selectedTimeValidityUrl + shopId + '/' + selectedDate + '/' + selectedTime;
    return this.http.get<any>(url)
      .pipe(
    );

  }

  loadSaleItemExt(businessTypeId: any, pickUpDate: any, shop: any): Observable<any> {
    const datas = {
      'ooBusinessTypeId': businessTypeId,
      'departmentIds': shop.department.join(","),
      'pickUpDate': pickUpDate,
      'shopId': shop.id
    };

    return this.http.post<any>(this.saleItemUrlExt, datas)
      .pipe(

    );
  }


  getFilteredItemCategoryList(categoryList:any, salelist:any) {
    this.filteredCategoryitemList = [];
    let superCategoryitemList = [];
    // const categoryList = this.sessionStorage.get(SESSION_ITEM_CATEGORY_LIST);
    // const salelist = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
    for (const category of categoryList) {
      // const subCategories = category.subItemCategoryIds;
      if (category.parentId !== 0) {
        if (category.saleItemIds !== null &&
          category.saleItemIds !== undefined && category.saleItemIds.length > 0) {
          for (const saleId of category.saleItemIds) {
            const filteredCategoryitemListId = salelist.find((ic:any) => ic.id === saleId);
            // avoid duplicates to the array
            // const checkFiltercategory = this.filteredCategoryitemList.find(ic => ic.id === saleId);
            // if (filteredCategoryitemListId !== null && filteredCategoryitemListId !== undefined
            //   && (checkFiltercategory === null || checkFiltercategory === undefined)) {
            if (filteredCategoryitemListId !== null && filteredCategoryitemListId !== undefined) {
              // push these data array to the  new array...
              this.filteredCategoryitemList.push(category);
              break;
            }
          }
        }
      } else {
        superCategoryitemList.push(category);
      }
    }

    for (const category of superCategoryitemList) {
      if (category.subItemCategoryIds !== null &&
        category.subItemCategoryIds !== undefined && category.subItemCategoryIds.length > 0) {
        for (const subcategoryid of category.subItemCategoryIds) {
          const superCategoryitemListId = this.filteredCategoryitemList.find(ic => ic.id === subcategoryid);
          if (superCategoryitemListId !== null && superCategoryitemListId !== undefined) {
            this.filteredCategoryitemList.push(category);
            break;
          }
        }
      }
    }

    return this.filteredCategoryitemList;
  }

  isItemActiveByID(saleId:any): Boolean {

    const saleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    const saleItem = saleItemList.find((ic:any) => ic.id === saleId);
    return this.isSaleItemActive(saleItem);
  }

  isSaleItemActive(saleItem:any): Boolean {
    let isActive = true;
    if (saleItem !== null && saleItem !== undefined) {
      /**** checking item inactive within the date range from saleitemlist.****/
      const itemInactiveFrom = saleItem.inactiveFrom;
      const itemInactiveTo = saleItem.inactiveTo;
      isActive = this.isInactive(itemInactiveFrom, itemInactiveTo);
      if (isActive) {
        const selectedShopID = this.cartService.cart.shop.id;
        const selectedShop = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST)).find((shp:any) => shp.id === selectedShopID);
        // const selectedShop = this.cartService.cart.shop; /* To check */
        if (saleItem != null && saleItem !== undefined) {
          // Checking In shop wise inactive items
          const shopinactiveItemFind = selectedShop.inactiveItems.find((ic:any) => ic.oosaleItemId === saleItem.id);
          if (shopinactiveItemFind !== null && shopinactiveItemFind !== undefined) {
            isActive = false;
            const shopInactiveFrom = shopinactiveItemFind.inactiveFrom;
            const shopInactiveTo = shopinactiveItemFind.inactiveTo;
            /***** checking item inactive within the date range from shop inactiveitemlist. */
            isActive = this.isInactive(shopInactiveFrom, shopInactiveTo);
          }
          // Checking for available Day
          if (isActive) {
            const itemAvailDay = saleItem.itemAvailableDay;
            /******Checking the selected day exist in the "itemAvailableDay and
             the selected business type exist in the category item and checking inactive items from shop list******/
            isActive = itemAvailDay.includes(this.getSelectedDeliveryDay());
          }
          // Checking for biz type
          if (isActive) {
            const businType = saleItem.ooBusinessTypeId;
            isActive = businType.includes(this.cartService.cart.businessType.id);
          }
        }
      }
    }
    return isActive;
  }

  isInactive(fromDate:any, toDate:any): boolean {
    let active = true;
    const currentDate = this.datePipe.transform(this.cartService.cart.deliveryDate.date, 'yyyy-MM-dd');
    if (fromDate !== null) {
      if (fromDate <= currentDate!) {
        if (toDate != null && toDate < currentDate!) {
          active = true;
        } else {
          active = false;
        }
      } else {
        active = true;
      }
    }
    return active;
  }

  getSelectedDeliveryDay() {
    this.selectedDeliveryDate = this.cartService.cart.deliveryDate;
    const dateSelected = new Date(this.selectedDeliveryDate.date);
    const selectedDay = dateSelected.getDay();
    let daY: any;
    if (selectedDay === 0) {
      daY = 7;
    } else {
      daY = selectedDay;
    }
    return daY;
  }
}