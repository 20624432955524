<!-- <div class="back-strip strip-2 border-0 bg-white pt-lg-1">
  <div class="container py-3 pt-lg-2">
    <button
      class="btn btn-outline-dark rounded-pill py-2 px-3 d-inline d-lg-none"
      onclick="history.back()"
    >
      <img src="assets/icons/back_arrow_icon.svg" class="white-icon" alt="" />
    </button>
    <h2
      class="d-inline-block d-lg-block mt-lg-1 mb-0 mb-lg-4 pb-lg-2 page-heading text-center align-middle"
    >
      Hello,
      <span class="font-secondary customer-name"
        >{{ customer.firstName }} {{ customer.lastName }}</span
      >
    </h2>
    <div class="qr-code-container"></div>
  </div>
</div> -->

<!-- <div class="position-sticky back-strip bg-white">
    <div class="container py-3 ">
      <button
        class="btn btn-outline-dark rounded-pill py-2 px-3"
        (click)="back()"
      >
        <img
          src="assets/icons/back_arrow_icon.svg"
          class="me-lg-2 white-icon"
          alt=""
        />
        <span class="align-middle d-none d-lg-inline font-secondary ">BACK</span>
      </button>
      <p
        class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
      >
      Hello,
      <span class="font-secondary customer-name"
        >{{ customer.firstName }} {{ customer.lastName }}</span
      >
      </p>
    </div>
  </div> -->

  <div class="back-strip  bg-white firstDiv">
   <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
               <button class="btn btn-outline-dark rounded-pill py-2 px-3 font-secondary" (click)="back()"><img
                  src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                  class="align-middle d-none d-lg-inline">BACK</span></button>
               <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">
                Hello,
                <span class="font-secondary customer-name"
                  >{{ customer.firstName }} {{ customer.lastName }}</span
                >
               </p>
   </div>
</div>
<div class="container py-2">
  <div class="qr-container">
    <qrcode
      [qrdata]="custQrInfo"
      [width]="100"
      cssClass="qr-code-container"
      [errorCorrectionLevel]="'M'"
      (click)="onShowQR()"
    ></qrcode>
  </div>
  <div class="wrapper py-2">
    <div 
      class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3 option-card-btn"
      (click)="MyAccount()"
    >
      <button class="nav-link text-center ">
        <img
          src="assets/icons/user_icon.svg"
          width="70px"
          alt="user icon"
          class="svg-icon"
        />
      </button>
      <p  class="font-secondary  ">MY PROFILE</p>
    </div>
    <div
      class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3 option-card-btn"
      (click)="MYOrders()"
    >
      <button class="nav-link text-center ">
        <img style="filter: unset;"
          src="assets/icons/myorders.png"
          width="70px"
          alt="user icon"
          class="svg-icon"
          height="72px"
        />
      </button>
      <p class="font-secondary  ">ORDERS</p>
    </div>

    <div
      class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3 option-card-btn"   (click)="MyRwards()"
    >
      <button class="nav-link text-center ">
        <img
          src="assets/icons/gift.png"
          width="70px"
          alt="user icon"
          class="svg-icon"
        />
      </button>
      <p class="font-secondary  ">REWARDS</p>
    </div>

    <div
    class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3 option-card-btn"
    (click)="logout();"
  >
    <button class="nav-link text-center ">
      <img
        src="assets/icons/logout_icon-02.svg"
        width="70px"
        alt="user icon"
      />
    </button>
    <p class="font-secondary">LOGOUT</p>
  </div>
  </div>
</div>
