import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { OOModule } from '../../modules/oo.module';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private dbName = 'Oov2';
  private dbVersion = 1;
  constructor() {

  }

  openDb(): Observable<IDBDatabase> {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    return from(
      new Observable<IDBDatabase>((observer) => {
        request.onerror = (event) => {
          observer.error('Error opening database: ' + (event.target as any).error);
        };

        request.onupgradeneeded = (event) => {
          const db = (event.target as any).result;
          db.createObjectStore('fcmTokens', { keyPath: 'id', autoIncrement: true });
        };

        request.onsuccess = (event) => {
          const db = (event.target as any).result;
          observer.next(db);
          observer.complete();
        };
      })
    );
  }

  storeData(db: IDBDatabase, token: string) {
    const transaction = db.transaction(['fcmTokens'], 'readwrite');
    const objectStore = transaction.objectStore('fcmTokens');
    const request = objectStore.put({ token });

    request.onerror = (event: Event) => {
      console.error('Error adding FCM token:', (event.target as any).error);
    };
  }
  getAllFCMTokens(db: IDBDatabase) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['fcmTokens'], 'readonly');
      const objectStore = transaction.objectStore('fcmTokens');
      const request = objectStore.getAll();

      request.onsuccess = (event: Event) => {
        resolve((event.target as any).result);
      };

      request.onerror = (event: Event) => {
        console.error('Error getting FCM tokens:', (event.target as any).error);
        reject((event.target as any).error);
      };
    });
  }
  test(){
    console.log('Testing')
  }
}
