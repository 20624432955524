import { Component, Inject } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { DOCUMENT } from '@angular/common';
import { CartService } from '../../services/cart/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderConfirmationService } from '../confirm-orders/order-confirmation.service';
import { TimeService } from '../../services/shared/time-service.service';
import { SESSION_CONFIRMATION_MESSAGE, SESSION_SYSTEM_SETTINGS } from '../../app.constants';
import { environment } from '../../environments/environment';
import { PaymentInitService } from './payment-init.service';

@Component({
  selector: 'app-payment-init',
  standalone: true,
  imports: [],
  templateUrl: './payment-init.component.html',
  styleUrl: './payment-init.component.scss'
})
export class PaymentInitComponent {
  confirmationMessage: any;
  redirectUrl: any;
  baseUrl: any;
  currency: any;
  cartToInit: any;
  constructor(private storageService: StorageService, 
    @Inject(DOCUMENT) private document: Document,
    private cartService: CartService, 
    private paymentInitService: PaymentInitService, 
    private router: Router, 
    private confirmationService: OrderConfirmationService,
    private activatedRoute: ActivatedRoute,  
    private timeService: TimeService) {
    // this.initTitleBarParams();
    this.currency = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS)).currency;
    this.baseUrl = environment.oos.url;
    this.redirectUrl = this.activatedRoute.snapshot;
    this.confirmationMessage = this.storageService.loadFromSession(SESSION_CONFIRMATION_MESSAGE);
  }

  ngAfterViewInit() {
    this.cartToInit=this.cartService.getCustomerCart();
    this.paymentProcess();
   }

   paymentProcess() {
    this.getPaymentRequestParam();
  }

  getPaymentRequestParam() {
    const cartShop =  this.cartService.cart.shop;
    this.cartToInit.deliveryDate = this.cartService.cart.deliveryDate.date;
    this.cartToInit.customerId = this.cartService.cart.customer?.id;
    const today = new Date(this.timeService.serverTime);
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    this.cartToInit.orderDate = date;
    this.cartToInit.orderTime = time;
    // const custCart= this.cartService.getCustomerCart();
    const paymentParam = {customerId: this.cartToInit.customer?.id, customerName: this.cartToInit.customer?.firstName, customerPhone: this.cartToInit.customer?.phoneNumber,
        customerAddress: this.cartToInit.customer?.address, customerEmail: this.cartToInit.customer?.email, amount: this.cartService.getCustCartTotal(this.cartToInit).toFixed(2),
        currency : this.currency.code, baseUrl: this.document.baseURI, shopAccountNumber: cartShop.accountNumber, cart: this.cartToInit,
         orderConfirmation: this.confirmationMessage};
     this.paymentInitService.paymentRequest(paymentParam).subscribe(
      res => {
       const result = res.success;
         if (result) {
          this.document.location.href = res.object.paymentUrl;
       } else if (res.errorCode === 'INVALID_ORDER') {
        this.confirmationService.confirm({
          key: 'payment-fail',
          header: 'FAILED TO PROCESS ORDER',
          message: res.message,
          accept: () => {
            this.cartService.cart.items = [];
            this.cartService.cart.remarks = undefined;
            this.cartService.cart.pickupLocationId = undefined;
            this.cartService.setToSession();
            this.router.navigate(['/shopList']);
          },
        });
       } else {
        this.router.navigate(['/payment-fail']);

       }
     }
   );
}

}
