<button class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-3 m-sm-4"
    (click)="close(false)"></button>
<h3 class="font-secondary mb-3 mb-sm-4 pb-1 pb-sm-2">
    Pickup Date/Time
</h3>
<div class="card px-2 px-sm-4 flex-row py-2 mb-2" role="button">
    <div class="my-auto me-2 pe-1 pe-sm-0 me-sm-4">
        <img src="assets/icons/calendar_icon.svg" width="34" height="34" alt="">
    </div>
    <div class="date-picker py-1" style="width: 100%;" (click)="openDateSelection()">
        <!-- <p class="mb-1">{{dateTimeTitlePrefix}} DATE</p> -->
        <!-- Today <span class="text-nowrap">(10-November-2023)</span> -->
        <mat-label>{{dateTimeTitlePrefix}} DATE</mat-label><br />
        <mat-select #dateSelector [(ngModel)]="selectedDate" (selectionChange)="onDateChange($event)">
            @for (date of dateArray; track $index) {
            <mat-option [value]="date">
                <p>{{date.label}}</p>
            </mat-option>
            }
        </mat-select>
    </div>
</div>

<!-- <p class="mb-1">{{dateTimeTitlePrefix}} TIME</p> -->
<div class="timeList-dropdown-div" id="timeList">
    @if(isTimeSlot){
    <div class="time-slot-error">
        @if (isTimeSlot && !msg) {
        <div class="form-field-error fs-5 alert alert-danger">
            @if (!noShopServiceTimeSlot) {
            <i class="fa fa-info-circle error-icon" aria-hidden="true"></i>&nbsp;
            <span class="no-time-span">Shop is closed for
                {{ selectedService.name.toUpperCase( ) +' '+ getShopClosedMsg()}}. Please choose another
                day.
            </span>
            }
            @else {
            <span class="no-time-span">No time slot available for
                {{getShopClosedMsg()}}. Please choose another day.
            </span>
            }
        </div>
        }
    </div>
    }
    @else {
    <div class="time-slot-selection-div" (click)="openTimeSelection()">
        <div class="card px-2 px-sm-4 flex-row py-2 mb-3 mb-sm-4" role="button">
            <div class="my-auto me-2 pe-1 pe-sm-0 me-sm-4">
                <img src="assets/icons/clock_icon.svg" width="34" height="34" alt="">
            </div>
            @if (!isTimeSlot) {
            <div class="date-picker py-1" style="width: 100%;">
                <mat-label>{{dateTimeTitlePrefix}} TIME</mat-label><br />
                <mat-select #timeSelector [(ngModel)]="timeSelected" (selectionChange)="onTimeChange($event)">
                    @for (timeSlote of timeSlots; track $index) {
                    <mat-option [value]="timeSlote">{{timeSlote.displayTime}}</mat-option>
                    }
                </mat-select>
            </div>
            }
        </div>
    </div>
    }
</div>
<!-- @if (this.selectedService.id === 1 && shopPickupLocation.length > 0) {
<div class="pickUpLocation-selection-card card">
    <div class="card-header">
        <h4>
            <b>SELECT {{contactLessLocation}} LOCATION</b>
        </h4>
    </div>
    <div class="contactLess-options-list" style="width:100%;">
        @for (shopPickupLoc of shopPickupLocation; track $index) {
        <div class="contactLess-location" (click)="selectPickupLoc(shopPickupLoc)">
            @if(selectedPickUplocation?.id === shopPickupLoc?.id){
            <i class="fa fa-check-circle selected-icon" aria-hidden="true"></i>
            }
            <div class="option-title" style=" display: flex;">

                <div>{{shopPickupLoc?.title}}</div>
            </div>
        </div>
        }
    </div>
</div>
} -->
@if (msg) {
<div class="form-field-error fs-5 alert alert-success">
    <span>{{msg}}</span>
</div>
}
<div class="row">
    <div class="d-flex flex-row-reverse" style="justify-content: space-between;width: 100%;">
        <!-- <div class="col-md-4 me-5"> -->
        @if (this.proceedStatus==0) {
        <button class="btn btn-primary font-secondary rounded-pill py-2 px-4" routerLink="shopItems"
            (click)="addDateTimeToCart()">Proceed</button>
        }
        @if (this.proceedStatus==1) {
        <button class="btn btn-primary font-secondary rounded-pill py-2 px-4 text-dark" routerLink="shopItems"
            (click)="addDateTimeToCart()" disabled>
            Proceed
            &nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
        </button>
        }
        <button class="btn btn-secondary font-secondary rounded-pill py-2 px-4 me-4" routerLink="shopItems"
            (click)="cancel()">Cancel</button>

        <!-- </div> -->
        <!-- <div class="col-md-1 ms-5"> -->
        <!-- <div class="col-md-4 ms-5 ps-5 proceedButton"> -->



        <!-- </div> -->
    </div>
</div>