import { Injectable, Inject } from '@angular/core';
import { CartService } from '../../services/cart/cart.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationService {

errorJsonArrayOfCart: any;
  getOrderConfirmationUrl: string;
  confirm: any;
  constructor(private cartService: CartService, private http: HttpClient,
    @Inject(DOCUMENT) private document: Document) {
    this.getOrderConfirmationUrl = environment.oos.url + '/order-confirmation/confirmation-content';
   }

  getOrderConfirmation(): Observable<any> {
  const cartParam = this.cartService.getCartParam();
    return this.http.post<any>(this.getOrderConfirmationUrl, cartParam)
    .pipe(
    );
}

}
