import { Injectable } from '@angular/core';
import { timeout } from 'rxjs';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { OOModule } from '../../modules/oo.module';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  serverTime= new Date();
  timeInterval: any;
  lastUpdatedTime= new Date();
  isServiceStarted: any;
  serverTimeUrl: string;
  startUpTimeUrl = this.http.get(environment.oos.url);
  constructor(private http: HttpClient) {
    this.timeInterval = 30000;
    this.isServiceStarted = false;
    this.serverTimeUrl = this.startUpTimeUrl + '/util-service/get-time';
  }

  startTimeService() {
    if (!this.isServiceStarted) {
      this.updateServerTime();
    }
  }

  updateServerTime() {
    this.isServiceStarted = true;
    // Api call to get current servertime
    this.serverTime = new Date();
    setTimeout(() => {
               this.updateServerTime();
            }, this.timeInterval);
    return this.serverTime;
  }

  getServerTime(){
    return this.http.get<any>(this.serverTimeUrl)
    .pipe(

      );
  }

  toHMFormat(initialDelay: number): String {

    const initialDelayHours = initialDelay / 60;
    const initialHours = Math.floor(initialDelayHours);
    const initialDelayMinutes = (initialDelayHours - initialHours) * 60;
    const initialMinutes = Math.round(initialDelayMinutes);
    let initialTime = '';
    if (initialHours > 0 && initialMinutes > 0) {
      initialTime = initialHours + ' Hours' + "' " + initialMinutes + ' Minutes ';
    } else if (initialHours <= 0) {
      initialTime = initialMinutes + ' Minutes ';
    } else if (initialMinutes <= 0) {
      initialTime = initialHours + ' Hours' + "' ";
    }
    return initialTime;

  }
}
