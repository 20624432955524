import { Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SESSION_SYSTEM_SETTINGS } from '../../app.constants';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  validateTokenUrl: string;
  accountActivationUrl: string;
  systemSettingsUrl: string;
  _systemSettings: any;

  constructor(private http: HttpClient,
    private storageService: StorageService) {
    this.systemSettingsUrl = environment.oos.url + '/settings/get-all';
    this.accountActivationUrl = environment.oos.url + '/customer/account-activation/';
   this.validateTokenUrl = environment.oos.url + '/order-confirmation/';
  }

  systemSettings(){
    this._systemSettings = JSON.parse(this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS));
    if (this._systemSettings === null || this._systemSettings === undefined) {
      this.loadSystemSettings().subscribe(
        res => {
          this._systemSettings = res.object;
          this.storageService.saveToSession(SESSION_SYSTEM_SETTINGS,JSON.stringify(this._systemSettings));
        }
      );
    }

    return this._systemSettings;
  }
  
  loadSystemSettings(): Observable<any> {
    return this.http.get<any>(this.systemSettingsUrl)
      .pipe(
      );
  }

  accountactivation(accessTocken: any): Observable<any> {
    const url = this.accountActivationUrl + accessTocken;
    return this.http.get<any>(url)
      .pipe(
      );
  }

      validateToken(token: any): Observable<any> {
        const url = this.validateTokenUrl + token;
        return this.http.get<any>(url)
        .pipe(
        );
          }
}
