import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CustomerService } from '../../services/customer/customer.service';

import { OOModule } from '../../modules/oo.module';
import { StorageService } from '../../services/storage/storage.service';

import { DatePipe } from '@angular/common';
import { TimeService } from '../../services/shared/time-service.service';
import { DateTimeServiceService } from '../../services/shared/date-time-service.service';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  curTime:any;
  constructor(private http: HttpClient,
    private customerService:CustomerService,
    private storageService:StorageService,
    private datePipe: DatePipe,
    private dateTimeServiceService:DateTimeServiceService
    ) {}

    getShopHours(shop:any, serverTime:any) {
      return this.getShopHoursByDate(shop, serverTime);
    }

    formatDate(date: Date): any {
      const dt = date.getDate();
      const month = date.getMonth() + 1;
      const yyyy = date.getFullYear();
  
      const dd = (dt < 10) ? ('0' + dt) : dt + '';
      const mm = (month < 10) ? ('0' + month) : month + '';
  
      return (yyyy + '/' + mm + '/' + dd);
    }

    getShopHoursByDate(shop:any, date:any) {

      let shopHoliday = false;
      let shopWorkingInfo: { shopOpen: any; shopStatus: any; currentShopWrkInfo: any; };
      let wrkDayVal: any;
      let wrkDay: any;
      let shopWkgHrsDisplayTime:any;
      let notHoliday: any = [];
      let tomorrowDate = this.datePipe.transform(new Date(+date + 86400000), "yyyy-MM-dd");
      let shopWorkingHours = shop.shopDailyWorkingHours.filter((obj:any) => obj.date === this.datePipe.transform(date, "yyyy-MM-dd"));
      notHoliday = shop.shopDailyWorkingHours.filter((obj:any) => obj.dayType != 1);
      shopWkgHrsDisplayTime = shopWorkingHours[0].displayTime;
      if (notHoliday.length !== 0) {
        const WrkDayIndex = notHoliday.findIndex((element:any) => (element.date === this.datePipe.transform(date, "yyyy-MM-dd")));
        wrkDayVal = notHoliday[WrkDayIndex + 1];
        
        /**
         * If next working day is with in the same month then it should show only the date.
         * If next working day belongs to different month,then show short month name and date.
         */
    // if(wrkDayVal !== undefined){
    //     var df = ((new Date().getMonth() + 1) !== (new Number(this.datePipe.transform(wrkDayVal.date, 'MM')).valueOf())) ? 'MMM Do' : 'Do';
    //     wrkDay = moment(wrkDayVal.date).format(df);
    //   }
      }
      if (shopWorkingHours != undefined) {
        shopHoliday = shopWorkingHours[0].dayType == 1;
        this.curTime = this.datePipe.transform(date, 'HH:mm:ss');
        const curDay = this.datePipe.transform(date, 'yyyy-MM-dd');
        const openTime = shopWorkingHours[0].openTime;
        const closeTime = shopWorkingHours[0].closeTime;
        /**
         * current time is between the working time
         */
        if (openTime <= this.curTime && this.curTime <= closeTime) {
          shop.status = 'Now Open';
          
        } else {
          /**
           * Just close/Open case
           * If  current time is before opening, 
           * time is + this.datePipe.transform('1900-01-01T' + shopWorkingHours[0].openTime, 'h:mm a')
           * show “Opening Shortly : shopWorkingHourse[0].openTime
           */
          if (openTime >= this.curTime) {
            shop.status = 'Opening Shortly';
  
          } else if (this.curTime >= closeTime) {
            /**
             * If  current time is after closing,
             *  show “Closed, Open at  : xxxxx”
             */
            if (notHoliday.length != 0 && wrkDayVal !== undefined) {
              let dayDate = this.getWrkDayInfo(tomorrowDate, wrkDayVal, date, wrkDay);
              shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
              this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
              shop.status = 'Closed';
            } else {
              shopWkgHrsDisplayTime = '-';
              shop.status = 'Closed';
            }
          }
        }
      } else {
  
        /**
         *  shop.status = 'Closed';
         * If today is holiday, then show next working day
         * This is the case for undefined working day.
         */
        if (notHoliday.length != 0 && wrkDayVal !== undefined) {
          let dayDate = this.getWrkDayInfo(tomorrowDate, wrkDayVal, date, wrkDay);
          shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
          this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
          shop.status = 'Closed';
        } else {
          shopWkgHrsDisplayTime = '-';
          shop.status = 'Closed';
        }
      }
      if (shopHoliday) {
        if (wrkDayVal !== undefined && wrkDayVal !== undefined) {
          let dayDate = this.getWrkDayInfo(tomorrowDate, wrkDayVal, date, wrkDay);
          shopWkgHrsDisplayTime = dayDate +  this.datePipe.transform('1900-01-01T' + wrkDayVal.openTime, 'h:mm a') + ' To ' +
                                  this.datePipe.transform('1900-01-01T' + wrkDayVal.closeTime, 'h:mm a');
          shop.status = 'Closed (' + shopWorkingHours[0].dayTitle+")";
        } else {
          shopWkgHrsDisplayTime = '-';
          shop.status = 'Closed';
        }
      }
      shopWorkingInfo = { shopOpen: shopWkgHrsDisplayTime, shopStatus: shop.status, currentShopWrkInfo: shopWorkingHours[0] };
      return shopWorkingInfo;
    }
    getWrkDayInfo(tomorrowDate: any, wrkDayVal: any, date: any, wrkDay: any) {
      let dayDate = (tomorrowDate === wrkDayVal.date) ? ' Tomorrow (' +
        this.dateTimeServiceService.dayOfWeek[new Date(+date + 86400000).getDay()] + ') ' : ' ' + wrkDay +
        ' (' + this.dateTimeServiceService.dayOfWeek[(wrkDayVal.weekDay === 7) ? 0 : wrkDayVal.weekDay] + ') ';
  
      return dayDate;
    }

  }

function moment(date: any) {
  throw new Error('Function not implemented.');
}
