
@if(paymentId==undefined){
 
  <div class="payment-fail-main-div d-flex flex-column justify-content-center align-items-center">
    <div class="payment-fail-div-class mb-4">
        <div class="payment-authorization">
            
            <h1 class="payment-fail-authorize-span-title font-secondary pt-2" >Something Wrong!</h1>
        </div>
        <div class="payment-money-details">
            
          <p class="payment-fail-div-message" style="height: 240px;" >We apologize for any inconvenience caused. It seems something went wrong. This may have occurred if you pressed the back button. Don't worry, your money is secure. If your account has been debited, please reach out to us for assistance.</p>
      </div>
      
        
      <div class="button-div d-flex justify-content-center align-items-center gap-2 container mb-2">
        <button class="btn btn-warning rounded-pill btn-shadow py-2 px-4 me-md-2 text-truncate mt-2" (click)="onHome()" >
         HOME
        </button>
       
      </div>
    </div>
   
</div>
} @else{


  <!-- <div class="position-sticky back-strip bg-white">
    <div class="container py-3 font-secondary">
      <button
        class="btn btn-outline-dark rounded-pill py-2 px-3"
        (click)="OnBack()"
      >
        <img
          src="assets/icons/back_arrow_icon.svg"
          class="me-lg-2 white-icon"
          alt=""
        />
        <span class="align-middle d-none d-lg-inline">BACK</span>
      </button>
      <p
        class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
      >
        payment
      </p>
    </div>
  </div> -->
  <div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="OnBack()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle">  payment</p>
    </div>
 </div>

@if (result==undefined) {
<div class="wait-div-result">
    <span class="wait-span">Please wait...</span>
</div>

<div class="payment-loading-div-fail">
    <img class="payment-loading-panel" id="loading-id-fail" src="assets/Loading_icon.gif">
</div>
}
@else {
    <div class="payment-fail-main-div d-flex flex-column justify-content-center align-items-center">
        <div class="payment-fail-div-class mb-4">
            <div class="payment-authorization">
                
                <h1 class="payment-fail-authorize-span-title font-secondary pt-2" >Payment Failed!</h1>
            </div>
            <div class="payment-money-details">
                
              <p class="payment-fail-div-message" ><span class="fw-bold">Don't Worry. Your Money is safe!</span> if money was debited from your account please contact us.</p>
          </div>
          <div class="payment-fail-details pt-3">
            <div class="payment-fail-div-payment-id-container d-flex justify-content-between "><div class="fw-bold">Payment ID</div> <div class="payment-fail-div-payment-id" > {{paymentId}}</div></div>
            <div class="payment-fail-div-payment-id-container d-flex justify-content-between pt-2"><div class="fw-bold">Time</div> <div class="payment-fail-div-payment-id" >{{ currentTime }}</div></div>
            
        </div>
            <div class="payment-fail-div-desc">
                <div  class="payment-fail-div-payment-id-container  pt-1">Why did it fail ?</div>
                <span class="payment-fail-div-reason-span">{{errorPage}}</span>
            </div>
            
          <div class="button-div d-flex justify-content-center align-items-center gap-2 container mb-2">
            <button class="btn btn-warning rounded-pill btn-shadow py-2 px-4 me-md-2 text-truncate"  (click)="OrderDetailsBtn()">
              ORDER DETAILS
            </button>
            <button class="btn btn-dark rounded-pill btn-shadow py-2 px-4 text-truncate"  (click)="RetryBtn()">
              RETRY
            </button>
          </div>
        </div>
       
    </div>

}


}



