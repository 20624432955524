<div class="customer-activation-main-container">

    <div class="customer-activation-main-div d-flex flex-column justify-content-center align-items-center">
        <div class="customer-activation-div-class mb-4">
         
          
          <div class="customer-activation-details">
            @if(message==undefined){
              <p class="loading">Please wait....</p>
            }
            <p class="customer-activation-message">{{message}}</p>
            
            
        </div>
        <div class="button-div d-flex justify-content-center align-items-center gap-2 container mt-1">
            <button class="btn btn-primary py-2 px-4 font-secondary rounded-pill"(click)="OnHome()">
                CONTINUE
              </button>
        </div>
        
         
        </div>
       
    </div>
</div>
