@if(!isError){
<div class="d-flex rounded-1 promotion-card-root-container" (click)="showPromotionSelection(promotion)">
    <img #promotionImage src="{{crmAssetsUrl}}/images/reward/{{promotion.image_file_name}}" onerror="hasError()"
        class="img-fluid" alt="" style="object-fit: contain;">
</div>
}@else{
<div class="d-flex rounded-1 p-3 content-item promotion-card-root-container" (click)="showPromotionSelection(promotion)">
    <div class="col">
        <h5 class="mb-3">{{promotion.name}}</h5>
        <h6 class="mb-0 line-clamp" style="line-height: var(--h3);--truncate-lines:3">
            {{promotion.description}}
        </h6>
    </div>
    @if (promotion.crm_event_id==1) {
    <div class="col-auto ms-3 reward-img">
        <img src="assets/images/icons/welcome-offer.svg" width="176" height="176" alt="">
    </div>
    }
    @else {
    <div class="col-auto ms-3 reward-img">
        <img src="assets/images/rewards/special-offer-default.svg" width="176" height="176" alt="">
    </div>
    }
</div>
}