import { Component,Inject, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface DialogData {
  isCancelled: boolean;
  title: string;
  remarks: string;
}

@Component({
  selector: 'app-remarks-dialog',
  standalone: true,
  imports: [MatFormFieldModule,FormsModule,MatDialogModule],
  templateUrl: './remarks-dialog.component.html',
  styleUrl: './remarks-dialog.component.scss'
})
export class RemarksDialogComponent {
  ngModelData: DialogData;
  constructor(
    public dlgRemarks: MatDialogRef<RemarksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.ngModelData = Object.assign(this.data);
      this.ngModelData.isCancelled = false;
    }

  onNoClick(): void {
    this.ngModelData.isCancelled = true;
    this.dlgRemarks.close(this.ngModelData);
  }

  ngOnInit() {
  }

}
