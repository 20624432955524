import { Component } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  currentYear: number = new Date().getFullYear();
  constructor(private location: Location) { }
  ngOnInit(){
    window.scrollTo(0, 0);
  }
  back() {
    this.location.back()
  }

}
