import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

  historyDtlUrl: string;
  orderHistoryUrl: string;
  orderReceiptUrl:string;
  recentOrderUrl:string;
  recentOrderByOrderIdUrl: string;
  myOrdersUrl: string;
  myOrderDetailUrl: string;
  constructor(private http: HttpClient) {
    this.orderHistoryUrl = environment.oos.url + '/order-info/get-all';
    this.historyDtlUrl = environment.oos.url + '/order-info/order-detail';
    this.orderReceiptUrl = environment.oos.url + '/order-info/get-order-receipt';
    this.recentOrderUrl  = environment.oos.url + '/order-info/getRecentOrders';
    this.recentOrderByOrderIdUrl = environment.oos.url + '/order-info/getRecentOrderByOrderId?orderId=';
    this.myOrdersUrl=environment.oos.url + '/customer/get-my-orders';
    this.myOrderDetailUrl=environment.oos.url + '/customer/get-my-order-detail';
  }

  getMyOrders(customerid:any,limit:any,offset:any): Observable<any> {
    const url = this.myOrdersUrl +  '/' + customerid+ '/' +limit+'/'+offset;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getMyOrderDetail(orderId:any): Observable<any> {
    const url = this.myOrderDetailUrl +  '/' + orderId;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getOrderHistory(orderHistory:any): Observable<any> {
    return this.http.post<any>(this.orderHistoryUrl, orderHistory)
      .pipe(

      );
  }

  getOrderHistoryDetails(orderId:any): Observable<any> {
    const url = this.historyDtlUrl +  '/' + orderId;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getPaymentReceiptInfo(taxInvNo:any): Observable<any> {
    const url = this.orderReceiptUrl +  '/' + taxInvNo;
    return this.http.get<any>(url)
      .pipe(
      );
  }

  getRecentOrders(bodyParam:any): Observable<any> {
    return this.http.post<any>(this.recentOrderUrl, bodyParam)
      .pipe(

      );
  }

  getRecentOrderByOrderId(requestParam:any): Observable<any> {
    let reuestUrl = this.recentOrderByOrderIdUrl+requestParam;
    return this.http.get<any>(reuestUrl)
      .pipe(

      );
  }
  
}