
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">
             @if (isSearchItem) {
                <app-serach-shop (backClick) = "backClick($event)" style="width: 100%;"></app-serach-shop>
             }
             @else {
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle" >shop list</p>
                <button class="btn btn-outline-dark rounded-pill py-2 px-3 ms-lg-5 searchButton" (click)="searchAllItems()"><img
                   src="assets/icons/search_icon.svg" class="me-lg-2 white-icon" alt="" style="width: 20px;"><span
                   class="align-middle d-none d-lg-inline">SEARCH</span></button>
             }
    </div>
 </div>

<section class="container py-3">
    @if (lastOrderShopName) {
    <h6 class="mb-2">Based on your previous order history we have set {{lastOrderShopName}} shop as default. </h6>
    }
    <div class="row orders-row">
        @for (shop of shopList; track $index) {
        <div class="col-lg-6">
            <div class="card p-2 p-md-3 p-lg-4 rounded-1">
                <div class="p-1 pb-0 p-md-0">
                    <h5 class="mb-3 pb-lg-1 d-inline-block pe-2" style="width: calc(100% - 30px); height:40px;"> {{shop?.name}}
                    </h5>
                    @if(isBentoShop(shop.id)){
                    <img src="assets/icons/bentobowl_icon.svg" width="30" height="30" alt="">
                    }
                </div>
                <div class="pb-lg-1 px-1 px-md-0">
                    <h6 class="mb-lg-3 text-truncate"><img src="assets/icons/location_icon.svg" alt=""
                            class="align-top"> <span class="ms-2 ps-1">{{shop?.address}}</span></h6>
                            <p>&nbsp;<i class="fa fa-phone me-3" aria-hidden="true"></i>{{shop.phoneNumber}}</p>
                    <h6 class="mb-lg-3 text-truncate shop-time-details">
                        <img src="assets/icons/clock_icon.svg" alt="" class="align-top">
                        @for (time of shop.shopWorkingHours; track $index) {
                        @if (time.dayOfWeek==day) {
                        <span class="ms-1">
                            Open Hours {{time.hoursShop}}
                        </span>
                        @if(shoptime>=time.openTime && shoptime<=time.closeTime){ <span
                            class="shop-status open float-end float-md-none">Now Open</span>
                            }
                            @else {
                            <span class="shop-status closed float-end float-md-none">Closed</span>
                            }
                            <div class="mt-3">
                                @if (hasNearestShop==true && getShopDistance(shop.id)!=null) {
                                <img src="assets/icons/distance_icon.svg" alt="" class="align-top">
                                <span class="ms-2">{{ getShopDistance(shop.id) | number:'1.2-2' }}Km away</span>
                                }
                            </div>
                            }
                            }
                    </h6>
                </div>
                <div class="d-flex font-secondary px-md-0"  style="justify-content: left;">
                    <button class="btn btn-dark rounded-pill btn-shadow me-1 text-nowrap"
                        (click)="src=='home' ? navigateForOfferSelection('CATERING',shop) : scheduleDialog('CATERING',shop)">
                        Order Catering</button>
                    @if (isBentoShop(shop.id)) {
                    <button class="btn btn-info rounded-pill btn-shadow me-md-2 text-truncate" style="min-width: 146.77px;"
                        (click)="src=='home' ? navigateForOfferSelection('ORDERNOW',shop) : scheduleDialog('ORDERNOW',shop)">
                        Order Sushi & Bento</button>
                    }
                    @else {
                    <button class="btn btn-warning rounded-pill btn-shadow me-md-2 text-truncate"
                        (click)="src=='home' ? navigateForOfferSelection('ORDERNOW',shop) : scheduleDialog('ORDERNOW',shop)" style="min-width: 146.77px;">
                        Order sushi</button>
                    }
                    <button class="btn btn-outline-dark ms-auto d-block d-md-none p-1"
                        style="height: 50px;width: 40px;" routerLink="/shopDetail/{{shop.id}}">
                        <img src="assets/icons/map_icon.svg" class="mapimage" style="height: 40px;width: 30px;">
                    </button>

                    <button class="btn btn-outline-dark rounded-pill px-1 ms-auto d-none d-md-block text-nowrap"
                        routerLink="/shopDetail/{{shop.id}}">
                        View Map</button>
                </div>
            </div>
        </div>

        }
    </div>
    @if (showMoreButton) {
    <div class="text-center py-4 py-lg-5">
        <button class="btn btn-primary font-secondary rounded-pill py-2 px-4" (click)="showMore()">
            Show More</button>
    </div>
    }
</section>