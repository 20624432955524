import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentInitService {

  finalizePaymentRequestUrl: string;
  paymentRequestUrl: string;
  constructor(private http: HttpClient) {
    this.paymentRequestUrl = environment.oos.url + '/payment/initPayment';
    this.finalizePaymentRequestUrl = environment.oos.url + '/payment/getOrderStatus/';
   }


paymentRequest(paymentParam:any): Observable<any> {
  return this.http.post<any>(this.paymentRequestUrl, paymentParam)
    .pipe(
    );
}

getOrderStatus(sucessParam: any, pid: any, txnId: any,retryId: any): Observable<any> {
  
  const url = this.finalizePaymentRequestUrl + sucessParam + '/' + pid + '/' + txnId + '/' + retryId;
  return this.http.get<any>(url)
    .pipe(
    );
}


}
