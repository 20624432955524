<!-- <div class="position-sticky back-strip bg-white">
    <div class="container py-3 font-secondary">
        <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                class="align-middle d-none d-lg-inline">BACK</span></button>
        <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle text-success">Please Confirm
        </p>
    </div>
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
    <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
                <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                   src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                   class="align-middle d-none d-lg-inline">BACK</span></button>
                <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle text-success">Please Confirm</p>
    </div>
 </div>
<div class="container pb-5 pb-lg-4 pt-lg-4 overflow-x-hidden cart-wrapper">
    <div class="row cart-row">
        <div class="col-lg-12 d-flex justify-content-center">
            <div class="top">
                @if (!result) {
                <div class="confirmation-loading-div">
                    <img class="confirmation-loading-panel" id="confirmation-loading-id" src="assets/Loading_icon.gif">
                </div>
                }
                @if (result) {
                <div class="confirmation-contents">
                    @if (result) {
                    <div class="confirmation-div" [innerHTML]="htmlData">

                    </div>
                    }
                </div>
                <div class="d-flex justify-content-center">
                <button class="btn btn-danger rounded-pill py-2 col-lg-5 font-secondary mb-lg-2 amount " style="font-size: 18px;" (click)=" proceed()">Pay {{ currencySymbol
                }}{{grandTotal | number: '1.2-2' }}</button>
                </div>
                }
            </div>
        </div>
    </div>
</div>


<!-- <p-confirmDialog key="cd-fail" #cdFail class="payment-success" [closable]="false" [rejectVisible]="false"
    [closeOnEscape]="false" header="FAILED TO PROCESS ORDER!" [responsive]="true" width="350" height="150">
    <p-footer class="poppup_main_div_button">
      <button mat-raised-button class="pd-accept-btn" label="Ok" (click)="cdFail.accept()">Ok</button>
    </p-footer>
  </p-confirmDialog> -->