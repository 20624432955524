@if(!hasImageError){
<div class="d-flex rounded-1 p-3 promotion-card-root-container">
    <img #rewardImg src="{{crmAssetsUrl}}/images/reward/{{reward.image_file_name}}" class="reward-dish-img" width="150"
        alt="rewardImage" onerror="this.src='assets/images/rewards/special-offer-default.svg'">
</div>
}@else{
<div class="d-flex rounded-1 p-3 content-item">
    <div class="col">
        <h5 class="mb-3">{{reward.name}}</h5>
        <h6 class="mb-0 line-clamp" style="line-height: var(--h3);--truncate-lines:3">
            {{reward.description}}
        </h6>
    </div>
    @if (reward.crm_event_id==1) {
    <div class="col-auto ms-3 reward-img">
        <img src="assets/images/icons/welcome-offer.svg" width="176" height="176" alt="">
    </div>
    }
    @else {
    <div class="col-auto ms-3 reward-img">
        <img src="assets/images/rewards/special-offer-default.svg" width="176" height="176" alt="">
    </div>
    }
</div>
}