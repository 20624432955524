<section>
    <h5 class="font-secondary mb-4">{{data.title}}</h5>
    <p>{{data.message}}</p>
    <div class="text-end pt-1 font-secondary">
        @if (this.data.signINButtonCaption=="SIGN UP") {
            <button style="float: left;" class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="onSignUp()">{{signINButtonCaption}}</button>
        }
        @if (this.data.signINButtonCaption=="SIGN IN") {
            <button style="float: left;" class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="onSignIn()">{{signINButtonCaption}}</button>
        }
        <button style="float: right;" class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="onConfirm()">{{yesButtonCaption}}</button>
      
    </div>
  </section>
