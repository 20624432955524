<!-- <section>
    <h5 class="font-secondary mb-4">Confirmation</h5>
    <p>Do you want to delete Small Teriyaki Chicken on Rice with Mayo?</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="close()">No</button>
      <button class="btn btn-primary py-2 px-4 rounded-pill" (click)="closeData()">Yes</button>
    </div>
  </section> -->
  <div class="special-instruction-dlg">
    <h1 mat-dialog-title>{{this.ngModelData.title}}</h1>
    <div mat-dialog-content>
  
      <mat-form-field>
        <textarea matInput [(ngModel)]="this.ngModelData.remarks" cdkTextareaAutosize
        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" cdkAutosizeMinRows="5" autofocus ></textarea>
      </mat-form-field>
      
    </div>
    <div mat-dialog-actions >
  
      <button mat-button class="pd-accept-btn" label="Ok"  [mat-dialog-close]="this.ngModelData" >Ok</button>
      <button mat-button class="pd-reject-btn" label="Cancel" (click)="onNoClick()">Cancel</button>
  
    </div>
  </div>
  
