import { Injectable} from '@angular/core';

import { CUSTOMER_TYPE_GUEST, PROMOTION_ELIGIBILITY_TYPE_REWARD } from '../../app.constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CartService } from '../cart/cart.service';
import { OfferMessageDialogComponent } from '../../pages/home/components/promotion-card/offer-message-dialog/offer-message-dialog.component';
import { RewardService } from './reward.service';


@Injectable({
  providedIn: 'root'
})

export class PromotionValidityCheckService {
  constructor(
    public dialog: MatDialog,
    private cartService: CartService,
    private rewardService:RewardService
  ) {
   
}

checkForPromotionValidity(promo:any){
  var custRewards = this.rewardService.getCustomerRewards();
  const isValid = custRewards.some((cr: any) => cr.id = promo.id);
  return isValid;
}
  /**
   * Check for the offer/reward for avialability
   * @returns 
   */
  canUse(promotion:any): boolean {
    var isValid = true;
    const dialogConfig = new MatDialogConfig();
    // Check for reward
    if (promotion.reward_eligibility == PROMOTION_ELIGIBILITY_TYPE_REWARD) {
      isValid = this.checkForPromotionValidity(promotion);
      if (!isValid) {
        var message = "This  Offer is reserved exclusively for registered customer. Please register as a customer.";
        switch (promotion.crm_event_id) {
          case 1:
            message = "This Welcome Offer is reserved exclusively for newly registered customer. Please register as a customer.";
            break;
          case 2:
            message = "This Birthday Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
          case 3:
            message = "This Anniversary Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
          default:
            message = "This  Offer is reserved exclusively for registered customer. Please register as a customer.";
            break;
        }

        dialogConfig.data = { message: message, title: "REGISTER TO GET THIS OFFER!", yesButtonCaption: "Ok", signINButtonCaption: "SIGN UP" };
        dialogConfig.panelClass = "confirm-dialog";
        this.dialog.open(OfferMessageDialogComponent, dialogConfig);
        // this.messageDialogService.shoNotificationDialog(
        //   {
        //     message:message,
        //     title: "REGISTER TO GET THIS OFFER!", yesButtonCaption: "Ok",
        //   });
      }
    }
    // Check for an offer
    else {
      isValid=true;
        if (promotion.enabled_for !== null) {
            if(this.cartService.isGuestLogin()){
              if(!promotion.enabled_for.some((pr: any) => pr==CUSTOMER_TYPE_GUEST)){
                isValid=false;
                message = "To available this offer, you must signed in first. Please sign in."
                dialogConfig.data = { message: message, title: "LOGIN TO GET THIS OFFER!", yesButtonCaption: "Cancel", signINButtonCaption: "SIGN IN" };
                dialogConfig.panelClass = "confirm-dialog";
                this.dialog.open(OfferMessageDialogComponent, dialogConfig);
              }   
            }
         
        }
    }
    return isValid;
  }
}